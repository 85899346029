import React, { useEffect } from 'react';

// import toastr from 'toastr';

import * as Yup from 'yup';

// import { Link } from 'react-router-dom';

import { Formik, Field, Form } from 'formik';

import {
  Alert,
  FormGroup,
  Col,
  FormFeedback,
  Button,
  Input,
  Card,
  CardBody,
  Row
} from 'reactstrap';

import { useMappedState, useDispatch } from 'redux-react-hook';

import { login, authClearError } from '../../redux/ducks/auth';

import Logo from './Logo';

const mapState = state => ({
  authError: state.auth.error,
  user: state.auth.user
});

export default function Login(props) {
  const { authError, user } = useMappedState(mapState);

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (authError) {
        setTimeout(() => dispatch(authClearError()), 5000);
      }

      if (user) {
        // toastr.success('Welcome!');
        // Timeout because react-particles-js throws an error
        let url = '/dashboard';
        if (
          window._env_.REACT_APP_MODULE_CATEGORY_PDV === 'true' &&
          window._env_.REACT_APP_MODULE_PDV_DASHBOARD === 'true'
        )
          url += '?type=pdv';
        else if (
          window._env_.REACT_APP_MODULE_CATEGORY_OOH === 'true' &&
          window._env_.REACT_APP_MODULE_OOH_DASHBOARD === 'true'
        )
          url += '?type=ooh';
        setTimeout(() => props.history.push(url), 100);
      }
    },
    [user, authError]
  );

  const onSubmit = params => {
    dispatch(login(params.email, params.password));
  };

  return (
    <div className="view_cont">
      <Card className="main_window">
        <CardBody className="main_data main_signin m_15">
          <Row>
            <div className="col-md-12">
              <Logo />
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Is not a valid e-mail')
                    .required('Email is required'),
                  password: Yup.string().required('Password is required')
                })}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    {authError && <Alert color="danger">{authError}</Alert>}
                    <Col msm={9}>
                      <FormGroup>
                        <Input
                          placeholder="Email"
                          className="form_item text_form"
                          type="email"
                          name="email"
                          tag={Field}
                          invalid={errors.email && touched.email}
                        />
                        {errors.email && touched.email && (
                          <FormFeedback>{errors.email}</FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          placeholder="Password"
                          className="form_item text_form"
                          type="password"
                          name="password"
                          tag={Field}
                          invalid={errors.password && touched.password}
                        />
                        {errors.password && touched.password && (
                          <FormFeedback>{errors.password}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Button color="primary" type="submit" className="form_bt bt_common m_15">
                      LOGIN
                    </Button>
                    {/* <div className="form_agree m_25 mb_25_mob">
                      <div className="text_check text_small text_3_color">
                        <Link to="/forgot">Forgot password?</Link>
                        <br />
                      </div>
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
