import React from 'react';
import i18n from 'i18n-js';

import '../header/rsuite-default-custom.css';

import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

export default function Audit(props) {
  return (
    <>
      <div className="view">
        <ViewHeader title={i18n.t('audit_title')} subtitle={i18n.t('audit_subtitle')} />
        <ViewContent />
      </div>
    </>
  );
}
