import React from 'react';
import i18n from 'i18n-js';

import PieChart from '../../components/charts/PieChart';

export default function StatsChart({
  response,
  dataKey = 'value',
  nameKey = 'name',
  onClick,
  legend,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft
}) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  return (
    <PieChart
      data={response}
      dataKey={dataKey}
      nameKey={nameKey}
      onClick={onClick}
      legend={legend}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
    />
  );
}
