import React, { useState } from 'react';
import i18n from 'i18n-js';

import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

import numeral from 'numeral';

import moment from 'moment';
import { ResponseLoading } from '../../components/utils/Loading';
import { ExportExcelAudience } from '../../components/utils/ExportExcel';

import POIsMatchMap from './POIsMatchMap';
import POIsMatchList from './POIsMatchList';

export default function AudienceInsights({
  responseData,
  allMatch,
  filter = {},
  configFilters = {}
}) {
  const pois =
    responseData && (!responseData.hasValue || responseData.hasValue('pois'))
      ? responseData.data.value.pois
      : [];

  const type = responseData && responseData.filter ? responseData.filter.group : null;

  const name = responseData ? responseData.name : null;

  const [poisSelected, setPoiSelected] = useState(null);

  const filterLabelSingle = (title, field) =>
    filter[field] ? (
      <div key={field}>
        <br />
        {title}: <span className="font-weight-bold">{filter[field].label}</span>
      </div>
    ) : null;

  const filterLabelMulti = (title, field, extra) =>
    filter[field] ? (
      <div key={field}>
        <br />
        {title}:{' '}
        <span className="font-weight-bold">
          {filter[field].map(a => a.label).join(', ')} {extra ? `(${extra})` : null}
        </span>
      </div>
    ) : null;

  const filterLabelPeriod = (title, field) =>
    filter[field] ? (
      <div key={field}>
        <br />
        {title}:{' '}
        <span className="font-weight-bold">
          {window._env_.REACT_APP_TYPE_PERIOD === 'fixed'
            ? filter[field].label
            : `${moment(filter[field]).format('MMMM')} ${moment(filter[field]).format('YYYY')}`}
        </span>
      </div>
    ) : null;

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 mb-4">
        <Card>
          <CardBody>
            <CardTitle className="text-uppercase h6">
              {i18n.t('results_title')}
              {name && ` - ${name}`}
            </CardTitle>
            <div className="row">
              <div className="mb-4 col-sm-12 col-md-12">
                <Card>
                  <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                      <CardBody>
                        <CardTitle className="text-uppercase small font-weight-bold">
                          {i18n.t('results_filters')}
                        </CardTitle>
                        <div style={{ width: '100%', minHeight: '40px' }} className="small">
                          {filterLabelPeriod(i18n.t('period'), 'period')}
                          {filterLabelSingle(i18n.t('admin_pois_type'), 'group')}
                          {filterLabelSingle(i18n.t('admin_pois_city'), 'city')}
                          {configFilters.map(f => {
                            if (f.type === 'single') {
                              return filterLabelSingle(f.label, f.field);
                            } else if (f.type === 'multi') {
                              return filterLabelMulti(f.label, f.field);
                            }
                            return null;
                          })}

                          {filterLabelMulti(i18n.t('admin_menu_poi'), 'pois', filter.poiDistance)}
                          {filterLabelSingle(i18n.t('gender'), 'gender')}
                          {filterLabelMulti(i18n.t('age_range'), 'ageRanges')}
                          {filterLabelMulti(i18n.t('socioeconomic_level'), 'classes')}
                          {filterLabelMulti(i18n.t('apps_usage'), 'genres')}
                          {filterLabelMulti(i18n.t('offline_behavior'), 'offlines')}
                          {filterLabelSingle(i18n.t('platform'), 'platform')}
                          {filterLabelMulti(i18n.t('make'), 'makers')}
                        </div>
                      </CardBody>
                    </Col>
                    <Col xs={12} md={4}>
                      <CardBody className="pull-right-md">
                        <CardTitle className="text-uppercase small font-weight-bold">
                          {i18n.t('estimated_users')}
                          <ResponseLoading response={responseData} />
                        </CardTitle>

                        <div style={{ width: '100%', height: '40px' }}>
                          <h3 className="mr-2 font-weight-normal">
                            {numeral(
                              responseData.data && responseData.data.value
                                ? responseData.data.value.total || 0
                                : 0
                            ).format('0,0')}
                          </h3>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="col-12 col-sm-12 col-md-12 mb-4">
        <Card>
          <CardBody>
            <CardTitle className="text-uppercase h6">
              <div className="d-flex align-items-center">
                <div style={{ width: '100%' }}>{i18n.t('admin_menu_poi', { type })}</div>
                {allMatch && allMatch.length > 0 && (
                  <div style={{ width: '15vw', textAlign: 'right' }}>
                    <ExportExcelAudience {...{ responseData, allMatch, type }} />{' '}
                  </div>
                )}
              </div>
            </CardTitle>
            <div className="row">
              <div className="mb-4 col-sm-12 col-md-4">
                <Card>
                  <CardBody>
                    <CardTitle className="text-uppercase small font-weight-bold">
                      {i18n.t('top_match')}{' '}
                      {responseData.data && responseData.data.value
                        ? `(${responseData.data.value.topMatch} ${i18n.t('out_of')} ${numeral(
                            responseData.data.value.poisCount || 0
                          ).format('0,0')})`
                        : ''}
                      <ResponseLoading response={responseData} />
                    </CardTitle>
                    <div style={{ width: '100%', height: '55.5vh' }}>
                      <POIsMatchList
                        response={pois}
                        setSelected={setPoiSelected}
                        selected={poisSelected}
                        type={type}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="mb-4 col-sm-12 col-md-8">
                <Card>
                  <CardBody>
                    <CardTitle className="text-uppercase small font-weight-bold">
                      {i18n.t('map')}
                      <ResponseLoading response={responseData} />
                    </CardTitle>
                    <div style={{ width: '100%', height: '55.5vh' }}>
                      <POIsMatchMap
                        response={pois}
                        setSelected={setPoiSelected}
                        selected={poisSelected}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
