import React, { useState } from 'react';
import numeral from 'numeral';
import { PieChart as RePieChart, Pie, Legend, Cell, Sector, ResponsiveContainer } from 'recharts';

import { colors } from '../../../components/charts/Styles';

const renderActiveShape2 = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    name
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={payload.fill}>
        {`${payload.group} - ${name}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#000">
        {`${numeral(payload.total).format('0,0')} (${numeral(percent).format('0.0%')})`}
      </text>
    </g>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fontSize={10}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function PieChart({
  data,
  dataKey,
  data2,
  dataKey2,
  onClick,
  nameKey = 'name',
  nameKey2 = 'name',
  startAngle = 0,
  endAngle = 360,
  legend = true,
  marginTop = 0,
  marginBottom = -17,
  marginRight = 0,
  marginLeft = 0
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (data, index) => setActiveIndex(index);

  if (!data || !data2) return null;

  return (
    <ResponsiveContainer>
      <RePieChart
        margin={{ top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }}
      >
        <Pie
          onMouseEnter={onPieEnter}
          // onClick={onClick}
          data={data}
          // activeIndex={activeIndex}
          // activeShape={renderActiveShape}
          dataKey={dataKey}
          outerRadius={60}
          paddingAngle={2}
          // startAngle={startAngle}
          // endAngle={endAngle}
          nameKey={nameKey}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell fill={entry.color || colors[index]} key={index} />
          ))}
        </Pie>
        <Pie
          onMouseEnter={onPieEnter}
          onClick={onClick}
          data={data2}
          activeIndex={activeIndex}
          activeShape={renderActiveShape2}
          dataKey={dataKey2}
          outerRadius={90}
          innerRadius={70}
          paddingAngle={2}
          startAngle={startAngle}
          endAngle={endAngle}
          nameKey={nameKey2}
        >
          {data2.map((entry, index) => {
            const s = data2.length / data.length; // segments
            const i = Math.floor(index / s);

            return <Cell fill={colors[i]} key={index} />;
          })}
        </Pie>
        {legend === true ? (
          <Legend
            payload={data.map((item, index) => ({
              id: item.group,
              color: colors[index],
              type: 'square',
              value: `${item.group}`
            }))}
          />
        ) : null}
      </RePieChart>
    </ResponsiveContainer>
  );
}

export default React.memo(PieChart);
// <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={11} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
// <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={11} dy={18} textAnchor={textAnchor} fill="#999" >
//   {`(${(percent * 100).toFixed(2)}%)`}
// </text>
