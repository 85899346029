import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  ReferenceLine,
  CartesianGrid,
  Legend,
  Tooltip
} from 'recharts';

import { colors } from '../../components/charts/Styles';

import CustomTooltip from './CustomTooltip';

export default function PerformanceChart({ response }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  return (
    <ResponsiveContainer width="100%">
      <BarChart data={response} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          dataKey="name"
          tickCount={10}
          interval={0}
          tickFormatter={value => (value.length > 15 ? `${value.substr(0, 12)}...` : value)}
        />
        <YAxis tickFormatter={value => numeral(value).format('%0,0')} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="perc" name={i18n.t('performance_variation')} fill={colors[1]} />
      </BarChart>
    </ResponsiveContainer>
  );
}
