import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import POIMarker from '../../components/heatmap/POIMarker';
import { options } from '../../components/heatmap/config';

export default function POIsMatchMap({ response, selected, setSelected }) {
  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom: 5
  };

  const pois = response;

  return (
    <>
      <Map className="markercluster-map-full" center={defaultProps.center} zoom={defaultProps.zoom}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {pois.map(p => (
          <POIMarker
            key={p._id}
            poi={p}
            icon={p._id === selected ? options.yellowIcon : null}
            onClick={() => setSelected(p._id)}
          />
        ))}
      </Map>
    </>
  );
}
