import React, { useState, useEffect } from 'react';

import { useMappedState, useDispatch } from 'redux-react-hook';

import { logout } from '../../redux/ducks/auth';

import {
  useDefaultFilter,
  syncFilters,
  setFilter,
  filterSelector
} from '../../redux/ducks/filters';

import Nav from '../nav/Nav';
import Header from '../header/Header';

import './app_layout.scss';

export default function AppLayout(props) {
  // TODO: define a "useFilter"
  const dispatch = useDispatch();

  const { filter, defaultFilter, ranges, synchronized } = useMappedState(filterSelector);

  useEffect(() => {
    if (synchronized && filter.pois.length === 0) {
      dispatch(useDefaultFilter());
    }
  }, [synchronized]);

  useEffect(() => {
    dispatch(syncFilters());
  }, []);

  const [navMini, setNavMini] = useState(props.navMini);

  const logoutDispath = () => dispatch(logout());
  const setFilterDispath = filter => {
    dispatch(setFilter(filter));
    dispatch(syncFilters());
  };

  const toggleNav = e => {
    e.preventDefault();
    setNavMini(!navMini);
  };

  const hideNav = e => {
    e.stopPropagation();
    e.preventDefault();
    setNavMini(false);
  };

  if (!filter) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="app-wrapper">
      <Nav mini={navMini} toggleNav={toggleNav} user={props.user} />
      <div className={`content-container ${navMini ? 'full' : ''}`}>
        {/* dropshadow for mobile nav triggering */}
        <div
          className="menu-dropshadow"
          style={{ display: navMini ? 'block' : 'none' }}
          onClick={hideNav}
        />
        <Header
          toggleNav={toggleNav}
          logout={logoutDispath}
          filter={filter}
          defaultFilter={defaultFilter}
          ranges={ranges}
          setFilter={setFilterDispath}
          user={props.user}
          fullMonth={props.fullMonth}
          useAggregation={props.useAggregation}
          filterSinglePOI={props.filterSinglePOI}
        />
        {props.children}
      </div>
    </div>
  );
}
