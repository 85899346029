import React from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';
import './heatmap.scss';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useDashboardData } from '../../hooks/metrics/esdata';
import { getQueryVariable } from '../../../routeUtils';

const mapState = state => ({
  filter: state.filters.filter
});

export default function Heatmap() {
  const { filter } = useMappedState(mapState);

  const type = getQueryVariable('type');

  const { responseHeatmap } = useDashboardData(filter, {
    qHeatmap: true
  });

  return (
    <div className="view">
      <ViewHeader title={i18n.t('heatmaps_title')} />
      <ViewContent responseHeatmap={responseHeatmap} type={type} />
    </div>
  );
}
