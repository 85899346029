import React from 'react';
import numeral from 'numeral';
import {
  BarChart as ReBarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

export default function BarChart({
  data,
  dataKeyX,
  dataKey,
  dataKey2,
  stroke,
  stroke2,
  marginTop = 0,
  marginBottom = 30,
  marginRight = 20,
  marginLeft = 25,
  noLegend = false
}) {
  if (!data) return null;
  return (
    <ResponsiveContainer>
      <ReBarChart
        data={data}
        margin={{ top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }}
      >
        <YAxis tickFormatter={value => numeral(value).format('0,0')} />
        <XAxis dataKey={dataKeyX} />
        <Tooltip />
        {noLegend ? null : <Legend />}
        <Bar dataKey={dataKey} fill={stroke} />
        {dataKey2 ? <Bar dataKey={dataKey2} fill={stroke2} /> : null}
      </ReBarChart>
    </ResponsiveContainer>
  );
}
