import React, { useState } from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { CardBody, CardTitle, Popover, PopoverHeader, PopoverBody, Tooltip } from 'reactstrap';
import { GoPrimitiveDot } from 'react-icons/go/';
import { colors } from '../../components/charts/Styles';

export default function POIsMatchList({ response, selected, setSelected, type }) {
  const pois = response;

  const [popoverSelected, setPopoverSelected] = useState('');

  const [tooltipSelected, setTooltipSelected] = useState('');

  return (
    <div
      style={{
        maxHeight: '600px',
        overflowY: 'scroll'
      }}
    >
      {pois.map(p => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <CardBody
          key={p._id}
          style={{
            borderBottom: '1px solid #eceff1',
            backgroundColor: selected === p._id ? colors[10] : '#fff'
          }}
          onMouseOver={() => setSelected(p._id)}
          onMouseOut={() => setSelected(null)}
        >
          <CardTitle className="text-uppercase small font-weight-bold">
            <div className="d-flex justify-content-between">
              <span>{p.name}</span>
              <span
                style={{ color: colors[0], cursor: 'pointer' }}
                id={`Popover-${p._id}`}
                onClick={() => setPopoverSelected(p._id)}
              >
                {numeral(p.matchRate).format('%')}
              </span>
              <Popover
                placement="bottom"
                isOpen={popoverSelected === p._id}
                target={`Popover-${p._id}`}
                toggle={() => setPopoverSelected('')}
              >
                <PopoverHeader className="text-center">
                  {i18n.t('filters_score')}: {p.score} {i18n.t('out_of')} {p.scoreMax}
                </PopoverHeader>
                <PopoverBody>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{i18n.t('filters_title')}</th>
                        <th>{i18n.t('filters_value')}</th>
                        <th>{i18n.t('filters_score')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {p.matchRateDetails.map((d, index) => (
                        <tr key={`${p._id}-${index}`}>
                          <td>{d[0]}</td>
                          <td>{d[1]}</td>
                          <td style={{ textAlign: 'right' }}>
                            <GoPrimitiveDot
                              id={`Popover-Match-${p._id}-${index}`}
                              // eslint-disable-next-line no-nested-ternary
                              color={d[2] === 0 ? 'red' : d[2] === d[3] ? 'green' : 'yellow'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => setTooltipSelected(`${p._id}-${index}`)}
                            />
                            <Tooltip
                              placement="right"
                              isOpen={tooltipSelected === `${p._id}-${index}`}
                              target={`Popover-Match-${p._id}-${index}`}
                              toggle={() => setTooltipSelected('')}
                            >
                              {d[2]} {i18n.t('out_of')} {d[3]}
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </PopoverBody>
              </Popover>
            </div>
          </CardTitle>
          <div className="d-flex align-items-center">
            <span>{p.city}</span>
          </div>
          <div>
            <h4 className="mr-2 font-weight-normal mt-2">
              {i18n.t('results_reach')}: {numeral(p.unique_visitors).format('0,0')}
            </h4>
          </div>
        </CardBody>
      ))}
    </div>
  );
}
