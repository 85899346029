import React from 'react';
import { Map, TileLayer, withLeaflet } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { Curve } from 'react-leaflet-curve';

export const NeighborhoodMap = props => {
  const { neighborhoodData, zoom } = props;

  const CurveLeaflet = withLeaflet(Curve);

  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom
  };

  return (
    <>
      <Map className="markercluster-map-full" center={defaultProps.center} zoom={defaultProps.zoom}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {neighborhoodData.map(p => (
          <CurveLeaflet
            positions={p.positions}
            option={{ color: p.color, weight: 0.5 }}
            key={p.id}
          />
        ))}
      </Map>
    </>
  );
};
