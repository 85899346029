import React from 'react';
import i18n from 'i18n-js';

import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';

export default function ViewContent() {
  return (
    <>
      <div className="view-content view-dashboard">
        <CardGroup className="metric-card mb-12">
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase font-weight-bold">
                {i18n.t('metrics_title')}
              </CardTitle>
              {window._env_.REACT_APP_MODULE_CATEGORY_PDV === 'true' ||
              window._env_.REACT_APP_MODULE_CATEGORY_REPORTS === 'true' ? (
                <>
                  <CardTitle className="text-uppercase font-weight-bold">
                    {i18n.t('dashboard_pdv')}
                  </CardTitle>
                  {block(
                    i18n.t('total_unique_visitors_title'),
                    i18n.t('total_unique_visitors_text')
                  )}
                  {block(i18n.t('frequency_title'), i18n.t('frequency_text'))}
                  {block(i18n.t('total_visits_title'), i18n.t('total_visits_text'))}
                  {block(i18n.t('repeat_visitors_title'), i18n.t('repeat_visits_text'))}
                  {block(i18n.t('new_visitors_title'), i18n.t('new_visitors_text'))}
                  {/* {block(i18n.t('visitors_ratio_title'), i18n.t('visitors_ratio_text'))} */}
                  {block(i18n.t('avg_dwell_time_title'), i18n.t('avg_dwell_time_text'))}
                  {/* {block(i18n.t('daily_visits_title'), i18n.t('daily_visits_text'))} */}
                  {/* {block(i18n.t('avg_visitors_title'), i18n.t('avg_visitors_text'))} */}
                  {/* {block(i18n.t('lifestyle_title'), i18n.t('lifestyle_text'))} */}
                  {/* {block(i18n.t('demographics_title'), i18n.t('demographics_text'))} */}
                  {/* {block(i18n.t('top_apps_title'), i18n.t('top_apps_text'))} */}
                </>
              ) : (
                <></>
              )}
              {window._env_.REACT_APP_MODULE_CATEGORY_OOH === 'true' ? (
                <>
                  <CardTitle className="text-uppercase font-weight-bold">
                    {i18n.t('dashboard_ooh')}
                  </CardTitle>
                  {block(
                    i18n.t('total_unique_visitors_title_ooh'),
                    i18n.t('total_unique_visitors_text')
                  )}
                  {block(i18n.t('frequency_title'), i18n.t('frequency_text_ooh'))}
                  {block(i18n.t('total_visits_title_ooh'), i18n.t('total_visits_text_ooh'))}
                  {block(i18n.t('repeat_visitors_title_ooh'), i18n.t('repeat_visits_text_ooh'))}
                  {block(i18n.t('new_visitors_title_ooh'), i18n.t('new_visitors_text_ooh'))}
                </>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </CardGroup>
        <CardGroup className="metric-card mb-12 mt-4">
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase font-weight-bold">
                {i18n.t('definitions_title')}
              </CardTitle>
              {block(i18n.t('visitor_title'), i18n.t('visitor_text'))}
              {block(i18n.t('dwell_time_title'), i18n.t('dwell_time_text'))}
              {block(i18n.t('work_title'), i18n.t('work_text'))}
              {block(i18n.t('home_title'), i18n.t('home_text'))}
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </>
  );
}

function block(title, text) {
  return (
    <>
      <p className="text-uppercase h6">{title}</p>
      <p className="small mb-4 card-subtitle">{text}</p>
    </>
  );
}
