import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { Card, CardBody } from 'reactstrap';
import { MdTrendingDown, MdTrendingUp } from 'react-icons/md/';

export default function VisitsPerformanceCard({ title, current, prev, format, appendText }) {
  const percStyle = {
    textAlign: 'center',
    marginBottom: '0px',
    marginTop: '0px'
  };

  const variationBlock = (current, prev) => {
    let variation = <MdTrendingDown size="16" color="#c80053" />;
    if (prev !== 0) {
      variation =
        current > prev ? (
          <MdTrendingUp size="16" color="#00c853" />
        ) : (
          <MdTrendingDown size="16" color="#c80053" />
        );
    }

    let perc = [{ ...percStyle, color: 'red' }, numeral(0).format('% 0,0.0')];
    if (prev !== 0) {
      perc =
        current > prev
          ? [{ ...percStyle, color: '#18cb5d' }, numeral(current / prev - 1).format('% 0,0.0')]
          : [
              { ...percStyle, color: 'red' },
              numeral(Math.abs(current / prev - 1)).format('% 0,0.0')
            ];
    }

    return (
      <div style={perc[0]}>
        {variation}
        {perc[1]}
      </div>
    );
  };

  return (
    <Card className="mb-4">
      <CardBody>
        <h6 className="text-uppercase title font-weight-bold text-center">{title}</h6>
        <br />
        <h6 className="font-weight-normal mb-0 text-center">
          {i18n.t('performance_previous_period')}: {numeral(prev).format(format)}
          {appendText}
        </h6>
        <br />
        <h6 className="font-weight-normal mb-0 text-center">
          {i18n.t('performance_selected_period')}: {numeral(current).format(format)}
          {appendText}
        </h6>
        <br />
        <h6 className="font-weight-normal mb-0 text-center">{variationBlock(current, prev)}</h6>
      </CardBody>
    </Card>
  );
}
