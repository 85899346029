import React, { useState } from 'react';
import i18n from 'i18n-js';

import { Card, CardBody, Alert } from 'reactstrap';

import { useAPICaller } from '../../hooks/api';

import POIForm from './POIForm';

function ViewContent() {
  const [notification, setNotification] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [callResponse, apiCall] = useAPICaller();

  const poi = {
    name: '',
    address: '',
    latitude: 0,
    longitude: 0,
    city: '',
    uf: '',
    zipcode: '',
    country_code: '',
    phone: ''
  };

  const createPOI = (formValues, newPoi) => {
    const poiData = {
      ...formValues
    };
    poiData.latitude = newPoi.latitude;
    poiData.longitude = newPoi.longitude;
    // console.log('poiData', poiData);
    apiCall('pois.create', poiData);
  };

  const notificationColor = notification ? notification.type : 'info';

  return (
    <div className="view-content view-components">
      <Card className="mb-4">
        <CardBody className="table-responsive">
          <h6 className="mb-4 text-uppercase">{i18n.t('admin_poi_new')}</h6>

          <Alert
            color={notificationColor}
            isOpen={!!notification}
            toggle={() => setNotification(null)}
            className="mb-4"
          >
            {notification && notification.msg}
          </Alert>

          <POIForm poi={poi} notification={notification} onSubmit={createPOI} submitText="Crear" />
        </CardBody>
      </Card>
    </div>
  );
}

export default function POICreate(props) {
  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('admin_menu_poi')}</h1>
          <p className="mb-0">{i18n.t('admin_poi_title')}</p>
        </header>
      </div>
      <ViewContent />
    </div>
  );
}
