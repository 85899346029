import React from 'react';
import { Circle } from 'react-leaflet';
import { colors } from '../charts/Styles';

export default function ZoneMarker({ poi, onClick }) {
  const greenOptions = { color: colors[1], fillColor: colors[1] };

  return (
    <Circle
      center={[parseFloat(poi.latitude), parseFloat(poi.longitude)]}
      pathOptions={greenOptions}
      radius={5000}
      onClick={onClick}
    />
  );
}
