import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, Button } from 'reactstrap';
import history from '../../../history';
import AuditTable from './AuditTable';
import { useAPIDataLoader } from '../../hooks/api';

export default function ViewContent(props) {
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    setFilter(filter + 1);
  }, []);

  const responseAuditData = useAPIDataLoader('audit.getAll', filter, { cache: false });
  if (responseAuditData.error) {
    console.log('getAdvisorData error', responseAuditData.error);
  }

  let audits = null;

  if (responseAuditData.hasValue('audits')) audits = responseAuditData.data.value.audits;

  return (
    <div className="view-content">
      <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <Button color="primary" onClick={() => history.push('/newaudit')}>
              {i18n.t('new')}
            </Button>
            <AuditTable audits={audits || []} />
          </CardBody>
        </Card>
      </CardGroup>
    </div>
  );
}
