import React from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Line
} from 'recharts';
import { ResponseLoading } from '../../../components/utils/Loading';
import { colors } from '../../../components/charts/Styles';

export default function ControlGroupCard({ responseControlGroup }) {
  const controlGroupData = responseControlGroup.hasValue('auditVisits')
    ? responseControlGroup.data.value.auditVisits
    : null;

  return (
    <>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('audit_control_group_title')}
                <ResponseLoading response={responseControlGroup} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t('audit_control_group_subtitle')}
              </div>
              <div style={{ width: '100%', height: '400px' }}>
                {controlGroupData ? (
                  <ResponsiveContainer>
                    <LineChart
                      data={controlGroupData}
                      margin={{ top: 10, bottom: 10, right: 30, left: 40 }}
                      padding={{ right: 10, left: 10 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day_part" />
                      <YAxis type="number" />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="real"
                        name={i18n.t('audit_visitors_real')}
                        stroke={colors[1]}
                        strokeWidth={2}
                      />
                      <Line
                        type="monotone"
                        dataKey="control"
                        name={i18n.t('audit_visitors_control')}
                        stroke={colors[2]}
                        strokeWidth={2}
                      />
                      <Line
                        type="monotone"
                        dataKey="predict"
                        stroke={colors[3]}
                        strokeDasharray="5 5"
                        name={i18n.t('audit_visitors_predict')}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="text_check">{i18n.t('no_data')}</div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
}
