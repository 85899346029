import React from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';
import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';
import { useDashboardData } from '../../../hooks/metrics/esdata';
import Logo from '../Logo';

const mapState = state => ({
  filter: state.filters.filter
});

export default function Loyalty(props) {
  const { filter } = useMappedState(mapState);

  const { responseRadar } = useDashboardData(filter, {
    qRadar: true
  });

  const filterComponent = (
    <div className="m-auto text-center small text-white">
      Powered by <br />
      <Logo brand="datrix" alt="Datrix" />
    </div>
  );

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('premium_loyalty_title')}
        subtitle={i18n.t('premium_loyalty_text')}
        filterComponent={filterComponent}
        filterComponentSize="4"
      />
      <ViewContent responseRadar={responseRadar} />
    </div>
  );
}
