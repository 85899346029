import React from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { StandardEventsWeekHourChart } from './EventsWeekHourChart';
import EventsHourChart from './EventsHourChart';

export default function VisitsTrendsFDCard({ fullDemosObj, poiType }) {
  const metricsWeekDayHour = [];
  for (let d = 1; d <= 7; d++) {
    for (let r = 1; r <= 4; r++) {
      metricsWeekDayHour.push({
        dayofweek: d,
        range: r,
        visits: fullDemosObj.fullDemos.reduce((accum, v) => accum + v[`dow_${d}_h${r}`], 0)
      });
    }
  }
  const metricsHour = [];
  for (let r = 1; r <= 4; r++) {
    let v = 0;
    let vW = 0;
    for (let d = 1; d <= 2; d++) {
      vW += fullDemosObj.fullDemos.reduce((accum, v) => accum + v[`dow_${d}_h${r}`], 0);
    }
    for (let d = 3; d <= 7; d++) {
      v += fullDemosObj.fullDemos.reduce((accum, v) => accum + v[`dow_${d}_h${r}`], 0);
    }
    metricsHour.push({
      range: r,
      visits: v,
      visitsWeekend: vW
    });
  }

  return (
    <>
      {window._env_.REACT_APP_MODULE_REPORTS_CUSTOM_TIME_BLOCKS !== 'true' && (
        <Row>
          <div className="mb-4 col-sm-12 col-md-12">
            <Card>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t(poiType === 'ooh' ? 'mobility_per_week_ooh' : 'mobility_per_week')}
                </CardTitle>
                <div className="small mb-4 card-subtitle">
                  {i18n.t(
                    poiType === 'ooh' ? 'mobility_per_week_text_ooh' : 'mobility_per_week_text'
                  )}
                </div>
                <div style={{ width: '100%', height: '460px' }}>
                  <StandardEventsWeekHourChart
                    response={metricsWeekDayHour}
                    visits={fullDemosObj.totalVisits}
                    poiType={poiType}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      )}

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t(
                  poiType === 'ooh' ? 'mobility_per_hour_block_ooh' : 'mobility_per_hour_block'
                )}
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(
                  poiType === 'ooh'
                    ? 'mobility_per_hour_block_text_ooh'
                    : 'mobility_per_hour_block_text'
                )}
              </div>
              <div style={{ width: '100%', height: '460px' }}>
                <EventsHourChart
                  response={metricsHour}
                  visits={fullDemosObj.totalVisits}
                  poiType={poiType}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
}
