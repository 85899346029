import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { useMappedState } from 'redux-react-hook';
import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';
import { useDashboardData } from '../../../hooks/metrics/esdata';
import { useAPIDataLoader } from '../../../hooks/api';
import Logo from '../Logo';

const mapState = state => ({
  filter: state.filters.filter
});

export default function Neighborhood(props) {
  const { filter } = useMappedState(mapState);

  const { responseRadar } = useDashboardData(filter, {
    qRadar: true
  });

  const stores =
    responseRadar && responseRadar.hasValue('heatmap')
      ? responseRadar.data.value.heatmap.stores
      : [];

  const [coords, setCoords] = useState([]);

  useEffect(() => {
    if (stores.length > 0) {
      setCoords(
        stores.map(store => {
          return {
            id: store._id,
            latitude: store.latitude,
            longitude: store.longitude
          };
        })
      );
    }
  }, [stores]);

  const responseNeighborhoodData = useAPIDataLoader(
    'premium.getNeighborhoodData',
    { stores: coords },
    {
      cache: true
    }
  );
  if (responseNeighborhoodData.error) {
    console.log('getNeighborhoodData error', responseNeighborhoodData.error);
  }

  const filterComponent = (
    <div className="m-auto text-center small text-white">
      Powered by <br />
      <Logo brand="equifax" file="logo-premium.png" alt="Equifax" />
    </div>
  );

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('premium_neighborhood_patterns_title')}
        subtitle={i18n.t('premium_neighborhood_patterns_text')}
        filterComponent={filterComponent}
        filterComponentSize="4"
      />
      <ViewContent responseNeighborhoodData={responseNeighborhoodData} />
    </div>
  );
}
