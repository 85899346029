import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';
import OfflineInterestsCard from '../../dashboard/OfflineInterestsCard';
import TechCard from '../../dashboard/TechCard';
import RankingChart from '../../ranking/RankingChart';
import { colors } from '../../../components/charts/Styles';
import ResultCard from './ResultCard';
import VisitorsCard from './VisitorsCard';
import AgeRangeCard from './AgeRangeCard';
import DwellTimeCard from './DwellTimeCard';
import ControlGroupCard from './ControlGroupCard';

export default function ViewContent({ auditResponse }) {
  if (!auditResponse) return <div className="text_check">{i18n.t('no_data')}</div>;

  let rankingOOHHits = null;
  let rankingOOHUnique = null;
  let rankingPDV = null;
  let rankingPDVAttr = null;

  const auditGroups = auditResponse.hasValue('auditGroups')
    ? auditResponse.data.value.auditGroups
    : [];

  if (auditResponse.hasValue('rankingVisits')) rankingPDV = auditResponse.data.value.rankingVisits;

  if (auditResponse.hasValue('rankingVisitsAttr'))
    rankingPDVAttr = auditResponse.data.value.rankingVisitsAttr;

  if (auditResponse.hasValue('rankingOOHHits'))
    rankingOOHHits = auditResponse.data.value.rankingOOHHits;

  if (auditResponse.hasValue('rankingOOHUnique'))
    rankingOOHUnique = auditResponse.data.value.rankingOOHUnique;

  return (
    <div className="view-content">
      <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <ResultCard responseResult={auditResponse} />
            <ControlGroupCard responseControlGroup={auditResponse} />
            <VisitorsCard responseVisitors={auditResponse} />
            <DwellTimeCard responseDwellTime={auditResponse} auditGroups={auditGroups} />
            <AgeRangeCard responseAgeRange={auditResponse} auditGroups={auditGroups} />
            <OfflineInterestsCard responseVisits={auditResponse} poiType="pdv" />
            <TechCard
              responseAppBundles={auditResponse}
              responseTech={auditResponse}
              poiType="pdv"
            />
            {rankingOOHHits && rankingOOHHits.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_ooh_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_ooh_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingOOHHits} color={colors[1]} type="ooh" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : (
              <></>
            )}
            {rankingOOHUnique && rankingOOHUnique.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_attribution_ooh_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_attribution_ooh_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingOOHUnique} color={colors[1]} type="ooh" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : (
              <></>
            )}
            {rankingPDV && rankingPDV.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_pdv_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_pdv_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingPDV} color={colors[3]} type="pdv" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : (
              <></>
            )}
            {rankingPDVAttr && rankingPDVAttr.length > 0 ? (
              <Row>
                <div className="mb-2 col-sm-12 col-md-12">
                  <Card style={{ flex: '3 0 0' }}>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        {i18n.t('ranking_attribution_pdv_title')}
                      </CardTitle>
                      <div className="small mb-4 card-subtitle">
                        {i18n.t('ranking_attribution_pdv_subtitle')}
                      </div>
                      <div style={{ width: '100%', height: '500px' }}>
                        <RankingChart response={rankingPDVAttr} color={colors[3]} type="pdv" />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            ) : (
              <></>
            )}
          </CardBody>
        </Card>
      </CardGroup>
    </div>
  );
}
