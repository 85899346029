import React from 'react';
import { Card, CardBody } from 'reactstrap';

export default function VisitsCard({ title, value }) {
  return (
    <Card className="mb-4" style={{ minHeight: '102px' }}>
      <CardBody>
        <div className="text-uppercase small font-weight-bold card-title">{title}</div>
        <div className="d-flex align-items-center">
          <h3 className="mr-2 font-weight-normal">{value}</h3>
        </div>
      </CardBody>
    </Card>
  );
}
