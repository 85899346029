import axios from 'axios';
import { generateHash } from './mofidata';
import { refreshToken, logout, metricsCache } from '../redux/ducks/auth';

let isRefreshingToken = false;

export default {
  call(name, parameters, callback) {
    if (typeof parameters.length === 'undefined') {
      parameters = [parameters];
    }

    const hash = generateHash(name, parameters);

    if (metricsCache.peek(hash)) {
      const data = metricsCache.get(hash);
      callback(null, data);
    } else {
      callApi();
    }

    function callApi() {
      axios
        .post('/v2/methods/call', {
          name,
          params: parameters
        })
        .then(res => {
          metricsCache.set(hash, res.data);
          callback(null, res.data);
        })
        .catch(err => {
          console.log('API.call error', name, parameters, err);
          callback(err, {});
        });
    }
  },
  noCacheCall(name, parameters, callback) {
    if (typeof parameters.length === 'undefined') {
      parameters = [parameters];
    }

    axios
      .post('/v2/methods/call', {
        name,
        params: parameters,
        noCache: true
      })
      .then(res => {
        callback(null, res.data);
      })
      .catch(err => {
        console.log('API.noCacheCall error', name, parameters, err);
        callback(err, {});
      });
  },
  configureInterceptors(store, token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    axios.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config;

        const refreshTokenUrl = `${axios.defaults.baseURL}/v2/auth/refresh-token`;

        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          refreshToken &&
          originalRequest.url !== refreshTokenUrl &&
          !isRefreshingToken
        ) {
          originalRequest._retry = true;
          isRefreshingToken = true;
          return store.dispatch(refreshToken());
        }

        if (
          error.response &&
          error.response.status === 401 &&
          originalRequest._retry &&
          refreshToken
        ) {
          store.dispatch(logout());
        }

        return Promise.reject(error);
      }
    );
  }
};
