import React from 'react';
import i18n from 'i18n-js';

export default function ViewHeader({
  title = i18n.t('dashboard_title'),
  subtitle = i18n.t('dashboard_text'),
  filterComponent,
  infoComponent,
  filterComponentSize = 8
}) {
  return (
    <div className="view-header d-flex align-items-center">
      <header
        className={`text-white ${filterComponent ? `col-${12 - filterComponentSize}` : null}`}
      >
        <h1 className="h5 title text-uppercase">{title}</h1>
        <p className="mb-0 subtitle">
          {subtitle}&nbsp;{infoComponent}
        </p>
      </header>
      {filterComponent && (
        <div className={`view-filter col-${filterComponentSize}`}>{filterComponent}</div>
      )}
    </div>
  );
}
