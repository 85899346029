import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';

import { CustomEventsWeekHourChart } from './EventsWeekHourChart';

import { ResponseLoading } from '../../components/utils/Loading';

export default function VisitorsByTimeBlockCard({ responseVisits, fullDemosObj, poiType }) {
  return (
    <CardGroup className="metric-card mb-4">
      <Card style={{ flex: '3 0 0' }}>
        <CardBody>
          <CardTitle className="text-uppercase h6">
            {i18n.t(poiType === 'ooh' ? 'mobility_per_week_ooh' : 'mobility_per_week')}
            <ResponseLoading response={responseVisits} />
          </CardTitle>

          <div className="small mb-4 card-subtitle">
            {i18n.t(poiType === 'ooh' ? 'mobility_per_week_text_ooh' : 'mobility_per_week_text')}
          </div>
          <div style={{ width: '100%', height: '400px' }}>
            <CustomEventsWeekHourChart
              response={responseVisits}
              visits={fullDemosObj.totalVisits}
              poiType={poiType}
            />
          </div>
        </CardBody>
      </Card>
    </CardGroup>
  );
}
