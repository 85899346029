import moment from 'moment';
import { createSelector } from 'reselect';
import API from '../../api';

// Actions

const FILTER_SET_POIS = 'filter/SET_POIS';
const FILTER_SET_FILTER = 'auth/SET_FILTER';
const FILTER_USE_DEFAULT_FILTER = 'auth/USE_DEFAULT_FILTER';
// const FILTER_SYNC_RANGES = 'auth/SYNC_RANGES';
const FILTER_CLEAR = 'auth/CLEAR';

// Reducer

const defaultFilter = {
  startDate: moment()
    .subtract(1, 'month')
    .startOf('month')
    .valueOf(),
  endDate: moment()
    .subtract(1, 'month')
    .endOf('week')
    .valueOf(),
  offset: moment()
    .toDate()
    .getTimezoneOffset(),
  // range: 'Last 30 Days: ' + moment().subtract(29, 'days').startOf('day').format('MMMM D, YYYY') + ' - ' + moment().endOf('day').format('MMMM D, YYYY'),
  // range: `${moment()
  //   .subtract(29, 'days')
  //   .startOf('day')
  //   .format('MMMM D, YYYY')} - ${moment()
  //   .endOf('day')
  //   .format('MMMM D, YYYY')}`,
  // rangeLabel: 'Last 30 Days',
  // pois: ALL_POIS_LIST,
  pois: [],
  groupKeys: [],
  storeKey: null,
  agg: 'g'
};

const initialState = {
  pois: [],
  defaultFilter,
  filter: defaultFilter,
  synchronized: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FILTER_CLEAR:
      return initialState;
    case FILTER_SET_POIS:
      const { pois } = action.payload;

      return {
        ...state,
        pois,
        defaultFilter: {
          ...state.defaultFilter,
          pois
        },
        filter: {
          ...state.filter,
          // groupKeys: indexAllAppsSelect > -1 ? [] : state.filter.groupKeys
          groupKeys: state.filter.groupKeys
        }
      };
    case FILTER_SET_FILTER:
      return {
        ...state,
        filter: {
          ...action.payload.filter
        }
      };
    case FILTER_USE_DEFAULT_FILTER:
      return {
        ...state,
        filter: state.defaultFilter
      };
    // case FILTER_SYNC_RANGES:
    //   const updatedRanges = action.payload.ranges;
    //   return {
    //     ...state,
    //     ranges: updatedRanges,
    //     defaultFilter: {
    //       ...state.defaultFilter,
    //       startDate: updatedRanges.filter(r => r.label === state.defaultFilter.rangeLabel)[0],
    //       endDate: updatedRanges.filter(r => r.label === state.defaultFilter.rangeLabel)[1],
    //       offset: new Date().getTimezoneOffset()
    //     },
    //     synchronized: true
    //   };
    default:
      return state;
  }
}

// Action Creators

export function setPOIs(pois) {
  return {
    type: FILTER_SET_POIS,
    payload: { pois }
  };
}

export function setFilter(filter) {
  return {
    type: FILTER_SET_FILTER,
    payload: {
      filter: {
        ...filter,
        period: filter.period ? filter.period : null,
        startDate: filter.startDate ? filter.startDate.valueOf() : null,
        endDate: filter.endDate ? filter.endDate.valueOf() : null,
        offset: moment(filter.startDate)
          .toDate()
          .getTimezoneOffset(),
        type: filter.type ? filter.type : null
      }
    }
  };
}

export function clearFilter() {
  return {
    type: FILTER_CLEAR
  };
}

export function useDefaultFilter() {
  return {
    type: FILTER_USE_DEFAULT_FILTER
  };
}

// export function syncRanges() {
//   return {
//     type: FILTER_SYNC_RANGES,
//     payload: {
//       ranges // global
//     }
//   };
// }

export function syncFilters() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.filters.synchronized) return;

    // if(state.filters.pois && state.filters.pois.length>0){
    //   const range = state.filters.pois[0].range;
    //   const sd = state.filters.filter.startDate + state.filters.filter.offset * 60000
    //   if(sd >= range[0] ** sd <= range[1]){
    //     console.log("in range, keep filter")
    //     return
    //   }
    // }

    // API.noCacheCall(
    API.call(
      'pois.getAllForFilters',
      {
        period: state.filters.filter.period ? state.filters.filter.period : null,
        startDate: state.filters.filter.startDate ? state.filters.filter.startDate : null,
        offset: state.filters.filter.offset,
        type: state.filters.filter.type ? state.filters.filter.type : null
      },
      (err, res) => {
        if (err) {
          console.log('pois.getAllForFilters error', err);
        } else {
          // console.log(res);
          dispatch(setPOIs(res));
        }
      }
    );
  };
}

// function processPOIs(pois) {
//   const groupKeys = [];

//   return {
//     pois,
//     groupKeys
//   };
// }

// Selectors

export function defaultFilterSelector(state) {
  return {
    filter: state.filters.filter,
    defaultFilter: state.filters.defaultFilter,
    ranges: state.filters.ranges,
    pois: state.filters.pois
  };
}

// Selector to replace all epoch to moment objects.
export const filterSelector = createSelector(
  defaultFilterSelector,
  filters =>
    // const ranges = {};
    // for (const rangeIndex in filters.ranges) {
    //   const range = filters.ranges[rangeIndex];
    //   ranges[rangeIndex] = range.map(epoch => moment(epoch));
    // }

    ({
      ...filters,
      defaultFilter: {
        ...filters.defaultFilter,
        startDate: moment(filters.defaultFilter.startDate),
        endDate: moment(filters.defaultFilter.endDate),
        offset: moment(filters.defaultFilter.startDate)
          .toDate()
          .getTimezoneOffset()
      },
      filter: {
        ...filters.filter,
        startDate: moment(filters.filter.startDate),
        endDate: moment(filters.filter.endDate),
        offset: moment(filters.filter.startDate)
          .toDate()
          .getTimezoneOffset()
      }
      // ranges: filters.ranges
    })
);
