import React from 'react';
import i18n from 'i18n-js';
import { Card, Row, CardBody, CardTitle } from 'reactstrap';
import DashboardTopFDCard from './DashboardTopFDCard';
import VisitsTrendsCard from './VisitsTrendsCard';
import VisitsTrendsFDCard from './VisitsTrendsFDCard';
import DemographicsCard from './DemographicsCard';
import TechCard from './TechCard';
import VisitsMetricsFDCard from './VisitsMetricsFDCard';
import OfflineInterestsCard from './OfflineInterestsCard';
import VisitorsByDayCard from './VisitorsByDayCard';
import VisitorsByTimeBlock from './VisitorsByTimeBlockCard';

export default function ViewContent({
  filter,
  responseVisits,
  responseTech,
  responseAppBundles,
  fullDemosObj,
  poiType
}) {
  const groupCount = filter.storeKey
    ? 1
    : filter.pois && filter.pois.length > 0
    ? filter.pois[0].count
    : -1;

  return (
    <div className="view-content view-dashboard">
      {window._env_.REACT_APP_MODULE_REPORTS_DAILY === 'true' && (
        <VisitorsByDayCard responseVisits={responseVisits} />
      )}
      {window._env_.REACT_APP_MODULE_REPORTS_CUSTOM_TIME_BLOCKS === 'true' && (
        <VisitorsByTimeBlock
          responseVisits={responseVisits}
          fullDemosObj={fullDemosObj}
          poiType={poiType}
        />
      )}
      <DemographicsCard fullDemosObj={fullDemosObj} />

      <DashboardTopFDCard
        filter={filter}
        fullDemosObj={fullDemosObj}
        groupCount={groupCount}
        poiType={poiType}
      />

      <VisitsMetricsFDCard fullDemosObj={fullDemosObj} poiType={poiType} />

      <VisitsTrendsFDCard fullDemosObj={fullDemosObj} poiType={poiType} />

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <hr />
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('mobility_trends_general_info')}
              </CardTitle>
              <div className="mb-4 card-subtitle">
                {filter.pois && filter.pois.length > 0 ? filter.pois[0].label : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>

      <VisitsTrendsCard filter={filter} responseVisits={responseVisits} poiType={poiType} />

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <hr />
        </div>
      </Row>

      <OfflineInterestsCard responseVisits={responseVisits} poiType={poiType} />

      <TechCard
        responseAppBundles={responseAppBundles}
        responseTech={responseTech}
        poiType={poiType}
      />
    </div>
  );
}
