import React from 'react';
// import numeral from 'numeral';
import {
  Radar,
  RadarChart as ReRadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer
} from 'recharts';

export default function RadarChart({
  data,
  dataKeyX,
  dataKeyY,
  nameY,
  stroke,
  dataKeyY2,
  nameY2,
  stroke2
}) {
  if (!data) return null;
  return (
    <ResponsiveContainer>
      <ReRadarChart
        cx="50%"
        cy="50%"
        outerRadius="80%"
        data={data}
        margin={{
          top: 0,
          right: 0,
          bottom: -17,
          left: 0
        }}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey={dataKeyX} />
        <PolarRadiusAxis angle={30} domain={[0, 1500]} />
        <Radar name={nameY} dataKey={dataKeyY} stroke={stroke} fill={stroke} fillOpacity={0.6} />
        <Radar
          name={nameY2}
          dataKey={dataKeyY2}
          stroke={stroke2}
          fill={stroke2}
          fillOpacity={0.6}
        />
        <Legend />
      </ReRadarChart>
    </ResponsiveContainer>
  );
}
