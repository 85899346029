import React from 'react';
import i18n from 'i18n-js';

import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md/';

export function Filter({ filter, handleFilterChange }) {
  return (
    <div className="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder={i18n.t('search_for')}
          onChange={handleFilterChange}
          value={filter}
        />
        <span className="input-group-btn" />
      </div>
    </div>
  );
}

export function SortIcon({ sortBy, field }) {
  const currentSortBy = sortBy || {};
  // const className = 'fa fa-sort pull-right';

  if (currentSortBy[field] === -1) return <MdArrowDropUp size="18" color="#363537" />;
  if (currentSortBy[field] === 1) return <MdArrowDropDown size="18" color="#363537" />;

  return null;
}
