import React, { useState } from 'react';
import i18n from 'i18n-js';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
// import numeral from 'numeral';
import moment from 'moment';

import { MdFileDownload } from 'react-icons/md/';
import { useDashboardData } from '../../hooks/metrics/esdata';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function ExportExcelPanel({ responseFullDemos, responseVisits, filter }) {
  let fullDemos = null;
  let prevCols = [];

  if (filter.agg === 'g') {
    fullDemos = responseFullDemos.hasValue('metricsFullDemos')
      ? responseFullDemos.data.value.metricsFullDemos
      : null;
  } else {
    prevCols = [{ title: i18n.t('poi_name') }];

    const [qFullDemosPDVs, setQFullDemosPDVs] = useState(false);

    const { responseFullDemosPDVs } = useDashboardData(filter, { qFullDemosPDVs });

    if (!responseFullDemosPDVs.hasValue('metricsFullDemosPDVs')) {
      const generateFile = () => {
        setQFullDemosPDVs(true);
      };

      return (
        <Button color="danger" onClick={generateFile} disabled={qFullDemosPDVs}>
          <MdFileDownload
            size="18"
            className="icon-dashboard"
            style={{ color: '#fff', cursor: 'pointer' }}
          />
          {i18n.t('download_data')}
        </Button>
      );
    }
    fullDemos = responseFullDemosPDVs.data.value.metricsFullDemosPDVs;
  }

  if (!fullDemos || fullDemos.length === 0 || !filter.pois || filter.pois.length === 0) return null;

  let period = null;
  if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed')
    period = moment(filter.startDate).format('MMMM YY');
  else period = filter.period.label;

  let labelWeek = '';
  if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed') labelWeek = i18n.t('week');
  else labelWeek = i18n.t('fraction');

  const w = { width: { wpx: 140 } };

  const multiDataSet = [
    {
      columns: [
        { title: i18n.t('period_label'), ...w },
        { title: i18n.t('segment_label'), ...w }
      ],
      data: [[{ value: period }, { value: filter.pois[0].label }]]
    },
    {
      ySteps: 1,
      columns: prevCols.concat([
        { title: i18n.t('age_range') },
        { title: i18n.t('socioeconomic_level') },
        { title: i18n.t('gender') },
        { title: i18n.t('unique_visitors_title') },
        { title: `${i18n.t('unique_visitors_title')} ${labelWeek} 1` },
        { title: `${i18n.t('unique_visitors_title')} ${labelWeek} 2` },
        { title: `${i18n.t('unique_visitors_title')} ${labelWeek} 3` },
        { title: `${i18n.t('unique_visitors_title')} ${labelWeek} 4` },
        { title: i18n.t('visits_title') },
        { title: `${i18n.t('visits_title')} ${labelWeek} 1` },
        { title: `${i18n.t('visits_title')} ${labelWeek} 2` },
        { title: `${i18n.t('visits_title')} ${labelWeek} 3` },
        { title: `${i18n.t('visits_title')} ${labelWeek} 4` },
        { title: i18n.t('frequency_title') },
        { title: `${i18n.t('dwell_time_title')} (mins)` },
        { title: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 1` },
        { title: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 2` },
        { title: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 3` },
        { title: `${i18n.t('dwell_time_title')} (mins) ${labelWeek} 4` },
        { title: i18n.t('new_visitors_title') },
        { title: `${i18n.t('new_visitors_title')} ${labelWeek} 1` },
        { title: `${i18n.t('new_visitors_title')} ${labelWeek} 2` },
        { title: `${i18n.t('new_visitors_title')} ${labelWeek} 3` },
        { title: `${i18n.t('new_visitors_title')} ${labelWeek} 4` },
        { title: i18n.t('repeat_visitors_title') },
        { title: `${i18n.t('repeat_visitors_title')} ${labelWeek} 1` },
        { title: `${i18n.t('repeat_visitors_title')} ${labelWeek} 2` },
        { title: `${i18n.t('repeat_visitors_title')} ${labelWeek} 3` },
        { title: `${i18n.t('repeat_visitors_title')} ${labelWeek} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('monday')} ${i18n.t('block')} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('tuesday')} ${i18n.t('block')} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('wednesday')} ${i18n.t('block')} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('thursday')} ${i18n.t('block')} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('friday')} ${i18n.t('block')} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('saturday')} ${i18n.t('block')} 4` },
        { title: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 1` },
        { title: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 2` },
        { title: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 3` },
        { title: `${i18n.t('visits_title')} ${i18n.t('sunday')} ${i18n.t('block')} 4` }
      ]),
      data: fullDemos.map(s => {
        let prevColsValues = [];
        if (prevCols.length > 0) {
          prevColsValues = [{ value: s.name }];
        }
        return prevColsValues.concat([
          { value: s.demographics_age_range },
          { value: s.demographics_class },
          { value: s.demographics_gender },
          { value: s.unique },
          { value: s.unique_q1 },
          { value: s.unique_q2 },
          { value: s.unique_q3 },
          { value: s.unique_q4 },
          { value: s.visits },
          { value: s.visits_q1 },
          { value: s.visits_q2 },
          { value: s.visits_q3 },
          { value: s.visits_q4 },
          { value: s.unique > 0 ? s.visits / s.unique : 0 },
          { value: s.dwell_time_mins },
          { value: s.dwell_time_mins_q1 },
          { value: s.dwell_time_mins_q2 },
          { value: s.dwell_time_mins_q3 },
          { value: s.dwell_time_mins_q4 },
          { value: s.new_visitors },
          { value: s.new_visitor_q1 },
          { value: s.new_visitor_q2 },
          { value: s.new_visitor_q3 },
          { value: s.new_visitor_q4 },
          { value: s.repeat_visitors },
          { value: s.repeat_q1 },
          { value: s.repeat_q2 },
          { value: s.repeat_q3 },
          { value: s.repeat_q4 },
          { value: s.dow_2_h1 },
          { value: s.dow_2_h2 },
          { value: s.dow_2_h3 },
          { value: s.dow_2_h4 },
          { value: s.dow_3_h1 },
          { value: s.dow_3_h2 },
          { value: s.dow_3_h3 },
          { value: s.dow_3_h4 },
          { value: s.dow_4_h1 },
          { value: s.dow_4_h2 },
          { value: s.dow_4_h3 },
          { value: s.dow_4_h4 },
          { value: s.dow_5_h1 },
          { value: s.dow_5_h2 },
          { value: s.dow_5_h3 },
          { value: s.dow_5_h4 },
          { value: s.dow_6_h1 },
          { value: s.dow_6_h2 },
          { value: s.dow_6_h3 },
          { value: s.dow_6_h4 },
          { value: s.dow_7_h1 },
          { value: s.dow_7_h2 },
          { value: s.dow_7_h3 },
          { value: s.dow_7_h4 },
          { value: s.dow_1_h1 },
          { value: s.dow_1_h2 },
          { value: s.dow_1_h3 },
          { value: s.dow_1_h4 }
        ]);
      })
    }
  ];

  const sheets = [<ExcelSheet dataSet={multiDataSet} key="1" name={i18n.t('metrics_title')} />];

  if (
    window._env_.REACT_APP_MODULE_REPORTS_DAILY === 'true' &&
    responseVisits.hasValue('metricsDaily')
  ) {
    const dailyData = responseVisits.data.value.metricsDaily;

    const multiDataSetDaily = [
      {
        columns: [
          { title: i18n.t('date_title') },
          { title: i18n.t('unique_visitors_title') },
          { title: i18n.t('visits_title') }
        ],
        data: dailyData.map(s => {
          return [{ value: s._id.day_part }, { value: s.unique_visitors }, { value: s.visits }];
        })
      }
    ];

    sheets.push(<ExcelSheet dataSet={multiDataSetDaily} key="2" name={i18n.t('by_day')} />);
  }

  if (window._env_.REACT_APP_TYPE_PERIOD !== 'fixed')
    period = moment(filter.startDate).format('YYYYMM');

  const fileName = `${window._env_.REACT_APP_BRAND}-${period}-${filter.pois[0].label}`;

  return (
    <>
      {filter.agg !== 'g' && (
        <ExcelFile filename={fileName} hideElement>
          {sheets}
        </ExcelFile>
      )}

      <ExcelFile
        filename={fileName}
        element={
          <Button color="secondary">
            <MdFileDownload
              size="18"
              className="icon-dashboard"
              style={{ color: '#fff', cursor: 'pointer' }}
            />
            {i18n.t('download_data')}
          </Button>
        }
      >
        {sheets}
      </ExcelFile>
    </>
  );
}

export function ExportExcelAudience({ responseData, allMatch, type, filter }) {
  if (!allMatch) return null;

  const w = { width: { wpx: 140 } };

  // const multiDataSet =
  //   type === 'PDV'
  //     ? [
  //         {
  //           columns: [
  //             { title: i18n.t('admin_pois_code'), ...w },
  //             { title: i18n.t('admin_pois_cp') },
  //             { title: i18n.t('admin_pois_so') },
  //             { title: i18n.t('admin_pois_address') },
  //             { title: i18n.t('admin_pois_local') },
  //             { title: i18n.t('admin_pois_type') },
  //             { title: 'Score' }
  //           ],
  //           data: allMatch.map(s => [
  //             { value: s.code },
  //             { value: s.cp_name },
  //             { value: s.sales_office },
  //             { value: s.street },
  //             { value: s.local },
  //             { value: s.typePDV },
  //             { value: s.score }
  //           ])
  //         }
  //       ]
  //     : [
  //         {
  //           columns: [
  //             { title: i18n.t('admin_pois_code'), ...w },
  //             { title: i18n.t('admin_pois_address') },
  //             { title: i18n.t('admin_pois_media_owner') },
  //             { title: i18n.t('admin_pois_media_owner_reference') },
  //             { title: i18n.t('admin_pois_media_format') },
  //             { title: i18n.t('admin_pois_media_format_type') },
  //             { title: i18n.t('admin_pois_media_frame_type') },
  //             { title: 'Score' }
  //           ],
  //           data: allMatch.map(s => [
  //             { value: s.media_owner_frame_reference },
  //             { value: s.address },
  //             { value: s.media_owner },
  //             { value: s.media_owner_reference },
  //             { value: s.format },
  //             { value: s.format_type },
  //             { title: s.frame_type },
  //             { value: s.score }
  //           ])
  //         }
  //       ];
  const multiDataSet = [
    {
      columns: [
        { title: i18n.t('admin_pois_name'), ...w },
        { title: i18n.t('admin_pois_type') },
        { title: i18n.t('estimated_users') }
      ],
      data: allMatch.map(s => [{ value: s.name }, { value: s.group }, { value: s.metrics.unique }])
    }
  ];

  const fileName = `${window._env_.REACT_APP_BRAND}-${responseData.name}`;

  return (
    <ExcelFile
      filename={fileName}
      element={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Button color="danger">
          <MdFileDownload
            size="18"
            className="icon-dashboard"
            style={{ color: '#fff', cursor: 'pointer' }}
          />
          {i18n.t('download_data')}
        </Button>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={i18n.t('audience_title')} />
    </ExcelFile>
  );
}

module.export = {
  ExportExcelPanel,
  ExportExcelAudience
};
