import React from 'react';
import i18n from 'i18n-js';
import { Row, Card, CardGroup, CardBody, CardTitle } from 'reactstrap';
import { NeighborhoodMap } from '../../../components/heatmap/NeighborhoodMap';
import { ResponseLoading } from '../../../components/utils/Loading';
import StatsChart from '../../dashboard/StatsChart';

export default function ViewContent({ responseNeighborhoodData }) {
  const neighborhoodData =
    responseNeighborhoodData && responseNeighborhoodData.hasValue('neighborhoodData')
      ? responseNeighborhoodData.data.value.neighborhoodData
      : [];

  const communityData =
    responseNeighborhoodData && responseNeighborhoodData.hasValue('communityData')
      ? responseNeighborhoodData.data.value.communityData
      : [];

  const classData =
    responseNeighborhoodData && responseNeighborhoodData.hasValue('classData')
      ? responseNeighborhoodData.data.value.classData
      : [];

  return (
    <div className="view-content">
      <div style={{ position: 'relative', zIndex: 9999, right: 0, top: '-3.5vw' }}>
        <ResponseLoading responses={[responseNeighborhoodData]} className="loading-dot-white" />
      </div>
      <Row>
        <div className="col-sm-5 col-md-5 pl-0">
          <Card className="mb-4 pr-0">
            <div style={{ width: '100%', height: '700px' }}>
              <NeighborhoodMap neighborhoodData={neighborhoodData} zoom={11} />
            </div>
          </Card>
        </div>
        <div className="col-sm-7 col-md-7 p-0">
          <CardGroup
            className="metric-card mb-4"
            style={{ flexFlow: 'row wrap', minHeight: '700px' }}
          >
            <Card style={{ flex: '3 0 0' }}>
              <CardBody className="pb-0">
                <CardTitle className="text-uppercase h6">{i18n.t('metrics_title')}</CardTitle>
                <div className="small mb-4 card-subtitle">
                  {i18n.t('mobility_trends_by_zone')}: <strong>--</strong>
                </div>

                <div className="col-sm-12 col-md-12 pr-0">
                  <Card className="mb-4">
                    <Row>
                      <div className="col-sm-12 col-md-12 pr-0">
                        <div style={{ width: '100%', height: '283px' }} className="p-3">
                          <h6>{i18n.t('premium_neighborhood_patterns_composition')}</h6>
                          <StatsChart response={communityData} dataKey="perc" legend={false} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <div style={{ width: '100%', height: '283px' }} className="p-3">
                          <h6>{i18n.t('premium_neighborhood_patterns_class')}</h6>
                          <StatsChart response={classData} dataKey="perc" legend={false} />
                        </div>
                      </div>
                    </Row>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
    </div>
  );
}
