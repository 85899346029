import React, { useState } from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';
import { useDashboardData } from '../../hooks/metrics/esdata';
import { defaultResponse, useAPIDataLoader } from '../../hooks/api';
// import VisitsDailyTrendsChart from './VisitsDailyTrendsChart';

import CompareColumn from './CompareColumn';

// import HeatMap from '../../components/heatmap/HeatMap';
import { ResponseLoading } from '../../components/utils/Loading';
import FlowMap from '../../components/heatmap/FlowMap';
import RadarMap from '../../components/heatmap/RadarMap';

const _defaultResponse = defaultResponse;
_defaultResponse.data.state = 'LOADED';

export default function ViewContent({ defaultFilter, pois, type }) {
  const responseFilters = useAPIDataLoader(
    'pois.getDataForFilters',
    {
      startDate: defaultFilter.startDate ? defaultFilter.startDate.valueOf() : null,
      endDate: defaultFilter.endDate ? defaultFilter.endDate.valueOf() : null,
      period: defaultFilter.period ? defaultFilter.period : null,
      offset: defaultFilter.offset,
      type: defaultFilter.type ? defaultFilter.type : null
    },
    { cache: true }
  );
  // if (responseFilters.error) {
  //   console.log('responseFilters error', responseFilters.error);
  // }

  const arrLength = 4;

  const filters = new Array(arrLength);
  const responses = new Array(arrLength);

  const defaultResponses = {
    responseVisits: _defaultResponse,
    responseDemos: _defaultResponse,
    responseTech: _defaultResponse,
    responseAppBundles: _defaultResponse
  };
  for (let i = 0; i < arrLength; i++) {
    filters[i] = useState({ ...defaultFilter, isFiltering: false });
    responses[i] = defaultResponses;
  }

  const [segmentsCount, setSegmentsCount] = useState([0, 0, 0, 0]);

  const onSelectPOI = (node, name) => {
    const pos = parseInt(name);
    const [filter, setFilter] = filters[pos];
    segmentsCount[pos] = node.groupCount;
    setSegmentsCount(segmentsCount);
    filter.isFiltering = true; // selected;
    filter.groupKeys = [node.group_hash];
    setFilter({ ...filter });
  };

  const addCompareMetrics = (response, segmentCount) => {
    if (response && response.hasValue('metrics') && segmentCount > 0) {
      response.data.value.metricsCompare = {
        unique_visitors: Math.round(response.data.value.metrics.unique_visitors / segmentCount),
        visits: Math.round(response.data.value.metrics.visits / segmentCount)
      };
    }
  };

  const filterMap = {};

  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') filterMap.period = filters[0][0].period;
  else {
    filterMap.offset = filters[0][0].offset.valueOf();
    filterMap.startDate = filters[0][0].startDate.valueOf();
    filterMap.endDate = filters[0][0].endDate.valueOf();
  }
  filterMap.groupKeys = [];

  let isFiltering = false;
  let allPois = false;

  // TODO move to CompareColumn
  for (let i = 0; i < arrLength; i++) {
    const filter = filters[i][0];
    if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') filter.period = defaultFilter.period;
    else {
      filter.startDate = defaultFilter.startDate.valueOf();
      filter.endDate = defaultFilter.endDate.valueOf();
    }
    isFiltering = isFiltering || filter.isFiltering;

    const { responseVisits, responseDemos, responseTech, responseAppBundles } = useDashboardData(
      filter,
      {
        qVisits: filter.isFiltering,
        qDemos: filter.isFiltering,
        qTech: filter.isFiltering,
        qAppBundles: filter.isFiltering
      }
    );

    responses[i] = { responseVisits, responseDemos, responseTech, responseAppBundles };

    addCompareMetrics(responseVisits, segmentsCount[i]);

    if (filter.isFiltering) {
      allPois = allPois || !filter.groupKeys.length;
      if (!allPois) {
        filterMap.groupKeys = [...filterMap.groupKeys, filter.groupKeys[0]];
      }
    }
  }

  const { responseHeatmap } = useDashboardData(filterMap, {
    qHeatmap: isFiltering
  });

  const { responseRadar } = useDashboardData(filterMap, {
    qRadar: isFiltering
  });

  const columns = [];

  for (let i = 0; i < arrLength; i++)
    columns.push(
      <CompareColumn
        key={i}
        pois={pois}
        filter={filters[i]}
        response={responses[i]}
        responseBase={i === 0 ? defaultResponses : responses[0]}
        responseFilters={responseFilters}
        onSelectPOI={onSelectPOI}
        name={`${i}`}
        type={type}
      />
    );
  return (
    <>
      <div className="view-content view-compare view-columns">{columns}</div>
      {/* <div className="view-content view-compare" style={{ marginTop: '0' }}>
        <CardGroup className="metric-card mb-12">
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">{i18n.t('visitors_ratio_title')}</CardTitle>
              <div className="small mb-4 card-subtitle">{i18n.t('mobility_per_day')}</div>
              <div style={{ width: '100%', height: '280px' }}>
                <VisitsDailyTrendsChart responses={responses} />
              </div>
            </CardBody>
          </Card>
        </CardGroup>
      </div> */}
      <div className="view-content view-compare" style={{ marginTop: '0' }}>
        <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('radar_title')}
                <ResponseLoading response={responseRadar} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(type === 'ooh' ? 'by_ooh' : 'by_poi')}
              </div>
              <div style={{ width: '100%', height: '600px' }}>
                {responseRadar.hasValue('heatmap') ? (
                  <RadarMap {...{ stores: responseRadar.data.value.heatmap.stores }} />
                ) : (
                  <div className="text_check">{i18n.t('no_data')}</div>
                )}
              </div>
            </CardBody>
          </Card>
        </CardGroup>
      </div>
      <div className="view-content view-compare" style={{ marginTop: '0' }}>
        <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('flow_map')}
                <ResponseLoading response={responseHeatmap} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(type === 'ooh' ? 'by_ooh' : 'by_poi')}
              </div>
              <div style={{ width: '100%', height: '600px' }}>
                {responseHeatmap.hasValue() ? (
                  <FlowMap responseHeatmap={responseHeatmap} type={type} />
                ) : (
                  <div className="text_check">{i18n.t('no_data')}</div>
                )}
              </div>
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </>
  );
}
