import React, { useState, useEffect } from 'react';
import { CardGroup, Card, CardBody } from 'reactstrap';
import StepWizard from 'react-step-wizard';
import { PeriodStep } from './PeriodStep';
import { POIStep } from './POIStep';
import history from '../../../../history';
import { useAPICaller } from '../../../hooks/api';

export default function ViewContent(props) {
  const [filter, setFilter] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [callResponse, apiCall] = useAPICaller();

  function submit() {
    apiCall('audit.create', filter);
    // history.push('/audit');
  }

  useEffect(() => {
    if (callResponse.data.value !== null) history.push('/audit');
  }, [callResponse]);
  return (
    <div className="view-content">
      <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <StepWizard isHashEnabled>
              <PeriodStep
                hashKey="Period"
                step={1}
                fullMonth={props.fullMonth}
                filter={filter}
                updateFilter={setFilter}
              />
              <POIStep hashKey="OOH" type="ooh" step={2} filter={filter} updateFilter={setFilter} />
              <POIStep
                hashKey="PDV"
                type="pdv"
                step={3}
                filter={filter}
                updateFilter={setFilter}
                submit={submit}
              />
            </StepWizard>
          </CardBody>
        </Card>
      </CardGroup>
    </div>
  );
}
