import React from 'react';
import i18n from 'i18n-js';

import ViewHeader from '../../layouts/AppHeader';

import ViewContent from './ViewContent';

export default function Forecast(props) {
  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('ai_forecast_title')}
        subtitle={i18n.t('ai_forecast_text')}
        filterComponent={null}
      />
      <ViewContent />
    </div>
  );
}
