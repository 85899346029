import React from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';
import { useAPIDataLoader } from '../../../hooks/api';
import ViewHeader from '../../layouts/AppHeader';
import ViewContent from './ViewContent';

export default function AuditDetails(props) {
  const auditResponse = useAPIDataLoader('audit.getOne', [props.match.params.id]);

  if (auditResponse.error) {
    console.log('error', auditResponse.error);
    if (auditResponse.error.response && auditResponse.error.response.data) {
      toastr.error(`Error: ${auditResponse.error.response.data.message}`);
    }
  }

  let audit = null;

  if (auditResponse.hasValue('audit')) audit = auditResponse.data.value.audit;

  return (
    <>
      <div className="view">
        <ViewHeader title={i18n.t('audit_title')} subtitle={audit ? audit.periodLabel : ''} />
        <ViewContent auditResponse={auditResponse} />
      </div>
    </>
  );
}
