import React from 'react';
import i18n from 'i18n-js';
import { MdVisibility, MdDelete } from 'react-icons/md/';
import history from '../../../history';
import PopupConfirm from '../../components/utils/PopupConfirm';
import { useAPICaller } from '../../hooks/api';

export default function AuditTable({ audits }) {
  function AuditRow({ audit }) {
    // eslint-disable-next-line no-unused-vars
    const [callResponse, apiCall] = useAPICaller();

    const deleteAudit = () => {
      PopupConfirm({
        onConfirm: () => {
          apiCall('audit.delete', [audit._id]);
          window.location.reload();
        }
      });
    };

    const detailsAudit = () => {
      history.push(`/audit/details/${[audit._id]}`);
    };

    return (
      <tr>
        <td>{audit.periodLabel}</td>
        <td>{audit.ooh[0].label}</td>
        <td>{audit.pdv[0].label}</td>
        <td>{audit.status}</td>
        <td className="d-flex" style={{ justifyContent: 'space-around' }}>
          {audit.status === 'READY' ? (
            <>
              <MdVisibility
                size="18"
                color="#363537"
                onClick={detailsAudit}
                style={{ cursor: 'pointer' }}
              />{' '}
            </>
          ) : (
            <></>
          )}
          <MdDelete size="18" color="#363537" onClick={deleteAudit} style={{ cursor: 'pointer' }} />
        </td>
      </tr>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="mb-2" style={{ width: '24%', minWidth: '24%' }}>
              {i18n.t('audit_period')}
            </th>
            <th className="mb-2" style={{ width: '28%', minWidth: '28%' }}>
              {i18n.t('audit_ooh')}
            </th>
            <th className="mb-2" style={{ width: '28%', minWidth: '28%' }}>
              {i18n.t('audit_pdv')}
            </th>
            <th className="mb-2" style={{ width: '10%', minWidth: '10%' }}>
              {i18n.t('audit_status')}
            </th>
            <th className="mb-4" style={{ textAlign: 'center', width: '10%', minWidth: '10%' }}>
              {i18n.t('audit_actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {audits &&
            audits
              .sort((a, b) => (a.status > b.status ? 1 : -1))
              .map((audit, i) => <AuditRow key={i} audit={audit} />)}
        </tbody>
      </table>
    </>
  );
}
