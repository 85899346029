import React from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';

import ViewHeader from '../layouts/AppHeader';

import { useDashboardData, processFullDemosObj } from '../../hooks/metrics/esdata';

import ViewContent from './ViewContent';
import { getQueryVariable } from '../../../routeUtils';

const mapState = state => ({
  filter: state.filters.filter
});

export default function Performance(props) {
  const { filter } = useMappedState(mapState);

  const type = getQueryVariable('type');

  const { responsePerformance, responseFullDemos, responseFullDemosPrev } = useDashboardData(
    filter,
    {
      qPerformance: true,
      qFullDemos: true,
      qFullDemosPrev: true
    }
  );

  const fullDemosObj = processFullDemosObj(responseFullDemos);
  const fullDemosPrevObj = processFullDemosObj(responseFullDemosPrev);

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('performance_title')}
        subtitle={i18n.t(type === 'ooh' ? 'performance_text_ooh' : 'performance_text')}
      />
      <ViewContent
        filter={filter}
        responsePerformance={responsePerformance}
        fullDemosObj={fullDemosObj}
        fullDemosPrevObj={fullDemosPrevObj}
        type={type}
      />
    </div>
  );
}
