import React from 'react';

export default function Logo(props) {
  return (
    <div className="data_col logo_sign m_15 pt-5 mb-4">
      {window._env_.REACT_APP_PRODUCT === 'true' ? (
        <img
          className="mb-4"
          src={`${process.env.PUBLIC_URL}/img/${
            window._env_.REACT_APP_BRAND
          }/product-logo-login.png`}
          alt={window._env_.REACT_APP_PRODUCT_TITLE}
        />
      ) : (
        <></>
      )}
      <img
        className="mb-4"
        src={`${process.env.PUBLIC_URL}/img/${window._env_.REACT_APP_BRAND}/logo-login.png`}
        alt={window._env_.REACT_APP_TITLE}
      />
    </div>
  );
}
