import React, { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Alert, FormGroup, Col, FormFeedback, Button, Input, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from '../../components/utils/Loading';
import Logo from '../login/Logo';
export default function NewPassword(props) {
  const {
    location: { search }
  } = props;

  const hash = search.replace('?hash=', '');

  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  if (!hash) return toastr.error('Invalid hash');

  const onSubmit = ({ password }) => {
    setLoaded(false);
    axios
      .post(`/v2/auth/update-password/${hash}`, { password })
      .then(({ data }) => {
        setLoaded(true);
        toastr.success('Password updated!');
        setMessage('Password updated!');
      })
      .catch(err => {
        setLoaded(true);
        if (err.response && err.response.status === 400) {
          toastr.error('Invalid hash');
          setError('Invalid hash');
        } else {
          console.log('validate err', err);
          setError(err.reason || 'Internal error');
          toastr.error(err.reason || 'Internal error');
        }
      });
  };

  return (
    <div className="view_cont">
      <Card className="main_window">
        <div className="main_data main_signin m_15">
          <Logo />
          <div className="data_col m_10">
            <Loading loaded={loaded} />
            {message && <Alert color="info">{message}</Alert>}
            {error && <Alert color="danger">{error}</Alert>}

            <Formik
              initialValues={{
                password: '',
                passwordConfirmation: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(6, 'Password should be at least 6 characters')
                  .required('Password is required'),
                passwordConfirmation: Yup.string()
                  .min(6, 'Password should be at least 6 characters')
                  .oneOf([Yup.ref('password'), null], "Passwords don't match")
                  .required('Password confirmation is required')
              })}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <Col msm={9}>
                    <FormGroup>
                      <Input
                        placeholder="Password"
                        className="form_item text_form"
                        type="password"
                        name="password"
                        tag={Field}
                        invalid={errors.password && touched.password}
                      />
                      {errors.password && touched.password && (
                        <FormFeedback>{errors.password}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder="Confirm Password"
                        className="form_item text_form"
                        type="password"
                        name="passwordConfirmation"
                        tag={Field}
                        invalid={errors.passwordConfirmation && touched.passwordConfirmation}
                      />
                      {errors.passwordConfirmation && touched.passwordConfirmation && (
                        <FormFeedback>{errors.passwordConfirmation}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Button color="primary" type="submit" className="form_bt bt_common m_15">
                    UPDATE PASSWORD
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="main_common m_10">
          <div className="text_check text_small text_3_color">
            <p>
              Back to <Link to="/login">login</Link>.
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}
