import React, { useState } from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row, Button } from 'reactstrap';

// import InterestsChart from './InterestsChart';
import VisitsDailyTrendsChart from './VisitsDailyTrendsChart';
import VisitsDistanceTrendsChart from './VisitsDistanceTrendsChart';
import DwellTimeQuartilesCard from './DwellTimeQuartilesCard';

import { ResponseLoading } from '../../components/utils/Loading';

export default function VisitsTrendsCard({ responseVisits, poiType }) {
  const [block, setBlock] = useState('0_500');

  const metricsWeekDay = responseVisits.hasValue('metricsWeekDay')
    ? responseVisits.data.value.metricsWeekDay
    : [];

  // const include_0_500 = metricsWeekDay.filter(m => m['0_500_uniques'] > 0);
  const include_500_1000 = metricsWeekDay.filter(m => m['500_1000_uniques'] > 0).length > 0;
  const include_1000_2000 = metricsWeekDay.filter(m => m['1000_2000_uniques'] > 0).length > 0;

  return (
    <>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card mb-12">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">{i18n.t('mobility_trends')}</CardTitle>
                <div className="small mb-4 mt-2 card-subtitle">
                  {i18n.t('mobility_trends_by_zone')}
                  <br />
                  <br />
                  <Button
                    className="btn-sm"
                    color={block === '0_500' ? 'dark' : 'light-gray'}
                    onClick={() => setBlock('0_500')}
                  >
                    {i18n.t('0_500_mts')}
                  </Button>
                  {include_500_1000 && (
                    <Button
                      className="btn-sm"
                      color={block === '500_1000' ? 'dark' : 'light-gray'}
                      onClick={() => setBlock('500_1000')}
                    >
                      {i18n.t('500_1000_mts')}
                    </Button>
                  )}
                  {include_1000_2000 && (
                    <Button
                      className="btn-sm"
                      color={block === '1000_2000' ? 'dark' : 'light-gray'}
                      onClick={() => setBlock('1000_2000')}
                    >
                      {i18n.t('1000_2000_mts')}
                    </Button>
                  )}
                </div>
                <div className="d-flex">
                  <Card className="col-12 col-md-12 col-sm-12" style={{ height: '23rem' }}>
                    <div className="small mb-4 mt-2 card-subtitle">
                      {i18n.t('mobility_per_day')}
                    </div>
                    <div style={{ width: '100%', height: '280px' }}>
                      <VisitsDailyTrendsChart response={metricsWeekDay} block={block} />
                    </div>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>

      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card mb-12">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('mobility_trends')}
                  <ResponseLoading response={responseVisits} />
                </CardTitle>
                <div className="small mb-4 card-subtitle">{i18n.t('distance_distribution')}</div>
                <div style={{ width: '100%' }}>
                  <VisitsDistanceTrendsChart response={responseVisits} poiType={poiType} />
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
      {// eslint-disable-next-line no-nested-ternary
      poiType === 'ooh' ? (
        <></>
      ) : window._env_.REACT_APP_MODULE_REPORTS_DWELLTIME === 'true' ? (
        <DwellTimeQuartilesCard responseVisits={responseVisits} />
      ) : (
        <></>
      )}
    </>
  );
}
