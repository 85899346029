import React, { useState } from 'react';

import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { MdHelp } from 'react-icons/md';

const defaultStyle = { float: 'right', marginTop: '-3px', marginRight: '-3px' };

export default function PopoverHelp({ title, text, style = defaultStyle }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const id = title.replace(/ /gi, '_');

  return (
    <span style={style}>
      <Button className="btn-fab" size="xs" id={id} onClick={() => setPopoverOpen(true)}>
        <MdHelp size="16" color="#FFF" />
      </Button>
      <Popover
        placement="right"
        isOpen={popoverOpen}
        target={id}
        toggle={() => setPopoverOpen(false)}
      >
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{text}</PopoverBody>
      </Popover>
    </span>
  );
}
