import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import i18n from 'i18n-js';

import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer, Legend } from 'recharts';

import { colors } from '../../components/charts/Styles';

export default function VisitsDailyTrendsChart({ response, block }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const resp = response
    .map(r => {
      r.dayofweek2 = moment()
        .utc()
        .isoWeekday(r.dayofweek - 1)
        .weekday();

      r.by_walk = r[`${block}_with_speed`] - r[`${block}_by_car`];

      return r;
    })
    .sort((a, b) => a.dayofweek2 - b.dayofweek2);

  const totalWithSpeed = response.reduce((accum, val) => val[`${block}_with_speed`] + accum, 0);

  const renderTooltip = function(props) {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const total = payload.reduce((accum, val) => val.value + accum, 0);

      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #999', margin: 0, padding: 10 }}>
          <p>
            <b>
              {moment()
                .utc()
                .isoWeekday(label - 1)
                .format('dddd')}
            </b>
          </p>

          <p>
            <span style={{ color: colors[0] }}>Total: </span>
            {numeral(total).format('0,0')} ({numeral(total / totalWithSpeed).format('% 0.0')})
          </p>
          {payload.map((e, i) => (
            <p key={i}>
              <span style={{ color: e.color }}>{e.name}: </span>
              {numeral(e.value).format('0,0')} ({numeral(e.value / total).format('% 0.0')})
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <BarChart data={resp} margin={{ top: 0, right: 20, bottom: 30, left: 25 }}>
        <YAxis tickFormatter={value => numeral(value).format('0,0')} />
        <XAxis
          dataKey="dayofweek"
          tick={{ fontSize: 11 }}
          tickFormatter={value =>
            moment()
              .utc()
              .isoWeekday(value - 1)
              .format('dddd')
          }
        />
        <Tooltip content={props => renderTooltip(props)} />
        <Bar dataKey="by_walk" name={i18n.t('by_foot')} stackId="a" fill={colors[4]} />
        <Bar dataKey={`${block}_by_car`} name={i18n.t('by_car')} stackId="a" fill={colors[1]} />
        <Legend />
      </BarChart>
    </ResponsiveContainer>
  );
}
