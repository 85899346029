/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';

// import Select from 'react-select';
// import AsyncSelect from 'react-select/async';
// icons
import { MdEdit, MdTrendingDown, MdTrendingUp } from 'react-icons/md/';

import { ResponseLoading } from '../../components/utils/Loading';
import DemographicsCard from './DemographicsCard';
// import { POIsLoadOptions } from '../header/Filter';
import ModalFilter from '../header/ModalFilter';

export default function CompareColumn({
  response,
  responseBase,
  responseFilters,
  onSelectPOI,
  name,
  type
}) {
  const [modal, setModal] = useState(false);
  const [segment, setSegment] = useState('--');

  const responseVisits = response && response.responseVisits;
  const responseDemos = response && response.responseDemos;
  const responseVisitsBase = responseBase && responseBase.responseVisits;

  const variationBlock = (metric, variable, format, value) => {
    const percStyle = {
      fontSize: '12px',
      textAlign: 'center',
      marginBottom: '0px',
      marginTop: '0px'
    };

    const variation =
      responseVisits.hasValue(metric) && responseVisitsBase.hasValue(metric) ? (
        responseVisits.data.value[metric][variable] >
        (responseVisitsBase.data.value[metric][variable] || 0) ? (
          // eslint-disable-next-line react/jsx-indent
          <MdTrendingUp size="16" color="#00c853" />
        ) : (
          <MdTrendingDown size="16" color="#c80053" />
        )
      ) : (
        <span>{responseVisits.hasValue(metric) ? null : '--'}</span>
      );

    const val = responseVisits.hasValue(metric) ? (
      <span>{numeral(value || responseVisits.data.value[metric][variable]).format(format)}</span>
    ) : null;

    const perc =
      responseVisits.hasValue(metric) && responseVisitsBase.hasValue(metric)
        ? responseVisits.data.value[metric][variable] >
          (responseVisitsBase.data.value[metric][variable] || 0)
          ? [
              { ...percStyle, color: '#18cb5d' },
              numeral(
                (responseVisits.data.value[metric][variable] || 0) /
                  responseVisitsBase.data.value[metric][variable] -
                  1
              ).format('% 0,0.0')
            ]
          : [
              { ...percStyle, color: 'red' },
              numeral(
                (responseVisits.data.value[metric][variable] || 0) /
                  responseVisitsBase.data.value[metric][variable] -
                  1
              ).format('% 0,0.0')
            ]
        : [{ ...percStyle, color: 'white' }, '--'];

    return (
      <>
        {variation}
        {val}
        <div style={perc[0]}>{perc[1]}</div>
      </>
    );
  };

  return (
    <CardGroup className="metric-card mb-4">
      <Card style={{ backgroundColor: name === '0' ? '#e8e8e8' : 'white' }}>
        <CardBody>
          <ModalFilter
            modal={modal}
            setModal={setModal}
            name={name}
            key={name}
            onClick={node => {
              setSegment(node.name);
              onSelectPOI(node, name);
              setModal(false);
            }}
            responseFilters={responseFilters}
            type={type}
          />
          <MdEdit
            size="18"
            color="#005384"
            style={{ float: 'right', cursor: 'pointer' }}
            disabled={!responseFilters.hasValue('pdvs')}
            onClick={() => {
              setModal(true);
            }}
          />
        </CardBody>

        {responseVisits && (
          <>
            <ResponseLoading responses={[responseVisits]} />

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'segment_label_ooh' : 'segment_label')}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3
                  className="mr-2 ml-2 font-weight-normal small text-center"
                  style={{ minHeight: '3.5em' }}
                >
                  {segment}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(
                  type === 'ooh' ? 'total_unique_visitors_title_ooh' : 'total_unique_visitors_title'
                )}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'unique_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(
                  type === 'ooh' ? 'avg_unique_visitors_title_ooh' : 'avg_unique_visitors_title'
                )}{' '}
                {i18n.t(type === 'ooh' ? 'by_ooh' : 'by_poi')}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metricsCompare', 'unique_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'visits', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'avg_visits_title_ooh' : 'avg_visits_title')}{' '}
                {i18n.t(type === 'ooh' ? 'by_ooh' : 'by_poi')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metricsCompare', 'visits', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t('avg_frequency_title')}
              </CardTitle>

              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'frequency', '0,0.00')}
                </h3>
              </div>
            </CardBody>
            {type === 'ooh' ? (
              <></>
            ) : (
              <CardBody>
                <CardTitle className="text-uppercase small font-weight-bold text-center">
                  {i18n.t('avg_dwell_time_title')}
                </CardTitle>

                <div className="d-flex align-items-center">
                  <h3 className="mr-2 font-weight-normal">
                    {variationBlock('metrics', 'dwell_time_mins', '0,0.00')}
                  </h3>
                </div>
              </CardBody>
            )}
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'repeat_visitors_title_ooh' : 'repeat_visitors_title')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'repeat_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold text-center">
                {i18n.t(type === 'ooh' ? 'avg_new_visitors_title_ooh' : 'avg_new_visitors_title')}
              </CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">
                  {variationBlock('metrics', 'new_visitors', '0.[00]a')}
                </h3>
              </div>
            </CardBody>

            <DemographicsCard response={responseDemos} />
          </>
        )}
      </Card>
    </CardGroup>
  );
}
