import React from 'react';
import i18n from 'i18n-js';
import { useMappedState, useDispatch } from 'redux-react-hook';
import './radar.scss';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';
import { useDashboardData } from '../../hooks/metrics/esdata';
import { syncFilters, setFilter } from '../../redux/ducks/filters';
import { getQueryVariable } from '../../../routeUtils';



const mapState = state => ({
  filter: state.filters.filter
});

export default function Radar(props) {
  const { filter } = useMappedState(mapState);

  const poiType = getQueryVariable('type');

  const { responseRadar } = useDashboardData(filter, {
    qRadar: true
  });

  const { history } = props;
  const dispatch = useDispatch();
  const setFilterDispath = filter => {
    dispatch(setFilter(filter));
    dispatch(syncFilters());
    let url = '/dashboard';
    if (poiType !== null) url = `${url}?type=${poiType}`;
    history.push(url);
  };

  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('radar_title')}
        subtitle={i18n.t(poiType === 'ooh' ? 'radar_text_ooh' : 'radar_text')}
      />
      <ViewContent
        filter={filter}
        responseRadar={responseRadar}
        setFilter={setFilterDispath}
        poiType={poiType}
      />
    </div>
  );
}
