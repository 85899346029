import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Row, Card, CardGroup, CardBody, CardTitle } from 'reactstrap';
import ReactWordcloud from 'react-wordcloud';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { useAPIDataLoader } from '../../../hooks/api';
import { ZoneMap } from '../../../components/heatmap/ZoneMap';
import { ResponseLoading } from '../../../components/utils/Loading';
import { colors } from '../../../components/charts/Styles';
import BarChart from '../../../components/charts/BarChart';

export default function ViewContent({ responseRadar }) {
  const stores =
    responseRadar && responseRadar.hasValue('heatmap')
      ? responseRadar.data.value.heatmap.stores
      : [];

  const [storeSelected, setStoreSelected] = useState({});
  const [words, setWords] = useState([]);
  const [averageSentimentData, setAverageSentimentData] = useState([]);
  const [uniqueAutorsData, setUniqueAutorsData] = useState([]);
  const [uniquePostsData, setUniquePostsData] = useState([]);

  const responseSentimentData = useAPIDataLoader('premium.getSentimentData', storeSelected, {
    cache: true
  });
  if (responseSentimentData.error) {
    console.log('getSentimentData error', responseSentimentData.error);
  }

  const onSelectPOI = selected => {
    setStoreSelected(selected);
    if (responseSentimentData.hasValue('words')) {
      setWords(responseSentimentData.data.value.words);
      setAverageSentimentData(responseSentimentData.data.value.averageSentimentData);
      setUniqueAutorsData(responseSentimentData.data.value.uniqueAutorsData);
      setUniquePostsData(responseSentimentData.data.value.uniquePostsData);
    }
  };

  const callbacks = {
    getWordColor: word => (word.value >= 35 ? colors[2] : colors[0])
  };

  const options = {
    colors,
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'ubuntu',
    fontSizes: [15, 60],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 2,
    rotationAngles: [0, 30],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000
  };

  return (
    <div className="view-content">
      <div style={{ position: 'relative', zIndex: 9999, right: 0, top: '-3.5vw' }}>
        <ResponseLoading
          responses={[responseRadar, responseSentimentData]}
          className="loading-dot-white"
        />
      </div>
      <Row>
        <div className="col-sm-5 col-md-5 pl-0">
          <Card className="mb-4 pr-0">
            <div style={{ width: '100%', height: '950px' }}>
              <ZoneMap pois={stores} onChange={onSelectPOI} zoom={8} />
            </div>
          </Card>
        </div>
        <div className="col-sm-7 col-md-7 p-0">
          <CardGroup
            className="metric-card mb-4"
            style={{ flexFlow: 'row wrap', minHeight: '950px' }}
          >
            <Card style={{ flex: '3 0 0' }}>
              <CardBody className="pb-0">
                <CardTitle className="text-uppercase h6">{i18n.t('metrics_title')}</CardTitle>
                <div className="small mb-4 card-subtitle">
                  {i18n.t('mobility_trends_by_zone')}:{' '}
                  <strong>{storeSelected.name ? storeSelected.name : '--'}</strong>
                </div>
                {storeSelected._id ? (
                  <>
                    <div className="col-sm-12 col-md-12 pr-0">
                      <Card className="mb-4">
                        <div style={{ width: '100%', height: '250px' }}>
                          <ReactWordcloud callbacks={callbacks} options={options} words={words} />
                        </div>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-12 pr-0">
                      <Card className="mb-4">
                        <Row>
                          <div className="col-sm-6 col-md-6 pr-0">
                            <div style={{ width: '100%', height: '283px' }} className="p-3">
                              <h6>{i18n.t('premium_sentiment_posts')}</h6>
                              <BarChart
                                data={uniquePostsData}
                                dataKeyX="type"
                                dataKey="quantity"
                                stroke={colors[2]}
                                marginTop={40}
                                marginBottom={25}
                                marginLeft={20}
                                marginRight={20}
                                noLegend
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                            <div style={{ width: '100%', height: '283px' }} className="p-3">
                              <h6>{i18n.t('premium_sentiment_unique_authors')}</h6>
                              <BarChart
                                data={uniqueAutorsData}
                                dataKeyX="type"
                                dataKey="quantity"
                                stroke={colors[2]}
                                marginTop={40}
                                marginBottom={25}
                                marginLeft={20}
                                marginRight={20}
                                noLegend
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12">
                            <div style={{ width: '100%', height: '283px' }} className="p-3">
                              <h6>{i18n.t('premium_sentiment_average')}</h6>
                              <ResponsiveContainer>
                                <PieChart margin={{ top: 90 }}>
                                  <Pie
                                    dataKey="value"
                                    startAngle={180}
                                    endAngle={0}
                                    data={averageSentimentData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    label
                                  />
                                </PieChart>
                              </ResponsiveContainer>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    </div>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
    </div>
  );
}
