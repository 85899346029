import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';

import { ResponseLoading } from '../../components/utils/Loading';
import PerformanceChart from './PerformanceChart';
import VisitsPerformanceCard from './VisitsPerformanceCard';

export default function ViewContent({
  filter,
  responsePerformance,
  fullDemosObj,
  fullDemosPrevObj,
  type
}) {
  const responseBest =
    responsePerformance.data.value && responsePerformance.data.value.performance
      ? responsePerformance.data.value.performance.best
      : null;
  const responseWorst =
    responsePerformance.data.value && responsePerformance.data.value.performance
      ? responsePerformance.data.value.performance.worst
      : null;

  const avgUniqueVisitorsTitle =
    type === 'ooh' ? 'avg_unique_visitors_title_ooh' : 'avg_unique_visitors_title';
  const totalUniqueVisitorsTitle =
    type === 'ooh' ? 'total_unique_visitors_title_ooh' : 'total_unique_visitors_title';
  const avgVisitsTitle = type === 'ooh' ? 'avg_visits_title_ooh' : 'avg_visits_title';
  const totalVisitsTitle = type === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title';

  return (
    <div className="view-content">
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card mb-12">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('performance_best')}
                  <ResponseLoading response={responsePerformance} />
                </CardTitle>
                <div className="d-flex">
                  <Card className="col-12 col-md-12 col-sm-12" style={{ height: '24rem' }}>
                    <div style={{ width: '100%', height: '300px' }}>
                      <PerformanceChart response={responseBest} />
                    </div>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card mb-12">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('performance_worst')}
                  <ResponseLoading response={responsePerformance} />
                </CardTitle>
                <div className="d-flex">
                  <Card className="col-12 col-md-12 col-sm-12" style={{ height: '24rem' }}>
                    <div style={{ width: '100%', height: '300px' }}>
                      <PerformanceChart response={responseWorst} />
                    </div>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <CardGroup className="metric-card mb-12">
            <Card style={{ flex: '3 0 0' }}>
              <CardBody>
                <CardTitle className="text-uppercase h6">
                  {i18n.t('metrics_title')}
                  <ResponseLoading response={fullDemosObj} />
                </CardTitle>
                <div className="d-flex">
                  <div style={{ width: '100%' }}>
                    <Row>
                      <div className="col-sm-6 col-md-4">
                        <VisitsPerformanceCard
                          title={i18n.t(
                            filter.agg === 'g' ? totalUniqueVisitorsTitle : avgUniqueVisitorsTitle
                          )}
                          current={fullDemosObj.uniques}
                          prev={fullDemosPrevObj.uniques}
                          format="0,0"
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <VisitsPerformanceCard
                          title={i18n.t(filter.agg === 'g' ? totalVisitsTitle : avgVisitsTitle)}
                          current={fullDemosObj.visits}
                          prev={fullDemosPrevObj.visits}
                          format="0,0"
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <VisitsPerformanceCard
                          title={i18n.t('avg_frequency_title')}
                          current={fullDemosObj.freq}
                          prev={fullDemosPrevObj.freq}
                          format="0,0.00"
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-sm-6 col-md-4">
                        <VisitsPerformanceCard
                          title={i18n.t(
                            type === 'ooh' ? 'repeat_visitors_title_ooh' : 'repeat_visitors_title'
                          )}
                          current={fullDemosObj.repeatVisitors}
                          prev={fullDemosPrevObj.repeatVisitors}
                          format="0,0"
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <VisitsPerformanceCard
                          title={i18n.t(
                            type === 'ooh' ? 'new_visitors_title_ooh' : 'new_visitors_title'
                          )}
                          current={fullDemosObj.newVisitors}
                          prev={fullDemosPrevObj.newVisitors}
                          format="0,0"
                        />
                      </div>
                      {type === 'ooh' ? (
                        <></>
                      ) : (
                        <div className="col-sm-6 col-md-4">
                          <VisitsPerformanceCard
                            title={i18n.t('avg_dwell_time_title')}
                            current={fullDemosObj.dwellTimeMins}
                            prev={fullDemosPrevObj.dwellTimeMins}
                            format="00:00:00"
                            appendText=" hs"
                          />
                        </div>
                      )}
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
      </Row>
    </div>
  );
}
