import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Map, TileLayer } from 'react-leaflet';
import { GeodesicLine } from 'react-leaflet-geodesic';

import 'leaflet/dist/leaflet.css';
import POIMarker from './POIMarker';

const gradientPrev = '#6ea0c8';

const gradientPost = '#ef959e';

export default function FlowMap({ responseHeatmap, type }) {
  if (!responseHeatmap || !responseHeatmap.hasValue('heatmap')) return null;

  const heatmapDataPrev = responseHeatmap.data.value.heatmap.pointsPrev;
  const heatmapDataPost = responseHeatmap.data.value.heatmap.pointsPost;

  // console.log(responseHeatmap);
  const [flags, setFlags] = useState({ prev: true, post: true });

  const handleChangeFlag = f => {
    const newFlags = {
      ...flags
    };
    newFlags[f] = !newFlags[f];
    setFlags(newFlags);
  };

  return (
    <>
      <MyLeafLetMapComponent
        key="flowMap"
        pois={responseHeatmap.data.value.heatmap.stores}
        {...{ heatmapDataPrev, heatmapDataPost, flags }}
      />

      <div className="d-flex small mt-2 mb-2" style={{ justifyContent: 'flex-end' }}>
        <input
          name="prev"
          type="checkbox"
          checked={flags.prev}
          onChange={e => handleChangeFlag('prev')}
        />
        <div style={{ backgroundColor: gradientPrev }}>
          {i18n.t(type === 'ooh' ? 'prev_visit_ooh' : 'prev_visit_poi')}
        </div>
        &nbsp;&nbsp;
        <input
          name="post"
          type="checkbox"
          checked={flags.post}
          onChange={e => handleChangeFlag('post')}
        />
        <div style={{ backgroundColor: gradientPost }}>
          {i18n.t(type === 'ooh' ? 'post_visit_ooh' : 'post_visit_poi')}
        </div>
      </div>
    </>
  );
}

const MyLeafLetMapComponent = props => {
  const defaultProps = {
    center: {
      lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
      lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
    },
    zoom: 4
  };

  const { pois, heatmapDataPrev, heatmapDataPost, flags } = props;

  const optionsPrev = {
    weight: 3,
    opacity: 0.8,
    color: gradientPrev
    // steps: 1
  };
  const optionsPost = {
    weight: 3,
    opacity: 0.8,
    color: gradientPost
    // steps: 1
  };

  // console.log(props);

  // return null;

  return (
    <>
      <Map className="markercluster-map" center={defaultProps.center} zoom={defaultProps.zoom}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {flags.prev
          ? heatmapDataPrev.map(prev => {
              const p = pois.find(p => p._id === prev.store_id);

              if (!p) return null;

              return (
                <GeodesicLine
                  key={prev._id}
                  positions={[
                    [parseFloat(p.latitude), parseFloat(p.longitude)],
                    [prev.latitude, prev.longitude]
                  ]}
                  options={optionsPrev}
                />
              );
            })
          : null}

        {flags.post
          ? heatmapDataPost.map(post => {
              const p = pois.find(p => p._id === post.store_id);

              if (!p) return null;

              return (
                <GeodesicLine
                  key={post._id}
                  positions={[
                    [parseFloat(p.latitude), parseFloat(p.longitude)],
                    [post.latitude, post.longitude]
                  ]}
                  options={optionsPost}
                />
              );
            })
          : null}

        {pois.map(p => (
          <POIMarker key={p._id} poi={p} />
        ))}
      </Map>
    </>
  );
};
