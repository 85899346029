import MofiData from '../../api/mofidata';


const MOFIDATA_OBSERVE_METRIC_INIT = 'mofidata/OBSERVE_METRIC_INIT';
const MOFIDATA_EMIT = 'mofidata/EMIT';
const MOFIDATA_ON_DATA = 'mofidata/ON_DATA';
const MOFIDATA_ON_ERROR = 'mofidata/ON_ERROR';
const MOFIDATA_SUBSCRIBE = 'mofidata/SUBSCRIBE';
const MOFIDATA_UNSUBSCRIBE = 'mofidata/UNSUBSCRIBE';
const MOFIDATA_CLEAR = 'mofidata/CLEAR';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case MOFIDATA_CLEAR:
      return {};
    case MOFIDATA_OBSERVE_METRIC_INIT:
      return {
        ...state,
        [action.payload.hash]: {
          state: 'LOADING',
          lastUpdate: state[action.payload.hash] ? state[action.payload.hash].lastUpdate : null,
          value: state[action.payload.hash] ? state[action.payload.hash].value : null,
          error: state[action.payload.hash] ? state[action.payload.hash].error : null
        }
      };
    case MOFIDATA_EMIT:
      return {
        ...state,
        [action.payload.hash]: {
          ...(state[action.payload.hash] || {}),
          state: 'LOADING'
        }
      };
    case MOFIDATA_ON_DATA:
      return {
        ...state,
        [action.payload.hash]: {
          ...(state[action.payload.hash] || {}),
          state: 'LOADED',
          value: action.payload.data
        }
      };
    case MOFIDATA_ON_ERROR:
      return {
        ...state,
        [action.payload.hash]: {
          ...(state[action.payload.hash] || {}),
          state: 'ERROR',
          error: action.payload.error
        }
      };
    case MOFIDATA_SUBSCRIBE:
      return state;
    case MOFIDATA_UNSUBSCRIBE:
      return state;
    default:
      return state;
  }
}


export function mofiDataObserveMetricInit(hash) {
  return {
    type: MOFIDATA_OBSERVE_METRIC_INIT,
    payload: {
      hash
    }
  };
}

export function mofiDataOnData(hash, data) {
  return {
    type: MOFIDATA_ON_DATA,
    payload: {
      hash,
      data
    }
  };
}

export function mofiDataClear() {
  return {
    type: MOFIDATA_CLEAR
  };
}

export function mofiDataOnError(hash, error) {
  return {
    type: MOFIDATA_ON_ERROR,
    payload: {
      hash,
      error
    }
  };
}

export function mofiDataEmit(dataKey, args, resolution, hash) {
  return (dispatch, getState) => {
    dispatch({
      type: MOFIDATA_EMIT,
      payload: {
        hash
      }
    });

    MofiData.socket.emit('metric', dataKey, args, resolution, hash, MofiData.token);
  };
}

export function mofiSubscribe(dataKey, args, resolution, hash) {
  return (dispatch, getState) => {
    dispatch({
      type: MOFIDATA_SUBSCRIBE,
      payload: {
        hash
      }
    });

    MofiData.metricsIntervals[hash] = setInterval(() => {
      dispatch(mofiDataEmit(dataKey, args, resolution, hash));
    }, resolution);
  };
}

export function stopObserveMetrics(hash) {
  return dispatch => {
    if (MofiData.metricsIntervals[hash]) {
      dispatch({
        type: MOFIDATA_UNSUBSCRIBE,
        payload: {
          hash
        }
      });

      clearInterval(MofiData.metricsIntervals[hash]);
      delete MofiData.metricsIntervals[hash];
    }
  };
}

export function observeMetrics(hash, dataKey, args) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.filters.synchronized) return;

    dispatch(mofiDataObserveMetricInit(hash));

    const resolution = MofiData._metricsPollInterval[args.resolution] || 30000;

    // TODO: define if request just one time per filter when is not real time.
    // if(!state.mofidata[hash] || (state.mofidata[hash] && !state.mofidata[hash].value) || args.realtime) {
    dispatch(mofiDataEmit(dataKey, args, resolution, hash));
    // }

    if (args.realtime) {
      dispatch(mofiSubscribe(dataKey, args, resolution, hash));
    }
  };
}
