import React, { PureComponent } from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Treemap, ResponsiveContainer } from 'recharts';
import { ResponseLoading } from '../../../components/utils/Loading';
import { colors } from '../../../components/charts/Styles';
import AgeRangeChart from './AgeRangeChart';
import PieChart from './PieChart';
// import StatsChart from '../../dashboard/StatsChart';

class CustomizedContent extends PureComponent {
  render() {
    const { root, depth, x, y, width, height, index, colors, name, perc } = this.props;
    if (root.children === null) return null;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill:
              depth < 2
                ? colors[Math.floor((index / root.children.length) * colors.length)]
                : 'none',
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10)
          }}
        />
        {depth === 1 ? (
          <text x={x + 4} y={y + 12} fill="#fff" fontSize={12} fillOpacity={0.9}>
            {name}
          </text>
        ) : null}
        {depth === 2 ? (
          <text x={x + 4} y={y + height - 4} fill="#fff" fontSize={9} fillOpacity={0.9}>
            {`${name} - ${numeral(perc).format('0.0%')}`}
          </text>
        ) : null}
      </g>
    );
  }
}

const mergeArrayByKey = (arr, key) => {
  return Array.from(
    arr
      .map(e => ({
        name: e[key],
        [`perc_${e.group}`]: e.perc,
        [`total_${e.group}`]: e.total
      }))
      .reduce(
        (entryMap, e) => entryMap.set(e.name, { ...(entryMap.get(e.name) || {}), ...e }),
        new Map()
      )
      .values()
  );
};

export default function AgeRangeCard({ responseAgeRange, auditGroups }) {
  const auditAgeRange = responseAgeRange.hasValue('auditAgeRange')
    ? mergeArrayByKey(responseAgeRange.data.value.auditAgeRange, 'demographics_age_range')
    : null;

  const auditAgeRangeClass = responseAgeRange.hasValue('auditAgeRangeClass')
    ? responseAgeRange.data.value.auditAgeRangeClass
    : null;

  const auditGender = responseAgeRange.hasValue('auditGender')
    ? responseAgeRange.data.value.auditGender
    : null;

  const auditGenderGroup = [];
  auditGender &&
    auditGender.reduce((res, value) => {
      if (!res[value.group]) {
        res[value.group] = { group: value.group, perc: 0 };
        auditGenderGroup.push(res[value.group]);
      }
      res[value.group].perc += value.perc;
      return res;
    }, {});

  return (
    <>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('audit_age_range_title')}
                <ResponseLoading response={responseAgeRange} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">{i18n.t('audit_age_range_subtitle')}</div>
              <div style={{ width: '100%', height: '400px' }}>
                <AgeRangeChart response={auditAgeRange} groups={auditGroups} />
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('audit_age_range_title')}
                <ResponseLoading response={responseAgeRange} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">{i18n.t('audit_age_range_subtitle')}</div>
              <div style={{ width: '100%', height: '400px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <Treemap
                    width={400}
                    height={200}
                    data={auditAgeRangeClass}
                    dataKey="perc"
                    ratio={4 / 3}
                    stroke="#fff"
                    fill="#8884d8"
                    content={<CustomizedContent colors={colors} />}
                  />
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row>
        <div className="mb-4 col-sm-12 col-md-12">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('audit_age_range_title')}
                <ResponseLoading response={responseAgeRange} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">{i18n.t('audit_age_range_subtitle')}</div>
              <div style={{ width: '100%', height: '400px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart
                    width={500}
                    height={500}
                    data={auditGenderGroup}
                    dataKey="perc"
                    nameKey="group"
                    data2={auditGender}
                    dataKey2="perc"
                    nameKey2="demographics_gender"
                  />
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
}
