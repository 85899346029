import React from 'react';
import i18n from 'i18n-js';

import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

export default function Glossary() {
  return (
    <>
      <div className="view">
        <ViewHeader title={i18n.t('glossary_title')} subtitle="" />
        <ViewContent />
      </div>
    </>
  );
}
