import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { logout } from '../../redux/ducks/auth';
import Nav from '../nav/Nav';
import Header from '../header/Header';

import './app_layout.scss';

export default function CrudLayout(props) {
  const dispatch = useDispatch();

  const [navMini, setNavMini] = useState(props.navMini);

  const logoutDispath = () => dispatch(logout());

  const toggleNav = e => {
    e.preventDefault();
    setNavMini(!navMini);
  };

  const hideNav = e => {
    e.stopPropagation();
    e.preventDefault();
    setNavMini(false);
  };

  return (
    <div className="app-wrapper">
      <Nav mini={navMini} toggleNav={toggleNav} user={props.user} />
      <div className={`content-container ${navMini ? 'full' : ''}`}>
        {/* dropshadow for mobile nav triggering */}
        <div
          className="menu-dropshadow"
          style={{ display: navMini ? 'block' : 'none' }}
          onClick={hideNav}
        />
        <Header toggleNav={toggleNav} logout={logoutDispath} filter={null} user={props.user} />
        {props.children}
      </div>
    </div>
  );
}
