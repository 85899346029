import React from 'react';
import i18n from 'i18n-js';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import ScatterChart from './ScatterChart';
import { colors } from '../../../components/charts/Styles';
import LineChart from './LineChart';
import { useAPIDataLoader } from '../../../hooks/api';
import ProjectedCard from './ProjectedCard';
import { ResponseLoading } from '../../../components/utils/Loading';

export default function ViewContent() {
  const responseForecastData = useAPIDataLoader('ai.getForecastData', {}, { cache: true });
  if (responseForecastData.error) {
    console.log('getForecastData error', responseForecastData.error);
  }

  let forecastVisitsData0 = [];
  let forecastVisitsData1 = [];
  let forecastRevenueData = [];
  let projectedRevenue = null;
  let projectedVisits = null;
  let projectedVisitors = null;
  let period = '--';

  if (responseForecastData.hasValue('projectedVisits')) {
    forecastVisitsData0 = responseForecastData.data.value.forecastVisitsData0;
    forecastVisitsData1 = responseForecastData.data.value.forecastVisitsData1;
    forecastRevenueData = responseForecastData.data.value.forecastRevenueData;
    projectedRevenue = responseForecastData.data.value.projectedRevenue;
    projectedVisits = responseForecastData.data.value.projectedVisits;
    projectedVisitors = responseForecastData.data.value.projectedVisitors;
    period = responseForecastData.data.value.period;
  }

  return (
    <div className="view-content">
      <div style={{ position: 'relative', zIndex: 9999, right: 0, top: '-3.5vw' }}>
        <ResponseLoading responses={[responseForecastData]} className="loading-dot-white" />
      </div>

      <Row>
        <div className="col-sm-6 col-md-4">
          <ProjectedCard
            period={period}
            title={i18n.t('ai_forecast_projected_revenue')}
            current={projectedRevenue ? projectedRevenue.current : 0}
            projected={projectedRevenue ? projectedRevenue.projected : 0}
            format="$ 0.00a"
          />
        </div>
        <div className="col-sm-6 col-md-4">
          <ProjectedCard
            period={period}
            title={i18n.t('ai_forecast_projected_visits')}
            current={projectedVisits ? projectedVisits.current : 0}
            projected={projectedVisits ? projectedVisits.projected : 0}
            format="0,0.00a"
          />
        </div>
        <div className="col-sm-6 col-md-4">
          <ProjectedCard
            period={period}
            title={i18n.t('ai_forecast_projected_visitors')}
            current={projectedVisitors ? projectedVisitors.current : 0}
            projected={projectedVisitors ? projectedVisitors.projected : 0}
            format="0,0.00a"
          />
        </div>
      </Row>
      <Row>
        <div className="col-sm-12 col-md-12">
          <Card className="mb-4">
            <CardBody>
              <CardTitle
                className="text-uppercase medium font-weight-bold"
                style={{ minHeight: 24 }}
              >
                {i18n.t('ai_forecast_visits')}
              </CardTitle>
              <div className="small card-subtitle advisor_card_description">
                {i18n.t('ai_forecast_visits_text')}
              </div>
            </CardBody>
            <div style={{ width: '100%', height: '250px' }}>
              <ScatterChart
                data0={forecastVisitsData0}
                data1={forecastVisitsData1}
                dataKey="visits"
              />
            </div>
          </Card>
        </div>
      </Row>
      <Row>
        <div className="col-sm-12 col-md-12">
          <Card className="mb-4">
            <CardBody>
              <CardTitle
                className="text-uppercase medium font-weight-bold"
                style={{ minHeight: 24 }}
              >
                {i18n.t('ai_forecast_revenue')}
              </CardTitle>
              <div className="small card-subtitle advisor_card_description">
                {i18n.t('ai_forecast_revenue_text')}
              </div>
            </CardBody>
            <div style={{ width: '100%', height: '250px' }}>
              <LineChart
                data={forecastRevenueData}
                dataKeyX="date"
                dataKeyY1="revenue"
                strokeY1={colors[9]}
                dataKeyY2="projected"
                strokeY2={colors[2]}
                format="$ 0.[00]a"
              />
            </div>
          </Card>
        </div>
      </Row>
    </div>
  );
}
