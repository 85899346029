import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import { MdEdit } from 'react-icons/md/';
import ModalFilter from '../../header/ModalFilter';
import { StepButton } from './StepButton';
import { useAPIDataLoader } from '../../../hooks/api';
import { ResponseLoading } from '../../../components/utils/Loading';

export const POIStep = props => {
  const [modal, setModal] = useState(false);
  const [pois, setPois] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    const filter = {};
    filter.period = props.filter ? (props.filter.period ? props.filter.period : null) : null;
    filter.startDate = props.filter
      ? props.filter.startDate
        ? props.filter.startDate.valueOf()
        : null
      : null;
    filter.endDate = props.filter
      ? props.filter.endDate
        ? props.filter.endDate.valueOf()
        : null
      : null;
    filter.type = props.type;
    filter.offset = moment()
      .toDate()
      .getTimezoneOffset();
    setFilter(filter);
  }, [props.filter]);

  const responseFilters = useAPIDataLoader('pois.getDataForFilters', filter, { cache: true });
  if (responseFilters.error) {
    console.log('responseFilters error', responseFilters.error);
  }

  const onSelectPOI = (node, params) => {
    const selected = {
      label: node.name,
      value: node.group_hash,
      count: node.groupCount,
      month_range_start: node.month_range_start,
      month_range_end: node.month_range_end,
      periods: node.periods
    };
    setPois(selected ? [selected] : []);
    const filter = props.filter;
    if (props.type === 'ooh') filter.ooh = [selected];
    else if (props.type === 'pdv') filter.pdv = [selected];
    props.updateFilter(filter);
    setDisabled(false);
    setModal(false);
  };

  return (
    <div>
      <ModalFilter
        modal={modal}
        setModal={setModal}
        onClick={onSelectPOI}
        responseFilters={responseFilters}
        type={props.type}
      />
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-10 pl-0 pr-0 d-flex">
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 p-10">
          <h6 className="subtitle pt-2">
            {i18n.t(props.type === 'ooh' ? 'segment_label_ooh' : 'segment_label')}
          </h6>
          <ResponseLoading response={responseFilters} className="loading-dot-white" />
          <div className="filter-picker-value">
            {pois.length > 0 ? pois[0].label : '--'}
            <MdEdit
              size="18"
              color="#005384"
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => {
                setModal(true);
              }}
            />
          </div>
        </div>
      </div>
      <StepButton {...props} step={props.step} disabled={disabled} />
    </div>
  );
};
