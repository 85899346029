import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useMappedState } from 'redux-react-hook';
// import { hotjar } from 'react-hotjar';
import i18n from 'i18n-js';
import moment from 'moment';
// import  * as THREE from 'three';
import history from './history';
import { AppRoute, CRUDRoute, OnboardingRoute } from './routeUtils';
import Login from './modules/containers/login/Login';
import Dashboard from './modules/containers/dashboard/Dashboard';
import Compare from './modules/containers/compare/Compare';
import Heatmap from './modules/containers/heatmap/Heatmap';
import Radar from './modules/containers/radar/Radar';
import Performance from './modules/containers/performance/Performance';
import Journey from './modules/containers/journey/Journey';
import Glossary from './modules/containers/info/Glossary';
import POIs from './modules/containers/pois/POIs';
import POIEdit from './modules/containers/pois/POIEdit';
import POICreate from './modules/containers/pois/POICreate';
import Ranking from './modules/containers/ranking/Ranking';
import EditProfile from './modules/containers/user/EditProfile';
import EditPassword from './modules/containers/user/EditPassword';
import ForgotPassword from './modules/containers/user/ForgotPassword';
import NewPassword from './modules/containers/user/NewPassword';
import AudienceBuilder from './modules/containers/audiences/AudienceBuilder';
import AudienceManager from './modules/containers/audiences/AudienceManager';
import AudienceDetails from './modules/containers/audiences/AudienceDetails';
import AudienceEdit from './modules/containers/audiences/AudienceEdit';
import AIAdvisor from './modules/containers/ai/advisor/Advisor';
import AIForecast from './modules/containers/ai/forecast/Forecast';
import PremiumSentiment from './modules/containers/premium/sentiment/Sentiment';
import PremiumFinancial from './modules/containers/premium/financial/Financial';
import PremiumLoyalty from './modules/containers/premium/loyalty/Loyalty';
import PremiumNeighborhood from './modules/containers/premium/neighborhood/Neighborhood';
import Audit from './modules/containers/audit/Audit';
import NewAudit from './modules/containers/audit/create/NewAudit';
import AuditDetails from './modules/containers/audit/details/AuditDetails';
import { translations } from './modules/assets/translations';

history.listen(location => {});

// Set the key-value pairs for the different languages you want to support.
i18n.translations = translations;

i18n.locale = window._env_.REACT_APP_LANGUAGE;

if (window._env_.REACT_APP_LANGUAGE !== 'en') {
  import(`moment/locale/${window._env_.REACT_APP_LANGUAGE}`).then();
  moment.locale(window._env_.REACT_APP_LANGUAGE);
}

const mapState = state => ({ user: state.auth.user });

function App() {
  const { user } = useMappedState(mapState);

  useEffect(() => {
    const root = document.documentElement;
    if (root) {
      root.style.setProperty('--brand-primary', window._env_.REACT_APP_BRAND_COLOR_PRIMARY);
      root.style.setProperty('--brand-second', window._env_.REACT_APP_BRAND_COLOR_SECONDARY);
      root.style.setProperty('--brand-second-b', window._env_.REACT_APP_BRAND_COLOR_SECONDARY_DARK);
      root.style.setProperty('--brand-third', window._env_.REACT_APP_BRAND_COLOR_THIRD);
      root.style.setProperty('--brand-fourth', window._env_.REACT_APP_BRAND_COLOR_FOURTH);
      root.style.setProperty('--brand-fifth', window._env_.REACT_APP_BRAND_COLOR_FIFTH);
      root.style.setProperty('--brand-fifth-b', window._env_.REACT_APP_BRAND_COLOR_FIFTH_DARK);
      root.style.setProperty('--brand-sixth', window._env_.REACT_APP_BRAND_COLOR_SIXTH);
      root.style.setProperty('--brand-seventh', window._env_.REACT_APP_BRAND_COLOR_SEVENTH);
      root.style.setProperty('--brand-eighth', window._env_.REACT_APP_BRAND_COLOR_EIGHTH);
      root.style.setProperty('--brand-ninth', window._env_.REACT_APP_BRAND_COLOR_NINTH);
      root.style.setProperty('--body-bg', window._env_.REACT_APP_BRAND_COLOR_BODY);
    }
  }, []);

  const fullMonth = window._env_.REACT_APP_TYPE_PERIOD === 'month';
  const useAggregation = window._env_.REACT_APP_USE_AGGREGATION === 'true';

  return (
    <Router history={history}>
      <div>
        {/* AUTH ROUTES */}
        <OnboardingRoute exact path="/" component={Login} loginRequired={false} />
        <OnboardingRoute exact path="/login" component={Login} loginRequired={false} />
        <OnboardingRoute exact path="/forgot" component={ForgotPassword} user={user} />
        <OnboardingRoute exact path="/update-password" component={NewPassword} user={user} />

        {/* USER ROUTES */}
        <CRUDRoute exact path="/edit-profile" component={EditProfile} user={user} />
        <CRUDRoute exact path="/edit-password" component={EditPassword} user={user} />

        {/* REPORTS ROUTES */}
        {window._env_.REACT_APP_MODULE_PDV_DASHBOARD === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_DASHBOARD === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_DASHBOARD === 'true' ? (
          <AppRoute
            exact
            path="/dashboard"
            component={Dashboard}
            user={user}
            fullMonth={fullMonth}
            useAggregation={useAggregation}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_COMPARE === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_COMPARE === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_COMPARE === 'true' ? (
          <CRUDRoute
            exact
            path="/compare"
            component={Compare}
            user={user}
            navMini
            fullMonth={fullMonth}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_HEATMAP === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_HEATMAP === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_HEATMAP === 'true' ? (
          <AppRoute exact path="/heatmaps" component={Heatmap} user={user} fullMonth={fullMonth} />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_RADAR === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_RADAR === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_RADAR === 'true' ? (
          <AppRoute
            exact
            path="/radar"
            component={Radar}
            user={user}
            navMini
            fullMonth={fullMonth}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_PERFORMANCE === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_PERFORMANCE === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_PERFORMANCE === 'true' ? (
          <AppRoute
            exact
            path="/performance"
            component={Performance}
            user={user}
            navMini
            fullMonth={fullMonth}
            useAggregation={useAggregation}
          />
        ) : null}
        {window._env_.REACT_APP_MODULE_PDV_JOURNEY === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_JOURNEY === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_JOURNEY === 'true' ? (
          <AppRoute
            exact
            path="/journey"
            component={Journey}
            user={user}
            navMini
            fullMonth={fullMonth}
            filterSinglePOI
          />
        ) : null}

        {window._env_.REACT_APP_MODULE_PDV_RANKING === 'true' ||
        window._env_.REACT_APP_MODULE_OOH_RANKING === 'true' ||
        window._env_.REACT_APP_MODULE_REPORTS_RANKING === 'true' ? (
          <CRUDRoute exact path="/ranking" component={Ranking} user={user} fullMonth={fullMonth} />
        ) : null}
        {window._env_.REACT_APP_MODULE_REPORTS_AUDIT === 'true' ? (
          <>
            <CRUDRoute exact path="/audit" component={Audit} user={user} />
            <CRUDRoute exact path="/newaudit" component={NewAudit} user={user} />
            <CRUDRoute exact path="/audit/details/:id" component={AuditDetails} user={user} />
          </>
        ) : null}
        <CRUDRoute exact path="/glossary" component={Glossary} user={user} />

        {/* PLANNER ROUTES */}
        {window._env_.REACT_APP_MODULE_CATEGORY_PLANNER === 'true' ? (
          <>
            <CRUDRoute exact path="/planner-new" component={AudienceBuilder} user={user} navMini />
            <CRUDRoute exact path="/planner" component={AudienceManager} user={user} />
            <CRUDRoute exact path="/planner/details/:id" component={AudienceDetails} user={user} />
            <CRUDRoute
              exact
              path="/planner/edit/:id"
              component={AudienceEdit}
              user={user}
              navMini
            />
          </>
        ) : null}
        {/* ADMIN ROUTES */}
        {window._env_.REACT_APP_MODULE_CATEGORY_POI === 'true' ? (
          <>
            <CRUDRoute exact path="/poi/new/" component={POICreate} user={user} />
            <CRUDRoute exact path="/pois/details/:id" component={POIEdit} user={user} />
            <CRUDRoute exact path="/pois/:page?" component={POIs} user={user} />
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_AI === 'true' ? (
          <>
            {window._env_.REACT_APP_MODULE_CATEGORY_AI_ADVISOR === 'true' ? (
              <CRUDRoute exact path="/ai/advisor/" component={AIAdvisor} user={user} />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_AI_FORECAST === 'true' ? (
              <CRUDRoute exact path="/ai/forecast/" component={AIForecast} user={user} />
            ) : null}
          </>
        ) : null}
        {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM === 'true' ? (
          <>
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_SENTIMENT === 'true' ? (
              <AppRoute
                exact
                path="/premium/sentiment/"
                component={PremiumSentiment}
                user={user}
                fullMonth={true}
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_FINANCIAL === 'true' ? (
              <AppRoute
                exact
                path="/premium/financial/"
                component={PremiumFinancial}
                user={user}
                fullMonth={true}
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_LOYALTY === 'true' ? (
              <AppRoute
                exact
                path="/premium/loyalty/"
                component={PremiumLoyalty}
                user={user}
                fullMonth={true}
              />
            ) : null}
            {window._env_.REACT_APP_MODULE_CATEGORY_PREMIUM_NEIGHBORHOOD_PATTERNS === 'true' ? (
              <AppRoute
                exact
                path="/premium/neighborhood/"
                component={PremiumNeighborhood}
                user={user}
                fullMonth={true}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </Router>
  );
}

export default App;
