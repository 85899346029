import React from 'react';
import i18n from 'i18n-js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Button } from 'reactstrap';

export default function PopupConfirm({
  title = i18n.t('popup_confirm_title'),
  text = i18n.t('popup_confirm_text'),
  onConfirm
}) {
  return confirmAlert({
    customUI: ({ onClose }) => (
      <div className="custom-ui">
        <h1>{title}</h1>
        <p>{text}</p>
        <Button
          color="danger"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {i18n.t('confirm_button')}
        </Button>
        <Button onClick={onClose} color="secondary">
          {i18n.t('cancel_button')}
        </Button>
      </div>
    )
  });
}
