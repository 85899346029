import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Card, CardBody, Alert } from 'reactstrap';

import { useAPIDataLoader, useAPICaller } from '../../hooks/api';

import POIForm from './POIForm';

import { ResponseLoading } from '../../components/utils/Loading';

function ViewContent({ response, history }) {
  const [notification, setNotification] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [callResponse, apiCall] = useAPICaller();

  const poi = response.data ? response.data.value : {};

  const updatePOI = (formValues, newPoi) => {
    const poiData = {
      ...poi,
      ...formValues
    };
    poiData.latitude = newPoi.latitude;
    poiData.longitude = newPoi.longitude;

    apiCall('pois.update', poiData);
  };

  const deletePOI = () => {
    apiCall('pois.delete', [poi._id]);

    history.push('/pois');
  };

  const notificationColor = notification ? notification.type : 'info';

  return (
    <div className="view-content view-components">
      <Card className="mb-4">
        <CardBody className="table-responsive">
          <h6 className="text-uppercase" style={{ marginBottom: '40px' }}>
            Editar {response.hasValue() ? response.data.value.name : null}
            <ResponseLoading response={response} />
          </h6>

          <Alert
            color={notificationColor}
            isOpen={!!notification}
            toggle={() => setNotification(null)}
            className="mb-4"
          >
            {notification && notification.msg}
          </Alert>
          {poi && (
            <POIForm
              poi={poi}
              notification={notification}
              onSubmit={updatePOI}
              submitText="Grabar"
              onDelete={deletePOI}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default function POIEdit(props) {
  const applicationResponse = useAPIDataLoader('pois.getOne', [props.match.params.id]);

  if (applicationResponse.error) {
    console.log('error', applicationResponse.error);
  }

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('admin_menu_poi')}</h1>
          <p className="mb-0">{i18n.t('admin_poi_title')}</p>
        </header>
      </div>
      <ViewContent response={applicationResponse} history={props.history} />
    </div>
  );
}
