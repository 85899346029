import React from 'react';
import numeral from 'numeral';
import {
  LineChart as ReLineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line
} from 'recharts';

export default function LineChart({
  data,
  dataKeyX,
  dataKeyY,
  stroke,
  dataKeyY2,
  stroke2,
  format = '0,0',
  marginTop = 10,
  marginBottom = 10,
  marginRight = 30,
  marginLeft = 40
}) {
  if (!data) return null;
  return (
    <ResponsiveContainer>
      <ReLineChart
        data={data}
        margin={{ top: marginTop, bottom: marginBottom, right: marginRight, left: marginLeft }}
        padding={{ right: 10, left: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis type="number" tickFormatter={value => numeral(value).format(format)} />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          wrapperStyle={{ zIndex: 100 }}
          formatter={value => numeral(value).format(format)}
        />
        <Legend />
        <Line type="monotone" dataKey={dataKeyY} stroke={stroke} strokeWidth={2} />
        {dataKeyY2 ? (
          <Line type="monotone" dataKey={dataKeyY2} stroke={stroke2} strokeWidth={2} />
        ) : null}
      </ReLineChart>
    </ResponsiveContainer>
  );
}
