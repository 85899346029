import React from 'react';
import i18n from 'i18n-js';

import ViewHeader from '../../layouts/AppHeader';

import ViewContent from './ViewContent';
import './advisor.scss';

export default function Advisor(props) {
  return (
    <div className="view">
      <ViewHeader
        title={i18n.t('ai_advisor_title')}
        subtitle={i18n.t('ai_advisor_text')}
        filterComponent={null}
      />
      <ViewContent />
    </div>
  );
}
