import React from 'react';
import i18n from 'i18n-js';

export const StepButton = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
  disabled,
  submit
}) => (
  <div>
    <hr />
    {step > 1 && (
      <button className="btn btn-default" onClick={previousStep}>
        {i18n.t('back')}
      </button>
    )}
    {step < totalSteps ? (
      <button className="btn btn-primary" disabled={disabled} onClick={nextStep}>
        {i18n.t('continue')}
      </button>
    ) : (
      <button className="btn btn-success" disabled={disabled} onClick={submit}>
        {i18n.t('finish')}
      </button>
    )}
    <hr />
  </div>
);
