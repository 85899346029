import React, { useState } from 'react';

import { PieChart as RePieChart, Pie, Legend, Cell, Sector, ResponsiveContainer } from 'recharts';

import { colors } from './Styles';

const renderActiveShape = props => {
  // const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    // midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    name,
    percent
  } = props;
  // const sin = Math.sin(-RADIAN * midAngle);
  // const cos = Math.cos(-RADIAN * midAngle);
  // const sx = cx + (outerRadius + 10) * cos;
  // const sy = cy + (outerRadius + 10) * sin;
  // const mx = cx + (outerRadius + 15) * cos;
  // const my = cy + (outerRadius + 15) * sin;
  // const ex = mx + (cos >= 0 ? 1 : -1) * 15;
  // const ey = my;
  // const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text
        x={cx}
        y={cy - 5}
        dy={8}
        textAnchor="middle"
        fontSize={11}
        fill={fill}
        fontWeight="bold"
      >
        {name}
      </text>
      <text x={cx} y={cy + 9} dy={8} textAnchor="middle" fontSize={8} fill={fill}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/*      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey + 3}
        fontSize={9}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      */}
    </g>
  );
};

function PieChart({
  data,
  dataKey,
  onClick,
  nameKey = 'name',
  startAngle = 0,
  endAngle = 360,
  legend = true,
  marginTop = 0,
  marginBottom = -17,
  marginRight = 0,
  marginLeft = 0
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (data, index) => setActiveIndex(index);

  if (!data) return null;

  return (
    <ResponsiveContainer>
      <RePieChart
        margin={{ top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }}
      >
        <Pie
          onMouseEnter={onPieEnter}
          onClick={onClick}
          data={data}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          outerRadius={90}
          dataKey={dataKey}
          innerRadius={70}
          paddingAngle={2}
          startAngle={startAngle}
          endAngle={endAngle}
          nameKey={nameKey}
        >
          {data.map((entry, index) => (
            <Cell fill={entry.color || colors[index]} key={index} />
          ))}
        </Pie>
        {legend === true ? <Legend /> : null}
      </RePieChart>
    </ResponsiveContainer>
  );
}

export default React.memo(PieChart);
// <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={11} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
// <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={11} dy={18} textAnchor={textAnchor} fill="#999" >
//   {`(${(percent * 100).toFixed(2)}%)`}
// </text>
