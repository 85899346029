import React from 'react';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle, Row } from 'reactstrap';
import HeatMap from '../../components/heatmap/HeatMap';

import { ResponseLoading } from '../../components/utils/Loading';

export default function MapCard({ responseHeatmap, type }) {
  return (
    <Row>
      <div className="mb-2 col-sm-12 col-md-12">
        <CardGroup className="metric-card mb-4" style={{ flexFlow: 'row wrap' }}>
          <Card style={{ flex: '3 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">
                {i18n.t('flow_map')}
                <ResponseLoading response={responseHeatmap} />
              </CardTitle>
              <div className="small mb-4 card-subtitle">
                {i18n.t(type === 'ooh' ? 'flow_map_text_ooh' : 'flow_map_text')}
              </div>
              <div style={{ width: '100%', height: '600px' }}>
                <HeatMap responseHeatmap={responseHeatmap} type={type} />
              </div>
              {/* <div style={{ width: '100%', height: '600px' }}>
                <FlowMap responseHeatmap={responseHeatmap} type={type} />
              </div> */}
            </CardBody>
          </Card>
        </CardGroup>
      </div>
    </Row>
  );
}
