import React from 'react';
import numeral from 'numeral';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

export default function SynchronizedAreaChart({
  data,
  dataKeyX,
  dataKeyY,
  nameY,
  stroke,
  dataKeyY2,
  nameY2,
  stroke2,
  dataKeyX2,
  dataKeyY3,
  nameY3,
  stroke3,
  dataKeyY4,
  nameY4,
  stroke4,
  title,
  title2,
  format
}) {
  if (!data) return null;
  return (
    <div style={{ width: '100%', margin: 20 }}>
      <p>{title}</p>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          height={200}
          data={data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 70,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKeyX} />
          <YAxis tickFormatter={value => numeral(value).format(format)} />
          <Tooltip formatter={value => numeral(value).format(format)} />
          <Area
            type="monotone"
            dataKey={dataKeyY}
            stroke={stroke}
            fill={stroke}
            name={nameY}
            fillOpacity={1}
          />
          <Area
            type="monotone"
            dataKey={dataKeyY2}
            stroke={stroke2}
            fill={stroke2}
            name={nameY2}
            fillOpacity={0.9}
          />
        </AreaChart>
      </ResponsiveContainer>

      <p>{title2}</p>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          height={200}
          data={data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 70,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKeyX} />
          <YAxis tickFormatter={value => numeral(value).format(format)} />
          <Tooltip formatter={value => numeral(value).format(format)} />
          <Area
            type="monotone"
            dataKey={dataKeyY3}
            stroke={stroke3}
            fill={stroke3}
            name={nameY3}
            fillOpacity={1}
          />
          <Area
            type="monotone"
            dataKey={dataKeyY4}
            stroke={stroke4}
            fill={stroke4}
            name={nameY4}
            fillOpacity={0.9}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
