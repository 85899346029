import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import Select from 'react-select';
import { DatePicker, DateRangePicker } from 'rsuite';
import { StepButton } from './StepButton';
import { useAPIDataLoader } from '../../../hooks/api';
import { ResponseLoading } from '../../../components/utils/Loading';

export const PeriodStep = props => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [period, setPeriod] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') {
      if (period !== null) {
        const filter = {};
        filter.period = period;
        filter.periodLabel = period.label;
        props.updateFilter(filter);
        setDisabled(false);
      } else setDisabled(true);
    } else if (startDate !== null && endDate !== null) {
      const filter = {};
      filter.startDate = startDate;
      filter.endDate = endDate;
      filter.periodLabel = props.fullMonth
        ? `${moment(startDate).format('MMMM')}  ${moment(startDate).format('YYYY')}`
        : `${moment(startDate).format('MMMM DD')} - ${moment(endDate).format('MMMM DD')}`;
      props.updateFilter(filter);
      setDisabled(false);
    } else setDisabled(true);
  }, [startDate, endDate, period]);

  let responsePeriods = {};
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') {
    responsePeriods = useAPIDataLoader('periods.getPeriods', {}, { cache: true });
    if (responsePeriods.error) {
      console.log('getPeriods error', responsePeriods.error);
    }
  }

  const onSelectPeriod = period => {
    setPeriod(period);
  };

  const onApplyRange = value => {
    if (value && value.length === 0) {
      setStartDate(null);
      setEndDate(null);
    } else if (value && value.length > 0) {
      const _startDate = props.fullMonth ? moment(value).startOf('month') : moment(value[0]);
      const _endDate = props.fullMonth ? moment(value).endOf('month') : moment(value[1]);
      setStartDate(_startDate.startOf('day'));
      setEndDate(_endDate.startOf('day'));
    }
  };

  return (
    <div>
      <div className="col-12 col-sm-12 col-md-5 col-lg-4 p-10">
        {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? (
          <>
            <h6 className="subtitle pt-2">{i18n.t('period_label')}</h6>
            <ResponseLoading response={responsePeriods} className="loading-dot-white" />
            <div style={{ width: '100%', height: '60px' }}>
              <Select
                options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
                onChange={p => onSelectPeriod(p)}
                getOptionValue={option => option.oid}
              />
            </div>
          </>
        ) : (
          <>
            <h6 className="subtitle pt-2">{i18n.t('period_label')}</h6>
            {props.fullMonth ? (
              <DatePicker
                appearance="default"
                format="MMMM YYYY"
                block
                size="lg"
                renderValue={value =>
                  `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`
                }
                ranges={[]}
                onChange={onApplyRange}
                cleanable={false}
              />
            ) : (
              <DateRangePicker
                appearance="default"
                block
                size="lg"
                renderValue={value =>
                  `${moment(value[0]).format('MMMM DD')} - ${moment(value[1]).format('MMMM DD')}`
                }
                ranges={[]}
                onChange={onApplyRange}
                cleanable
              />
            )}
          </>
        )}
      </div>
      <StepButton {...props} step={props.step} disabled={disabled} />
    </div>
  );
};
