import React, { useState } from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import { DatePicker, DateRangePicker } from 'rsuite';
import './rsuite-default-custom.css';
import { Button, ButtonGroup } from 'reactstrap';
import { MdEdit } from 'react-icons/md/';
import Select from 'react-select';
import { useAPIDataLoader } from '../../hooks/api';
import { ResponseLoading } from '../../components/utils/Loading';
import PopoverHelp from '../../components/utils/PopoverHelp';
import ModalFilter from './ModalFilter';
import { getQueryVariable } from '../../../routeUtils';

export default function({
  filter,
  defaultFilter,
  ranges,
  setFilter,
  fullMonth,
  useAggregation,
  filterSinglePOI
}) {
  const [agg, setAgg] = useState(filter.agg);
  const [modal, setModal] = useState(false);
  const type = getQueryVariable('type');

  if (type !== null) {
    if (type !== filter.type)
      setFilter({
        startDate: filter.startDate,
        endDate: filter.endDate,
        period: filter.period ? filter.period : null,
        pois: [],
        groupKeys: [],
        storeKey: filter.storeKey,
        agg: filter.agg,
        offset: filter.offset,
        type
      });
  }

  const onSelectAgg = value => {
    setAgg(value);
    setTimeout(() => {
      setFilter({
        period: filter.period ? filter.period : null,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pois: filter.pois,
        groupKeys: filter.groupKeys,
        storeKey: null,
        agg: value,
        type
      });
    }, 500);
  };

  const onSelectPOI = (node, params) => {
    const selected = {
      label: node.name,
      value: node.group_hash,
      count: node.groupCount,
      month_range_start: node.month_range_start,
      month_range_end: node.month_range_end,
      periods: node.periods
    };
    const pois = selected ? [selected] : [];
    const groupKeys = pois.map(app => app.value);

    setModal(false);
    setTimeout(() => {
      setFilter({
        period: filter.period ? filter.period : null,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pois,
        groupKeys,
        storeKey: null,
        agg: filter.agg,
        type: filter.type
      });
    }, 500);
  };

  const responseFilters = useAPIDataLoader(
    'pois.getDataForFilters',
    {
      period: filter.period ? filter.period : null,
      startDate: filter.startDate.valueOf(),
      endDate: filter.endDate.valueOf(),
      offset: filter.offset,
      type: filter.type ? filter.type : type || null
    },
    { cache: true }
  );
  if (responseFilters.error) {
    console.log('responseFilters error', responseFilters.error);
  }

  const onApplyRange = value => {
    const _startDate = fullMonth ? moment(value).startOf('month') : moment(value[0]);
    const _endDate = fullMonth ? moment(value).endOf('month') : moment(value[1]);
    const startDate = _startDate.startOf('day');
    const endDate = _endDate.startOf('day');

    let pois = [];
    let groupKeys = [];

    if (type === filter.type) {
      if (filter.pois.length > 0) {
        const dateMonth = moment.utc(startDate).startOf('month');
        if (
          moment.utc(filter.pois[0].month_range_start).diff(dateMonth) <= 0 &&
          moment.utc(filter.pois[0].month_range_end).diff(dateMonth) >= 0
        ) {
          pois = filter.pois;
          groupKeys = filter.groupKeys;
        }
      }
    }

    setFilter({
      startDate,
      endDate,
      pois,
      groupKeys,
      storeKey: null,
      agg: filter.agg,
      type
    });
  };

  let responsePeriods = {};
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') {
    responsePeriods = useAPIDataLoader('periods.getPeriods', {}, { cache: true });
    if (responsePeriods.error) {
      console.log('getPeriods error', responsePeriods.error);
    }
  }

  const onSelectPeriod = period => {
    let pois = [];
    let groupKeys = [];
    if (type === filter.type) {
      if (filter.pois.length > 0) {
        if (filter.pois[0].periods) {
          if (filter.pois[0].periods.includes(period.oid)) {
            pois = filter.pois;
            groupKeys = filter.groupKeys;
          }
        }
      }
    }

    setFilter({
      period,
      pois,
      groupKeys,
      storeKey: null,
      agg: filter.agg,
      type
    });
  };

  return (
    <>
      <ModalFilter
        modal={modal}
        setModal={setModal}
        onClick={onSelectPOI}
        responseFilters={responseFilters}
        type={type}
        filterSinglePOI={filterSinglePOI}
      />

      <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-10 mb-4 pl-0 pr-0 d-flex flex-md-row flex-sm-column">
        <form
          className="col-12 col-sm-12 col-md-5 col-lg-4 p-10"
          onSubmit={e => e.preventDefault()}
        >
          {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? (
            <>
              <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
              <ResponseLoading response={responsePeriods} className="loading-dot-white" />
              <div style={{ width: '100%', height: '60px' }}>
                <Select
                  value={filter.period}
                  options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
                  onChange={p => onSelectPeriod(p)}
                  getOptionValue={option => option.oid}
                />
              </div>
            </>
          ) : (
            <>
              <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
              {fullMonth ? (
                <DatePicker
                  appearance="default"
                  format="MMMM YYYY"
                  block
                  size="lg"
                  value={filter ? filter.startDate.toDate() : new Date()}
                  renderValue={value =>
                    `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`
                  }
                  ranges={[]}
                  onChange={onApplyRange}
                  cleanable={false}
                />
              ) : (
                <DateRangePicker
                  appearance="default"
                  hoverRange="week"
                  isoWeek
                  block
                  size="lg"
                  showOneCalendar
                  value={
                    filter
                      ? [
                          filter.startDate.startOf('week').toDate(),
                          filter.endDate.endOf('week').toDate()
                        ]
                      : [new Date(), new Date()]
                  }
                  renderValue={value =>
                    `${moment(value[0]).format('MMMM DD')} - ${moment(value[1]).format('MMMM DD')}`
                  }
                  ranges={[]}
                  onChange={onApplyRange}
                  cleanable={false}
                />
              )}
            </>
          )}
        </form>
        <div className="col-12 col-lg-1 col-md-1 col-sm-12 p-10 pt-3" />
        {useAggregation ? (
          <div className="col-12 col-sm-12 col-md-2 col-lg-2 p-10 pr-0">
            <h6 className="subtitle text-white pt-2">
              {i18n.t('aggregation_label')}

              <PopoverHelp
                title={i18n.t('aggregation_label')}
                text={i18n.t('aggregation_text')}
                style={{ paddingLeft: '10px' }}
              />
            </h6>
            <ButtonGroup>
              <Button
                className="btn-md"
                color={agg === 'g' ? 'light' : 'outline-light'}
                onClick={() => onSelectAgg('g')}
              >
                {i18n.t('aggregation_group')}
              </Button>
              <Button
                className="btn-md"
                color={agg === 's' ? 'light' : 'outline-light'}
                onClick={() => onSelectAgg('s')}
              >
                {i18n.t('aggregation_single')}
              </Button>
            </ButtonGroup>
          </div>
        ) : null}
      </div>

      <div className="col-12 col-sm-12 col-md-9 col-lg-7 p-10">
        <div>
          <h6 className="subtitle text-white pt-2">
            {i18n.t(type === 'ooh' ? 'segment_label_ooh' : 'segment_label')}
          </h6>
          <ResponseLoading response={responseFilters} className="loading-dot-white" />
          <div
            className="filter-picker-value"
            onClick={() => {
              setModal(true);
            }}
          >
            {filter.pois && filter.pois.length > 0 ? filter.pois[0].label : '--'}
            <MdEdit
              size="18"
              color="#005384"
              style={{ float: 'right', cursor: 'pointer' }}
              disabled={!responseFilters.hasValue('pdvs')}
            />
          </div>
        </div>
      </div>
    </>
  );
}
