import React from 'react';
import i18n from 'i18n-js';
import { CardBody, CardTitle } from 'reactstrap';

import StatsChart from '../dashboard/StatsChart';
import AgeRangeChart from '../dashboard/AgeRangeChart';

import { ResponseLoading } from '../../components/utils/Loading';

export default function DemographicsCard({ response }) {
  // if (!response || !response.data || !response.data.value) return null;

  // const responsePlatform = useStats('platform', filter);
  // console.log('response', response);

  const genders = response.hasValue('metricsByGender')
    ? response.data.value.metricsByGender.map(e => ({
        name: e.demographics_gender,
        value: e.total
      }))
    : null;

  const ages = response.hasValue('metricsByAge')
    ? response.data.value.metricsByAge.map(e => ({
        name: e.demographics_age_range,
        value: e.total
      }))
    : null;

  const classes = response.hasValue('metricsByClass')
    ? response.data.value.metricsByClass.map(e => ({
        name: e.demographics_class,
        value: e.total
      }))
    : null;

  return (
    <>
      <CardBody>
        <CardTitle className="text-uppercase h6">
          {i18n.t('gender')}
          <ResponseLoading response={response} />
        </CardTitle>
        <div className="pieChart">
          <StatsChart response={genders} />
        </div>
      </CardBody>
      <CardBody>
        <CardTitle className="text-uppercase h6">
          {i18n.t('age_range')}
          <ResponseLoading response={response} />
        </CardTitle>
        <div className="pieChart">
          <AgeRangeChart response={ages} />
        </div>
      </CardBody>
      <CardBody>
        <CardTitle className="text-uppercase h6">
          {i18n.t('socioeconomic_level')}
          <ResponseLoading response={response} />
        </CardTitle>
        <div className="pieChart">
          <StatsChart response={classes} />
        </div>
      </CardBody>
    </>
  );
}
