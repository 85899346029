import React, { useState } from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';

import { DatePicker } from 'rsuite';
import '../header/rsuite-default-custom.css';

import moment from 'moment';

import Select from 'react-select';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useAPIDataLoader } from '../../hooks/api';
import { useDashboardData } from '../../hooks/metrics/esdata';
import { ResponseLoading } from '../../components/utils/Loading';

const mapState = state => ({
  filters: state.filters
});

export default function Ranking() {
  const { filters } = useMappedState(mapState);

  const [rankingFilter, setRankingFilter] = useState(filters.filter);

  let responsePeriods = {};
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') {
    responsePeriods = useAPIDataLoader('periods.getPeriods', {}, { cache: true });
    if (responsePeriods.error) {
      console.log('getPeriods error', responsePeriods.error);
    }
  }

  const onSelectPeriod = period => {
    setRankingFilter({
      ...rankingFilter,
      period
    });
  };

  const onApplyRange = value => {
    const _startDate = moment(value);
    const _endDate = moment(value);

    const startDate = _startDate.startOf('day').valueOf();
    const endDate = _endDate.startOf('day').valueOf();

    setRankingFilter({
      ...rankingFilter,
      startDate,
      endDate
    });
  };

  const dateFilterComponent = (
    <form
      className="col-12 col-sm-12 col-md-6 col-lg-6 p-10 site-search"
      onSubmit={e => e.preventDefault()}
    >
      {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? (
        <>
          <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
          <ResponseLoading response={responsePeriods} className="loading-dot-white" />
          <div style={{ width: '100%', height: '60px' }}>
            <Select
              value={rankingFilter.period}
              options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
              onChange={p => onSelectPeriod(p)}
              getOptionValue={option => option.oid}
            />
          </div>
        </>
      ) : (
        <DatePicker
          appearance="default"
          format="MMMM YYYY"
          block
          size="lg"
          value={rankingFilter ? moment(rankingFilter.startDate).toDate() : new Date()}
          renderValue={value => `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`}
          ranges={[]}
          onChange={onApplyRange}
          cleanable={false}
        />
      )}
    </form>
  );

  const { responseRanking } = useDashboardData(rankingFilter, {
    qRanking: true
  });

  return (
    <>
      <div className="view">
        <ViewHeader
          title={i18n.t('ranking_title')}
          subtitle={i18n.t('ranking_subtitle')}
          filterComponent={dateFilterComponent}
        />
        <ViewContent filter={rankingFilter} responseRanking={responseRanking} />
      </div>
    </>
  );
}
