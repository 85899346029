import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { Treebeard } from 'react-treebeard';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md/';
import * as filters from './tree_filter';
import treeStyle from './treeStyle';

let searchTask;

export default function ModalFilter({
  responseFilters,
  modal,
  setModal,
  onClick,
  type,
  filterSinglePOI = false
}) {
  if (!responseFilters.hasValue('pdvs')) return null;

  const _data = JSON.parse(JSON.stringify(responseFilters.data.value.pdvs));
  const [data, setData] = useState({});
  const [cursor, setCursor] = useState(false);

  useEffect(() => {
    // if (_data.ts !== data.ts) {
    //   setData({ ..._data });
    // }
    // if (_data.type !== data.type) {
    //   setData({ ..._data });
    // }
    if (_data.ts !== data.ts || _data.type !== data.type) {
      setData(_data);
    }
  }, [data, _data]);

  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
      setCursor(node.children);
      // cursor.active = false;
    }
    setCursor(node);
    setData(Object.assign({}, data));
  };

  const onSelect = node => {
    const a = node.active;
    if (cursor) {
      cursor.active = false;
      setCursor(cursor);
      setData(Object.assign({}, data));
    }
    if (node.children && (!isSelectable(node) || a)) {
      node.toggled = !node.toggled;
    }
    node.active = true;
    setCursor(node);
    setData(Object.assign({}, data));
  };

  const onFilterMouseUp = ({ target: { value } }) => {
    const filter = value.trim();
    if (searchTask) clearTimeout(searchTask);
    return new Promise(resolve => {
      searchTask = setTimeout(() => {
        if (!filter) {
          return setData(_data);
        }
        let filtered = filters.filterTree(_data, filter);
        filtered = filters.expandFilteredNodes(filtered, filter);
        return setData(filtered);
      }, 500);
    });
  };

  const decorators = {
    Header: ({ onSelect, node, style, customStyles }) => (
      <div style={style.base} onClick={() => onSelect(node)}>
        <div style={node.selected ? { ...style.title, ...customStyles.header.title } : style.title}>
          {node.name}
        </div>
      </div>
    ),
    Container: ({ style, decorators, terminal, node, customStyles, animations, onClick }) => {
      const renderToggle = node.toggled ? (
        <MdKeyboardArrowDown
          size="18"
          color="#000"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <MdKeyboardArrowRight
          size="18"
          color="#000"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      );
      return (
        <div style={node.active && isSelectable(node) ? { ...style.container } : { ...style.link }}>
          {!terminal ? renderToggle : null}
          <decorators.Header
            node={node}
            style={style.header}
            customStyles={customStyles}
            onSelect={onSelect}
          />
        </div>
      );
    }
  };

  const isSelectable = node => {
    return node.group_hash && (!filterSinglePOI || (node.groupCount === 1 && !node.children));
  };

  return (
    <Modal size="lg" isOpen={modal} toggle={() => setModal(false)} className="modalFadeInScale">
      <ModalHeader toggle={() => setModal(false)}>
        {i18n.t(type === 'ooh' ? 'admin_oohs_list' : 'admin_pois_list')}
      </ModalHeader>

      <ModalBody>
        {i18n.t('search_for')}
        <Input type="text" name="groupName" onKeyUp={onFilterMouseUp} />
        <Treebeard style={treeStyle} data={data} onToggle={onToggle} decorators={decorators} />
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={!cursor.active || !isSelectable(cursor)}
          color="primary"
          onClick={() => onClick(cursor)}
        >
          {i18n.t('apply_filters_button')}
        </Button>
        <Button color="secondary" onClick={() => setModal(false)}>
          {i18n.t('cancel_button')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
