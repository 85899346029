import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

/* import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  LabelList,
  Tooltip
} from 'recharts'; */

import { BarChart, XAxis, YAxis, Bar, Tooltip, ResponsiveContainer, Legend } from 'recharts';

import { colors } from '../../../components/charts/Styles';

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    const t = payload.reduce((accum, val) => val.value + accum, 0);
    return (
      <div>
        <p>
          <b>{i18n.t(label)}</b>
        </p>
        <hr />
        {payload.map((pl, i) => (
          <p key={i} style={{ color: payload[i].color }}>
            {` ${payload[i].name}`} :{' '}
            {` ${numeral(payload[i].value).format('0,0')} (${numeral(payload[i].value / t).format(
              '0.0%'
            )})`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default function AgeRangeChart({ response, groups }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  return (
    <ResponsiveContainer>
      <BarChart
        layout="horizontal"
        data={response}
        margin={{ top: 0, right: 40, bottom: 0, left: 20 }}
      >
        <YAxis type="number" tickFormatter={value => numeral(value).format('0,0')} />

        <XAxis tickFormatter={value => i18n.t(value)} dataKey="name" type="category" />

        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ borderColor: '#000', backgroundColor: '#fff', padding: 10 }}
        />

        <Legend />
        {groups.map((g, i) => (
          <Bar key={g} name={g} dataKey={`total_${g}`} stackId="a" barSize={20} fill={colors[i]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
