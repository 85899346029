import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';

import { Card, CardBody } from 'reactstrap';

import { MdTrendingDown, MdTrendingUp } from 'react-icons/md/';

export default function ProjectedCard({ period, title, current, projected, format, appendText }) {
  const percStyle = {
    textAlign: 'center',
    marginBottom: '0px',
    marginTop: '0px'
  };

  const variationBlock = (current, projected) => {
    let variation = <MdTrendingDown size="16" color="#c80053" />;
    if (projected !== 0) {
      variation =
        current < projected ? (
          <MdTrendingUp size="16" color="#00c853" />
        ) : (
          <MdTrendingDown size="16" color="#c80053" />
        );
    }

    let perc = [{ ...percStyle, color: 'red' }, numeral(0).format('% 0,0.0')];
    if (projected !== 0) {
      perc =
        current < projected
          ? [{ ...percStyle, color: '#18cb5d' }, numeral(projected / current - 1).format('% 0,0.0')]
          : [
              { ...percStyle, color: 'red' },
              numeral(Math.abs(projected / current - 1)).format('% 0,0.0')
            ];
    }

    return (
      <div style={perc[0]}>
        {variation}
        {perc[1]}
      </div>
    );
  };

  return (
    <Card className="mb-4">
      <CardBody>
        <p className="small card-subtitle text-center">{period}</p>
        <h6 className="text-uppercase title font-weight-bold text-center">{title}</h6>
        <br />
        <h6 className="font-weight-normal mb-0 text-right w-75 pr-5">
          {i18n.t('ai_forecast_current')}: {numeral(current).format(format)}
          {appendText}
        </h6>
        <br />
        <h6 className="font-weight-normal mb-0 text-right w-75 pr-5">
          {i18n.t('ai_forecast_projected')}: {numeral(projected).format(format)}
          {appendText}
        </h6>
        <br />
        <h6 className="font-weight-normal mb-0 text-center">
          {variationBlock(current, projected)}
        </h6>
      </CardBody>
    </Card>
  );
}
