import React from 'react';

export function ResponseLoading({ response, responses, className = 'loading-dot' }) {
  const responsesList = responses || [response];

  const loaded = responsesList.reduce(
    (acum, response) =>
      acum &&
      !!response &&
      !!response.data &&
      response.data.state === 'LOADED' &&
      response.data.loaded !== false,
    true
  );

  return <Loading loaded={loaded} className={className} />;
}

export function Loading({ loaded, className }) {
  if (loaded) {
    return null;
  }
  return (
    <div className="loading_metric">
      <div className={className} />
    </div>
  );
}
