import React from 'react';

import { useMappedState } from 'redux-react-hook';

import './dashboard.scss';

import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useDashboardData, processFullDemosObj } from '../../hooks/metrics/esdata';
import { ExportExcelPanel } from '../../components/utils/ExportExcel';
import { getQueryVariable } from '../../../routeUtils';

const mapState = state => ({
  filter: state.filters.filter
});

export default function Dashboard(props) {
  const { filter } = useMappedState(mapState);

  const poiType = getQueryVariable('type');

  const {
    responseVisits,
    responseDemos,
    responseTech,
    responseAppBundles,
    responseHeatmap,
    responseFullDemos
  } = useDashboardData(filter, {
    qVisits: true,
    qDemos: true,
    qTech: true,
    qAppBundles: true,
    qHeatmap: true,
    qFullDemos: true
  });

  const filterComponent = <ExportExcelPanel {...{ responseFullDemos, responseVisits, filter }} />;

  const fullDemosObj = processFullDemosObj(responseFullDemos, responseVisits);

  return (
    <div className="view">
      <ViewHeader {...{ filterComponent }} />
      <ViewContent
        {...{
          filter,
          responseVisits,
          responseDemos,
          responseTech,
          responseAppBundles,
          responseHeatmap,
          fullDemosObj,
          type: props.user.type,
          poiType
        }}
      />
    </div>
  );
}
