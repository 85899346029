import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import {
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import './bootstrap-dropdown.css';
import toastr from 'toastr';
import { FaMapMarker } from 'react-icons/fa/';
import LocationPicker from 'react-location-picker';
import Geosuggest from 'react-geosuggest';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import AudienceInsights from './AudienceInsights';
import { ResponseLoading } from '../../components/utils/Loading';
import { useAPIDataLoader, useSyncAPIDataLoader, defaultResponse } from '../../hooks/api';
import API from '../../api';

export default function ViewContentBuilder({ filter, workingFilter, type }) {
  const [, setCurrentFilter] = useState({ ...workingFilter });
  const [audienceFilter, setAudienceFilter] = useState({ ...workingFilter });
  const [tempPois, setTempPois] = useState(workingFilter.pois || []);

  const [filtersEnabled, setFiltersEnabled] = useState(false);

  const [rDistanceSelected, setRDistanceSelected] = useState(200);

  const [modal, setModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [savingAudience, setSavingAudience] = useState(false);

  const responseFilters = useAPIDataLoader('audiences.getDataForFilters', {}, { cache: true });
  if (responseFilters.error) {
    console.log('responseFilters error', responseFilters.error);
  }

  let responsePeriods = {};
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') {
    responsePeriods = useAPIDataLoader('periods.getPeriods', {}, { cache: true });
    if (responsePeriods.error) {
      console.log('getPeriods error', responsePeriods.error);
    }
  }

  const [responseDetails, setResponseDetails] = useState(defaultResponse);

  if (savingAudience) {
    console.log('savingAudience....');
    // TODO loading
  }

  let searchTask;

  const POIsLoadOptions = inputValue => {
    if (searchTask) clearTimeout(searchTask);

    return new Promise(resolve => {
      searchTask = setTimeout(() => {
        API.call(
          'audiences.searchAudiencePOIs',
          { country: workingFilter.country, inputValue },
          (err, res) => {
            if (err) {
              console.log('err', err);
              resolve([]);
            } else {
              resolve(res);
            }
          }
        );
      }, 1000);
    });
  };

  let requiredFields = {};

  const onSelectPOIs = (selected, params) => {
    workingFilter.pois = selected;
    setTempPois(workingFilter.pois);
    setFiltersEnabled(true);
  };

  const onSelectParam = (field, selected, params) => {
    workingFilter[field] = selected;
    setCurrentFilter({ ...workingFilter });
    setFiltersEnabled(true);
  };

  useEffect(() => {
    if (Object.keys(workingFilter).length > 0) {
      if (!(Object.keys(workingFilter).length === 1 && workingFilter.period)) applyFilters();
    }
  }, [workingFilter]);
  const applyFilters = () => {
    //validate all required fields has values
    const r = Object.keys(requiredFields);

    for (let i = 0; i < r.length; i++) {
      const f = r[i];

      if (
        requiredFields[f].required &&
        (!workingFilter[f] || workingFilter[f] === '' || workingFilter[f] === null)
      ) {
        window.scrollTo(0, 0);
        toastr.error(`${i18n.t('error_filtering_audience')}: ${requiredFields[f].title}`);
        return;
      }
    }

    workingFilter.offset = filter.offset;
    workingFilter.poiDistance = rDistanceSelected;

    setAudienceFilter({ ...workingFilter });
    setFiltersEnabled(false);

    useSyncAPIDataLoader(
      'audiences.applyFilter',
      { filter: workingFilter },
      { cache: true },
      setResponseDetails
    );

    window.scrollTo(0, 0);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: '90px',
      maxHeight: '90px',
      overflow: 'scroll'
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: '10'
    })
  };

  const saveAudience = formValues => {
    const { audienceName } = formValues;

    setSavingAudience(true);
    API.call(
      'audiences.build',
      { name: audienceName, filter: { ...audienceFilter }, data: responseDetails.data },
      (err, res) => {
        setSavingAudience(false);
        if (err) {
          console.log('err', err);
          toastr.error(`${i18n.t('error_saving_audience')}: ${err}`);
        } else {
          setModal(false);
          toastr.success(`${i18n.t('ok_saving_audience')}`);
        }
      }
    );
  };

  // Custom POI
  const [defaultPosition, setDefaultPosition] = useState({
    lat: window._env_.REACT_APP_DEFAULT_LATITUDE,
    lng: window._env_.REACT_APP_DEFAULT_LONGITUDE
  });

  let _geoSuggest;
  let selectedPosition = defaultPosition;

  const handleLocationChange = ({ position, address, places }) => {
    selectedPosition = { ...position };

    _geoSuggest.clear();
  };

  const onSuggestSelect = (suggest, setFieldValue) => {
    setFieldValue('poiName', suggest.label);

    setDefaultPosition({
      lat: suggest.location.lat,
      lng: suggest.location.lng
    });
  };

  const saveAudiencePOI = formValues => {
    const { poiName } = formValues;

    setSavingAudience(true);
    API.call(
      'audiences.saveAudiencePOI',
      {
        name: poiName,
        position: { ...selectedPosition }
      },
      (err, res) => {
        setSavingAudience(false);
        if (err) {
          console.log('err', err);
          toastr.error(`${i18n.t('error_saving_poi')}: ${err}`);
        } else {
          setLocationModal(false);
          const _tempPois = tempPois || [];
          _tempPois.push({ value: res._id, label: res.name });
          workingFilter.pois = _tempPois;
          setTempPois(_tempPois);
          setFiltersEnabled(true);

          toastr.success(`${i18n.t('ok_saving_poi')}`);
        }
      }
    );
  };

  const filterFieldSingle = (title, field, list, required = false) => {
    requiredFields[field] = { required: required, title: title };
    return (
      <div key={field}>
        <CardTitle
          className="text-uppercase small font-weight-bold"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
        >
          {title}{' '}
          {required && <span className="small card-subtitle">* {i18n.t('error_required')}</span>}
          <ResponseLoading response={responseFilters} />
        </CardTitle>
        <div style={{ width: '100%', height: '60px' }}>
          <Select
            value={workingFilter[field]}
            options={responseFilters.hasValue() ? responseFilters.data.value[list] : []}
            onChange={v => onSelectParam(field, v)}
            isClearable
          />
        </div>
      </div>
    );
  };

  const filterFieldMulti = (title, field, list, required = false) => {
    requiredFields[field] = required;
    return (
      <div key={field}>
        <CardTitle className="text-uppercase small font-weight-bold">
          {title}
          <ResponseLoading response={responseFilters} />
        </CardTitle>
        <div style={{ width: '100%', height: '110px' }}>
          <Select
            styles={customStyles}
            isMulti
            isClearable={false}
            value={workingFilter[field]}
            options={responseFilters.hasValue() ? responseFilters.data.value[list] : []}
            onChange={v => onSelectParam([field], v)}
            closeMenuOnSelect={!workingFilter[field] || workingFilter[list].length < 1}
            onSelectResetsInput={false}
            onBlurResetsInput={false}
          />
        </div>
      </div>
    );
  };

  if (workingFilter.period === undefined) workingFilter.period = filter.startDate;

  const filterFieldPeriod = title => (
    <>
      {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? (
        <>
          <CardTitle className="text-uppercase small font-weight-bold">
            {title}
            <ResponseLoading response={responsePeriods} />
          </CardTitle>
          <div style={{ width: '100%', height: '60px' }}>
            <Select
              value={workingFilter.period}
              options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
              onChange={p => {
                onSelectParam(['period'], p);
              }}
              getOptionValue={option => option.oid}
            />
          </div>
        </>
      ) : (
        <>
          <CardTitle className="text-uppercase small font-weight-bold">{title}</CardTitle>
          <div style={{ width: '100%', height: '60px' }}>
            <DatePicker
              appearance="default"
              format="MMMM YYYY"
              block
              size="lg"
              value={workingFilter.period ? moment(workingFilter.period).toDate() : new Date()}
              renderValue={value =>
                `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`
              }
              ranges={[]}
              onChange={value => {
                onSelectParam(
                  ['period'],
                  moment(value)
                    .toDate()
                    .getTime()
                );
              }}
              cleanable={false}
            />
          </div>
        </>
      )}
    </>
  );

  const configFilters = responseFilters.hasValue('filters')
    ? responseFilters.data.value.filters
    : [];

  return (
    <div className="view-content view-dashboard">
      <Modal isOpen={modal} toggle={() => setModal(false)} className="modalFadeInScale">
        <ModalHeader toggle={() => setModal(false)}>{i18n.t('save_audience_title')}</ModalHeader>
        <Formik
          initialValues={{ audienceName: '' }}
          validationSchema={Yup.object().shape({
            audienceName: Yup.string()
              .min(4, i18n.t('error_too_short'))
              .max(100, i18n.t('error_too_long'))
              .required(i18n.t('error_required'))
          })}
          onSubmit={saveAudience}
        >
          {({ errors, touched }) => (
            <Form>
              <ModalBody>
                {i18n.t('save_audience_text')}

                <br />
                <br />
                <FormGroup row>
                  <Label for="audienceName" sm={6}>
                    {i18n.t('audience_name')}
                  </Label>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="audienceName"
                      tag={Field}
                      invalid={errors.audienceName && touched.audienceName}
                    />
                    <FormFeedback tooltip>{errors.audienceName}</FormFeedback>
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">
                  {i18n.t('save_button')}
                </Button>{' '}
                <Button color="secondary" onClick={() => setModal(false)}>
                  {i18n.t('cancel_button')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        isOpen={locationModal}
        toggle={() => setLocationModal(false)}
        className="modalFadeInScale"
      >
        <ModalHeader toggle={() => setLocationModal(false)}>{i18n.t('location_title')}</ModalHeader>
        <Formik
          initialValues={{ poiName: '' }}
          validationSchema={Yup.object().shape({
            poiName: Yup.string()
              .min(4, i18n.t('error_too_short'))
              .max(100, i18n.t('error_too_long'))
              .required(i18n.t('error_required'))
          })}
          onSubmit={saveAudiencePOI}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <ModalBody>
                <br />
                <Geosuggest
                  ref={el => {
                    _geoSuggest = el;
                  }}
                  placeholder="search places"
                  onSuggestSelect={suggest => onSuggestSelect(suggest, setFieldValue)}
                  onFocus={() => _geoSuggest.clear()}
                  style={{ input: { width: '100%' } }}
                  country={workingFilter.country ? workingFilter.country.value : null}
                />
                <div className="d-flex mb-4 align-items-center">
                  <LocationPicker
                    containerElement={<div style={{ height: '100%', width: '100%' }} />}
                    mapElement={<div style={{ height: '400px' }} />}
                    defaultPosition={defaultPosition}
                    onChange={handleLocationChange}
                    zoom={5}
                  />
                </div>
                <FormGroup row>
                  <Label for="poiName" sm={6}>
                    {i18n.t('poi_name')}
                  </Label>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="poiName"
                      tag={Field}
                      invalid={errors.poiName && touched.poiName}
                    />
                    <FormFeedback tooltip>{errors.poiName}</FormFeedback>
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit">
                  {i18n.t('save_button')}
                </Button>{' '}
                <Button color="secondary" onClick={() => setLocationModal(false)}>
                  {i18n.t('cancel_button')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Row>
        <div className="col-3 col-sm-3 col-md-3 mb-8 p-0">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">{i18n.t('filters_title')}</CardTitle>
              <Row>
                <Col className="mb-4" sm={12} md={12}>
                  <Card>
                    <CardBody style={{ minHeight: '83vh' }}>
                      {filterFieldPeriod(i18n.t('period'))}
                      {filterFieldSingle(i18n.t('admin_pois_type'), 'group', 'types', true)}
                      {filterFieldSingle(i18n.t('admin_pois_city'), 'city', 'cities')}
                      {configFilters.map(f => {
                        if (f.type === 'single') {
                          return filterFieldSingle(f.label, f.field, f.options, f.required);
                        } else if (f.type === 'multi') {
                          return filterFieldMulti(f.label, f.field, f.options, f.required);
                        }
                        return null;
                      })}

                      <CardTitle className="text-uppercase small font-weight-bold d-flex align-items-center">
                        {i18n.t('pois_title')}
                        <Button
                          onClick={() => setLocationModal(true)}
                          color="light"
                          size="sm"
                          className="ml-auto"
                        >
                          <FaMapMarker className="align-middle mr-2" />
                        </Button>
                        <ResponseLoading response={responseFilters} />
                      </CardTitle>
                      <div style={{ width: '100%', marginBottom: '25px', height: 'auto' }}>
                        <AsyncSelect
                          styles={customStyles}
                          isMulti
                          isClearable={false}
                          // value={workingFilter.pois}
                          value={tempPois}
                          loadOptions={POIsLoadOptions}
                          onChange={onSelectPOIs}
                          cacheOptions
                          defaultOptions={[]}
                          // defaultOptions
                          closeMenuOnSelect
                          onSelectResetsInput={false}
                          onBlurResetsInput={false}
                        />

                        {tempPois && tempPois.length > 0 ? (
                          <>
                            <div style={{ width: '100%', marginTop: '5px' }}>
                              <span className="small">{i18n.t('pois_distance')}: </span>
                              <br />
                              <ButtonGroup>
                                <Button
                                  outline
                                  color="primary"
                                  onClick={() => {
                                    setRDistanceSelected(100);
                                    setFiltersEnabled(true);
                                  }}
                                  active={rDistanceSelected === 100}
                                >
                                  100m
                                </Button>
                                <Button
                                  outline
                                  color="primary"
                                  onClick={() => {
                                    setRDistanceSelected(500);
                                    setFiltersEnabled(true);
                                  }}
                                  active={rDistanceSelected === 500}
                                >
                                  500m
                                </Button>
                                <Button
                                  outline
                                  color="primary"
                                  onClick={() => {
                                    setRDistanceSelected(1000);
                                    setFiltersEnabled(true);
                                  }}
                                  active={rDistanceSelected === 1000}
                                >
                                  1 km
                                </Button>
                                <Button
                                  outline
                                  color="primary"
                                  onClick={() => {
                                    setRDistanceSelected(2000);
                                    setFiltersEnabled(true);
                                  }}
                                  active={rDistanceSelected === 2000}
                                >
                                  2 km
                                </Button>
                              </ButtonGroup>
                            </div>
                          </>
                        ) : null}
                      </div>

                      {filterFieldSingle(i18n.t('gender'), 'gender', 'genders')}

                      {filterFieldMulti(i18n.t('age_range'), 'ageRanges', 'ageRanges')}
                      {filterFieldMulti(i18n.t('socioeconomic_level'), 'classes', 'classes')}
                      {filterFieldMulti(i18n.t('offline_behavior'), 'offlines', 'offlines')}
                      {filterFieldMulti(i18n.t('apps_usage'), 'genres', 'genres')}

                      <div
                        className="mb-0 col-12 col-sm-12 col-md-12 mt-5 d-flex"
                        style={{ flexDirection: 'column' }}
                      >
                        <Button
                          className="col-12 col-sm-12 col-md-12 col-lg-12"
                          color="primary"
                          disabled={!filtersEnabled}
                          onClick={applyFilters}
                        >
                          {i18n.t('apply_filters_button')}
                        </Button>

                        <span className="col-2 col-lg-2 mt-3" />
                        <Button
                          className="col-12 col-sm-12 col-md-12 col-lg-12"
                          color="primary"
                          onClick={() => setModal(true)}
                          disabled={
                            !(
                              responseDetails &&
                              responseDetails.data &&
                              responseDetails.data.value &&
                              responseDetails.data.value.total > 0 &&
                              responseDetails.data.state === 'LOADED' &&
                              responseDetails.data.loaded !== false
                            )
                          }
                        >
                          {i18n.t('save_audience_button')}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="col-9 col-sm-9 col-md-9 mb-4 p-0">
          <AudienceInsights
            responseData={responseDetails}
            filter={audienceFilter}
            type={type}
            configFilters={configFilters}
          />
        </div>
      </Row>
    </div>
  );
}
