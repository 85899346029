import React from 'react';

import { paginate } from './paginate';

function Page({ onClick, page, text, currentPage, active, enable = true }) {
  if (typeof active === 'undefined') {
    active = page === currentPage;
  }

  const liClass = active ? 'active' : '';

  return (
    <li key={`page ${page}`} className={`page-item ${liClass}`}>
      <button
        type="button"
        className="page-link"
        title={`Go to page ${page}`}
        onClick={() => enable && onClick(page)}
      >
        {text || page}
      </button>
    </li>
  );
}

function Paginator({ setPage, totalItems, currentPage, pageSize = 10, maxPages = 10 }) {
  const pagination = paginate(
    parseInt(totalItems),
    parseInt(currentPage),
    parseInt(pageSize),
    parseInt(maxPages)
  );

  if (pagination.totalItems === 0) {
    return <div className="pagination-container col-centered" />;
  }

  return (
    <div className="pagination-container col-centered">
      <ul className="pagination">
        <Page
          enable={pagination.currentPage !== 1}
          text="Previous"
          page={pagination.currentPage - 1}
          currentPage={pagination.currentPage}
          onClick={setPage}
        />
        <Page
          enable={pagination.currentPage !== 1}
          text="First"
          page={1}
          active={false}
          currentPage={pagination.currentPage}
          onClick={setPage}
        />

        {pagination.pages.map(page => (
          <Page
            enable={pagination.currentPage !== page}
            page={page}
            currentPage={pagination.currentPage}
            onClick={setPage}
            key={page}
          />
        ))}

        <Page
          enable={pagination.currentPage !== pagination.totalPages}
          text="Last"
          page={pagination.totalPages}
          active={false}
          currentPage={pagination.currentPage}
          onClick={setPage}
        />
        <Page
          enable={pagination.currentPage !== pagination.totalPages}
          text="Next"
          page={pagination.currentPage + 1}
          currentPage={pagination.currentPage}
          onClick={setPage}
        />
      </ul>
    </div>
  );
}

export default Paginator;
