import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { CardGroup, Card, CardBody, CardTitle } from 'reactstrap';

// icons
import VisitorsChart from './VisitorsChart';

import { ResponseLoading } from '../../components/utils/Loading';

export default function DashboardTopFDCard({
  filter,
  fullDemosObj,
  groupCount,
  showPerc = true,
  poiType
}) {
  // if (!responseVisits || responseVisits.length === 0 || !responseVisits.hasValue('metrics'))
  //   return null;

  // const percStyle = {
  //   fontSize: '12px',
  //   textAlign: 'center',
  //   marginBottom: '0px',
  //   marginTop: '0px'
  // };

  // const variationBlock = (metric, variable, format, value) => {
  //   let comp = null;

  //   const val = responseVisits.hasValue(metric)
  //     ? numeral(value || responseVisits.data.value[metric][variable]).format(format)
  //     : null;

  //   if (responseVisits.hasValue(metric) && responseVisits.hasValue(`${metric}Previous`)) {
  //     const current = responseVisits.data.value[metric][variable] || 0;
  //     const prev = responseVisits.data.value[`${metric}Previous`][variable] || 0;

  //     const variation =
  //       current > prev ? (
  //         // eslint-disable-next-line react/jsx-indent
  //         <IconTrendUp size="16" color="#00c853" />
  //       ) : (
  //         <IconTrendDown size="16" color="#c80053" />
  //       );

  //     const perc =
  //       current > prev
  //         ? [{ ...percStyle, color: '#18cb5d' }, numeral(current / prev - 1).format('% 0,0.0')]
  //         : [
  //             { ...percStyle, color: 'red' },
  //             numeral(Math.abs(current / prev - 1)).format('% 0,0.0')
  //           ];

  //     comp = (
  //       <div style={perc[0]}>
  //         {variation}
  //         {perc[1]}
  //       </div>
  //     );
  //   }
  //   return (
  //     <>
  //       {val}
  //       {comp}
  //     </>
  //   );
  // };

  const totalVisitsTitle = poiType === 'ooh' ? 'total_visits_title_ooh' : 'total_visits_title';
  const avgUniqueVisitorsTitle =
    poiType === 'ooh' ? 'avg_unique_visitors_title_ooh' : 'avg_unique_visitors_title';
  const totalUniqueVisitorsTitle =
    poiType === 'ooh' ? 'total_unique_visitors_title_ooh' : 'total_unique_visitors_title';
  const avgVisitsTitle = poiType === 'ooh' ? 'avg_visits_title_ooh' : 'avg_visits_title';

  return (
    <CardGroup className="metric-card mb-4">
      <Card style={{ flex: '3 0 0' }}>
        <CardBody>
          <CardTitle className="text-uppercase h6">
            {i18n.t(poiType === 'ooh' ? 'visitors_title_ooh' : 'visitors_title')}
            <ResponseLoading response={fullDemosObj} />
          </CardTitle>
          <div className="small mb-4 card-subtitle">
            {window._env_.REACT_APP_TYPE_PERIOD === 'false'
              ? i18n.t('by_week')
              : i18n.t('by_fraction')}
          </div>
          <div style={{ width: '100%', height: '280px' }}>
            <VisitorsChart
              visitsTitle={filter.agg === 'g' ? totalVisitsTitle : avgVisitsTitle}
              uniquesTitle={filter.agg === 'g' ? totalUniqueVisitorsTitle : avgUniqueVisitorsTitle}
              visitorsAndVisitsResponse={fullDemosObj.metricsWeekly}
              fixedPeriods={window._env_.REACT_APP_TYPE_PERIOD === 'fixed'}
            />
          </div>
        </CardBody>
      </Card>
      <Card>
        {/* <ResponseLoading responses={[responseVisits, responseVisits]} /> */}

        <CardBody>
          <h6 className="text-uppercase title font-weight-bold small text-center">
            {i18n.t(poiType === 'ooh' ? 'pois_by_segment_ooh' : 'pois_by_segment')}
          </h6>
          <h4 className="font-weight-normal mb-0 text-center">
            {groupCount > 0 ? numeral(groupCount).format('0,0') : '--'}
          </h4>
        </CardBody>

        <CardBody>
          <h6 className="text-uppercase title font-weight-bold small text-center">
            {i18n.t(filter.agg === 'g' ? totalUniqueVisitorsTitle : avgUniqueVisitorsTitle)}
          </h6>
          {/* <h4 className="font-weight-normal mb-0">
            {variationBlock('metrics', 'unique_visitors', '0,0')}
          </h4> */}
          <h4 className="font-weight-normal mb-0 text-center">
            {numeral(fullDemosObj.uniques).format('0,0')}
            {showPerc ? (
              <>
                <br />
                <span className="small">
                  ({numeral(fullDemosObj.uniques / fullDemosObj.totalUniques).format('% 0.00')})
                </span>
              </>
            ) : (
              <></>
            )}
          </h4>
        </CardBody>
        <CardBody>
          <h6 className="text-uppercase title font-weight-bold small text-center">
            {i18n.t(filter.agg === 'g' ? totalVisitsTitle : avgVisitsTitle)}
          </h6>

          {/* <h4 className="font-weight-normal mb-0">{variationBlock('metrics', 'visits', '0,0')}</h4> */}
          <h4 className="font-weight-normal mb-0 text-center">
            {numeral(fullDemosObj.visits).format('0,0')}
            {showPerc ? (
              <>
                <br />
                <span className="small">
                  ({numeral(fullDemosObj.visits / fullDemosObj.totalVisits).format('% 0.00')})
                </span>
              </>
            ) : (
              <></>
            )}
          </h4>
        </CardBody>
        <CardBody>
          <h6 className="text-uppercase title font-weight-bold small text-center">
            {i18n.t('avg_frequency_title')}
          </h6>
          {/* <h4 className="font-weight-normal mb-0">
            {variationBlock('metrics', 'frequency', '0,0.00')}
          </h4> */}
          <h4 className="font-weight-normal mb-0 text-center">
            {numeral(fullDemosObj.freq).format('0,0.00')}
          </h4>
        </CardBody>
      </Card>
    </CardGroup>
  );
}
