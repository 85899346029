import { useEffect, useState } from 'react';

import toastr from 'toastr';

import API from '../api';

import { generateHash } from '../api/mofidata';

const hasValueFn = function(value) {
  return this.data.value != null && (value ? this.data.value[value] != null : true);
};

export const defaultResponse = {
  error: null,
  data: {
    value: null,
    state: 'LOADING'
  },
  hasValue: hasValueFn
};

export function useAPICaller(
  options = { cache: true, notification: true, notificationSuccessMsg: 'OK!' }
) {
  const [response, setResponse] = useState(defaultResponse);

  const call = (name, params) => {
    const callback = (err, response) => {
      if (options.notification) {
        if (err) {
          toastr.error(
            err.reason ||
              (err.response && err.response.data && err.response.data.message) ||
              'Error'
          );
        } else {
          toastr.success(options.notificationSuccessMsg);
        }
      }

      setResponse({
        error: err,
        data: {
          value: response.data,
          state: 'LOADED'
        },
        hasValue: hasValueFn
      });
    };

    if (options.cache) {
      API.call(name, params, callback);
    } else {
      API.noCacheCall(name, params, callback);
    }
  };

  return [response, call];
}

export function useAPIDataLoader(name, params = {}, options = { cache: true, skip: false }) {
  const hash = generateHash(name, params);

  const [response, setResponse] = useState(defaultResponse);

  useEffect(() => {
    if (!options.skip) {
      setResponse({
        ...response,
        data: {
          ...response.data,
          loaded: false
        },
        hasValue: hasValueFn
      });

      const callback = (err, response) => {
        setResponse({
          error: err,
          data: {
            value: response,
            state: 'LOADED'
          },
          hasValue: hasValueFn
        });
      };

      if (options.cache) {
        API.call(name, params, callback);
      } else {
        API.noCacheCall(name, params, callback);
      }
    }
  }, [hash]);

  return response;
}

export function useSyncAPIDataLoader(name, params = {}, options = { cache: true }, cb) {
  cb({
    defaultResponse,
    data: {
      value: defaultResponse.data.value,
      loaded: false
    },
    hasValue: hasValueFn
  });

  const callback = (err, response) => {
    cb({
      error: err,
      data: {
        value: response,
        state: 'LOADED'
      },
      hasValue: hasValueFn
    });
  };

  if (options.cache) {
    API.call(name, params, callback);
  } else {
    API.noCacheCall(name, params, callback);
  }
}
