import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import AppLayout from './modules/containers/layouts/AppLayout';
import CrudLayout from './modules/containers/layouts/CrudLayout';
import PublicLayout from './modules/containers/layouts/PublicLayout';
import OnboardingLayout from './modules/containers/layouts/OnboardingLayout';

export function AppRoute({
  component: Component,
  user,
  navMini = false,
  fullMonth,
  useAggregation,
  filterSinglePOI = false,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          return (
            <AppLayout
              user={user}
              navMini={navMini}
              fullMonth={fullMonth}
              useAggregation={useAggregation}
              filterSinglePOI={filterSinglePOI}
            >
              <Component {...props} user={user} key={props.location.search} />
            </AppLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function CRUDRoute({ component: Component, user, navMini = false, fullMonth, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          return (
            <CrudLayout user={user} navMini={navMini}>
              <Component {...props} user={user} fullMonth={fullMonth} key={props.location.search} />
            </CrudLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function PublicRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <PublicLayout>
              <Component {...props} />
            </PublicLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

export function OnboardingRoute({ component: Component, user, loginRequired = false, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (loginRequired && !user) {
          return (
            <Redirect
              to={{
                pathname: '/signup',
                state: { from: props.location }
              }}
            />
          );
        }
        return (
          <OnboardingLayout user={user}>
            <Component {...props} user={user} />
          </OnboardingLayout>
        );
      }}
    />
  );
}

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) return pair[1];
  }
  return null;
}
