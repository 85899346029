import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import auth from './ducks/auth';
import filters from './ducks/filters';
import mofidata from './ducks/mofidata';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

const reducer = combineReducers({
  auth,
  filters,
  mofidata
});

const configureStore = initialState => createStoreWithMiddleware(reducer, initialState);

export default configureStore;
