import React, { useState } from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';

import './compare.scss';
import { DatePicker, DateRangePicker } from 'rsuite';
import '../header/rsuite-default-custom.css';

import moment from 'moment';

import Select from 'react-select';
import ViewHeader from '../layouts/AppHeader';
import ViewContent from './ViewContent';

import { useAPIDataLoader } from '../../hooks/api';
import { ResponseLoading } from '../../components/utils/Loading';
import { getQueryVariable } from '../../../routeUtils';

const mapState = state => ({
  // defaultFilter: state.filters.filter
  filters: state.filters
});

export default function Compare({ fullMonth }) {
  const { filters } = useMappedState(mapState);

  // force endDate to be the same week as startDate -> in Compare we only allow one week selection
  const ed =
    window._env_.REACT_APP_TYPE_PERIOD === 'week'
      ? moment(filters.filter.startDate).endOf('isoWeek')
      : filters.filter.startDate;

  const [compareFilter, setCompareFilter] = useState({
    ...filters.filter,
    endDate: ed
  });

  const type = getQueryVariable('type');

  let responsePeriods = {};
  if (window._env_.REACT_APP_TYPE_PERIOD === 'fixed') {
    responsePeriods = useAPIDataLoader('periods.getPeriods', {}, { cache: true });
    if (responsePeriods.error) {
      console.log('getPeriods error', responsePeriods.error);
    }
  }

  if (type !== null) {
    if (type !== compareFilter.type)
      setCompareFilter({
        startDate: compareFilter.startDate,
        endDate: compareFilter.endDate,
        period: compareFilter.period ? compareFilter.period : null,
        pois: [],
        groupKeys: [],
        storeKey: compareFilter.storeKey,
        agg: compareFilter.agg,
        offset: compareFilter.offset,
        type
      });
  }

  const onSelectPeriod = period => {
    setCompareFilter({
      ...compareFilter,
      period,
      type
    });
  };

  const onApplyRange = value => {
    const _startDate = fullMonth ? moment(value).startOf('month') : moment(value[0]);
    const _endDate = fullMonth ? moment(value).endOf('month') : moment(value[1]);
    const startDate = _startDate.startOf('day').valueOf();
    const endDate = _endDate.startOf('day').valueOf();

    setCompareFilter({
      ...compareFilter,
      startDate,
      endDate,
      type
    });
  };
  const dateFilterComponent = (
    <form
      className="col-12 col-sm-12 col-md-6 col-lg-6 p-10 site-search"
      onSubmit={e => e.preventDefault()}
    >
      {window._env_.REACT_APP_TYPE_PERIOD === 'fixed' ? (
        <>
          <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
          <ResponseLoading response={responsePeriods} className="loading-dot-white" />
          <div style={{ width: '100%', height: '60px' }}>
            <Select
              value={compareFilter.period}
              options={responsePeriods.hasValue() ? responsePeriods.data.value : []}
              onChange={p => onSelectPeriod(p)}
              getOptionValue={option => option.oid}
            />
          </div>
        </>
      ) : (
        <>
          <h6 className="subtitle text-white pt-2">{i18n.t('period_label')}</h6>
          {fullMonth ? (
            <DatePicker
              appearance="default"
              format="MMMM YYYY"
              block
              size="lg"
              value={compareFilter ? moment(compareFilter.startDate).toDate() : new Date()}
              renderValue={value =>
                `${moment(value).format('MMMM')}  ${moment(value).format('YYYY')}`
              }
              ranges={[]}
              onChange={onApplyRange}
              cleanable={false}
            />
          ) : (
            <DateRangePicker
              appearance="default"
              hoverRange="week"
              isoWeek
              block
              size="lg"
              showOneCalendar
              oneTap
              value={
                compareFilter
                  ? [
                      moment(compareFilter.startDate)
                        .startOf('isoWeek')
                        .toDate(),
                      moment(compareFilter.endDate)
                        .endOf('isoWeek')
                        .toDate()
                    ]
                  : [new Date(), new Date()]
              }
              renderValue={value =>
                `${moment(value[0]).format('MMMM DD')} - ${moment(value[1]).format('MMMM DD')}`
              }
              ranges={[]}
              onChange={onApplyRange}
              cleanable={false}
            />
          )}
        </>
      )}
    </form>
  );
  return (
    <>
      <div className="view">
        <ViewHeader title={i18n.t('compare_menu')} filterComponent={dateFilterComponent} />
        <ViewContent defaultFilter={compareFilter} pois={filters.pois} type={type} />
      </div>
    </>
  );
}
