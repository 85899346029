import React, { useState } from 'react';
import i18n from 'i18n-js';
import * as Yup from 'yup';
import Select from 'react-select';
import { Formik, Field, Form } from 'formik';
import LocationPicker from 'react-location-picker';
import Geosuggest from 'react-geosuggest';

import { FormGroup, Col, Label, FormFeedback, Button, Input } from 'reactstrap';

import { Link } from 'react-router-dom';
import { useAPIDataLoader } from '../../hooks/api';

export default function POIForm({ poi, onSubmit, submitText = 'Submit', onDelete }) {
  const [countryCode, setCountryCode] = useState({});
  const [cp, setCP] = useState({});
  const [so, setSO] = useState({});
  const [statusCode, setStatusCode] = useState({});

  // Custom POI
  const [defaultPosition, setDefaultPosition] = useState({
    lat: parseFloat(poi.latitude || window._env_.REACT_APP_DEFAULT_LATITUDE),
    lng: parseFloat(poi.longitude || window._env_.REACT_APP_DEFAULT_LONGITUDE)
  });

  let _geoSuggest;

  const responseFilters = useAPIDataLoader('pois.getDataForFilters', {}, false);
  if (responseFilters.error) {
    console.log('responseFilters error', responseFilters.error);
  }

  poi.country_code = poi.country_code === '' ? 'BR' : poi.country_code;

  responseFilters.hasValue('countries') &&
    responseFilters.data.value.countries.length > 0 &&
    countryCode.value !== poi.country_code &&
    setCountryCode(
      responseFilters.data.value.countries.find(option => option.value === poi.country_code)
    );

  if (
    responseFilters.hasValue('cps') &&
    responseFilters.data.value.cps.length > 0 &&
    cp.value !== poi.cp_name
  ) {
    const newCP = responseFilters.data.value.cps.find(option => option.value === poi.cp_name);
    newCP && setCP(newCP);
  }

  responseFilters.hasValue('sos') &&
    responseFilters.data.value.sos.length > 0 &&
    so.value !== poi.sales_office &&
    setSO(responseFilters.data.value.sos.find(option => option.value === poi.sales_office));

  poi.status = !poi.status ? 'unknown' : poi.status;

  responseFilters.hasValue('statuses') &&
    responseFilters.data.value.statuses.length > 0 &&
    statusCode.value !== poi.status &&
    setStatusCode(responseFilters.data.value.statuses.find(option => option.value === poi.status));
  const values = {};

  const handleChange = event => {

    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    values[name] = value;
    if (
      _geoSuggest &&
      values.address &&
      values.address !== '' &&
      values.city &&
      values.city !== '' &&
      values.uf &&
      values.uf !== '' &&
      values.zipcode &&
      values.zipcode !== ''
    ) {
      _geoSuggest.update(
        `${values.address} - ${values.city}, ${values.uf} - ${values.zipcode}, ${poi.country_code}`
      );
    }
  };

  const onSelectCountry = (selected, params) => {
    poi.country_code = selected.value;
    setCountryCode(selected);
  };


  const onSuggestSelect = suggest => {
    if (suggest && suggest.location) {
      setDefaultPosition({
        lat: suggest.location.lat,
        lng: suggest.location.lng
      });
      poi.latitude = suggest.location.lat;
      poi.longitude = suggest.location.lng;
    }
  };

  const customStylesSingle = {
    menu: (base, state) => ({
      ...base,
      zIndex: '10'
    })
  };

  const handleLocationChange = ({ position, address, places }) => {
    poi.latitude = position.lat;
    poi.longitude = position.lng;

    _geoSuggest.clear();
  };

  return (
    <Formik
      initialValues={poi}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .min(4, i18n.t('error_too_short'))
          .max(100, i18n.t('error_too_long'))
          .required(i18n.t('error_required')),
        address: Yup.string()
          .min(4, i18n.t('error_too_short'))
          .max(100, i18n.t('error_too_long'))
          .required(i18n.t('error_required')),
        city: Yup.string()
          .min(4, i18n.t('error_too_short'))
          .max(100, i18n.t('error_too_long'))
          .required(i18n.t('error_required')),
        uf: Yup.string()
          .min(2, i18n.t('error_too_short'))
          .max(100, i18n.t('error_too_long'))
          .required(i18n.t('error_required')),
        zipcode: Yup.string()
          .min(4, i18n.t('error_too_short'))
          .max(100, i18n.t('error_too_long'))
          .required(i18n.t('error_required')),
        phone: Yup.string()
          .min(4, i18n.t('error_too_short'))
          .max(100, i18n.t('error_too_long'))
          .required(i18n.t('error_required')),
        country_code: Yup.string().required(i18n.t('error_required'))
      })}
      onSubmit={formValues => {
        onSubmit(formValues, poi);
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <FormGroup row>
            <Label for="code" sm={3}>
              {i18n.t('admin_pois_code')}
            </Label>
            <Col sm={9}>
              <Input type="text" name="code" tag={Field} invalid={errors.code && touched.code} />
              <FormFeedback>{errors.code}</FormFeedback>
            </Col>
          </FormGroup>

          {}

          <h6 className="mb-4 text-uppercase">
            <br />
            {i18n.t('admin_pois_location')}
          </h6>

          <Geosuggest
            ref={el => {
              _geoSuggest = el;
            }}
            placeholder="search places"
            onSuggestSelect={suggest => onSuggestSelect(suggest)}
            style={{ input: { width: '100%' } }}
            country={poi.country_code ? poi.country_code : null}
          />
          <div className="d-flex mb-4 align-items-center">
            <LocationPicker
              containerElement={<div style={{ height: '100%', width: '100%' }} />}
              mapElement={<div style={{ height: '400px' }} />}
              defaultPosition={defaultPosition}
              onChange={handleLocationChange}
              zoom={8}
              radius={-1}
            />
          </div>

          <FormGroup row>
            <Label for="address" sm={3}>
              {i18n.t('admin_pois_address')}
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="address"
                tag={Field}
                invalid={errors.address && touched.address}
                onBlur={handleChange}
              />
              <FormFeedback>{errors.address}</FormFeedback>
            </Col>
          </FormGroup>
          {}
          <FormGroup row>
            <Label for="city" sm={3}>
              {i18n.t('admin_pois_city')}
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="city"
                tag={Field}
                invalid={errors.city && touched.city}
                onBlur={handleChange}
              />
              <FormFeedback>{errors.city}</FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="uf" sm={3}>
              {i18n.t('admin_pois_state')}
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="uf"
                tag={Field}
                invalid={errors.uf && touched.uf}
                onBlur={handleChange}
              />
              <FormFeedback>{errors.uf}</FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="zipcode" sm={3}>
              {i18n.t('admin_pois_zipcode')}
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="zipcode"
                tag={Field}
                invalid={errors.zipcode && touched.zipcode}
                onBlur={handleChange}
              />
              <FormFeedback>{errors.zipcode}</FormFeedback>
            </Col>
          </FormGroup>

          {}

          <FormGroup row>
            <Label for="country_code" sm={3}>
              {i18n.t('admin_pois_country')}
            </Label>
            <Col sm={9}>
              <Select
                value={countryCode}
                options={
                  responseFilters.hasValue('countries') ? responseFilters.data.value.countries : []
                }
                styles={customStylesSingle}
                onChange={onSelectCountry}
              />
              <FormFeedback>{errors.country_code}</FormFeedback>
            </Col>
          </FormGroup>

          {}

          <FormGroup row>
            <Col sm={2} className="d-flex">
              {}
            </Col>
            <Col sm={10} className="text-right">
              <Button color="primary" type="submit">
                {submitText}
              </Button>{' '}
              <Link to="/pois/">
                <Button>{i18n.t('cancel_button')}</Button>
              </Link>
            </Col>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
}
