import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { ResponsiveContainer, Sankey, Tooltip } from 'recharts';

import { colors } from '../../components/charts/Styles';

// import SankeyLink from './SankeyLink';
import SankeyNode from './SankeyNode';

export default function JourneyFlowChart({ response }) {
  if (!response || response.length === 0 || !response.nodes || response.nodes.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  // TODO specific msg?
  if (response.nodes.length < 5) return <div className="text_check">{i18n.t('no_data')}</div>;
  // const numColors = colors.length;

  // const colorGradients = data.links.map(link => {
  //   return {
  //     source: colors[link.source % numColors],
  //     target: colors[link.target % numColors]
  //   };
  // });
  const renderTooltip = function(props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const source = data.payload.source;
      const target = data.payload.target;

      const descStyle = { fontSize: 9, color: 'black', fontWeight: 'lighter' };

      const srcDesc =
        source && source.description ? (
          <>
            <span style={descStyle}>
              <br />
              {source.description}
            </span>
          </>
        ) : null;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10,
            width: '20vw'
          }}
        >
          <p>
            <b>
              {source && target && (
                <>
                  <span style={{ color: colors[source.color] }}>{source.name}</span>
                  {srcDesc} <br />
                  <br />
                  {' ==> '}
                  <br />
                  <br />
                  <span style={{ color: colors[target.color] }}>{target.name}</span>
                  <span style={descStyle}>
                    <br />
                    {target.description}
                  </span>
                </>
              )}
              {!(source && target) && (
                <>
                  <span style={{ color: colors[data.payload.color] }}>{data.payload.name}</span>
                  <span style={descStyle}>
                    <br />
                    {data.payload.description}
                  </span>
                </>
              )}
            </b>
          </p>

          <p>
            <b>{numeral(data.payload.value).format('% 0.00')}</b>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Sankey
        width={960}
        margin={{ top: 20, bottom: 20 }}
        data={response}
        nodeWidth={10}
        nodePadding={100}
        linkCurvature={0.5}
        // iterations={5}
        // link={<SankeyLink colorGradients={colorGradients} />}
        node={<SankeyNode containerWidth={960} colors={colors} />}
      >
        <Tooltip content={props => renderTooltip(props)} />
      </Sankey>
    </ResponsiveContainer>
  );
}
