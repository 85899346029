import React from 'react';
import i18n from 'i18n-js';
import toastr from 'toastr';

import * as Yup from 'yup';

import { Formik, Field, Form } from 'formik';

import { FormGroup, Col, FormFeedback, Button, Input, Card, CardBody } from 'reactstrap';

import { useDispatch } from 'redux-react-hook';
import API from '../../api/index';

import { authSetUser } from '../../redux/ducks/auth';

export default function EditProfile({ user }) {
  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('edit_profile_title')}</h1>
          <p className="mb-0">{i18n.t('edit_profile')}</p>
        </header>
      </div>
      <ViewContent user={user} />
    </div>
  );
}

function ViewContent({ user }) {
  return (
    <div className="view-content view-components">
      <Card className="mb-4">
        <CardBody className="table-responsive">
          <h6 className="mb-4 text-uppercase">{i18n.t('new_profile_title')}</h6>
          {user && <EditProfileForm user={user} />}
        </CardBody>
      </Card>
    </div>
  );
}

function EditProfileForm({ user }) {
  const dispatch = useDispatch();

  const onSubmit = params => {
    API.call(
      'users.update',
      [user.id, params.firstName, params.lastName, user.email, user.role],
      (err, res) => {
        if (err) {
          toastr.error(err.reason);
        } else {
          dispatch(
            authSetUser({
              ...user,
              firstName: params.firstName,
              lastName: params.lastName
            })
          );
          toastr.success('OK!');
        }
      }
    );
  };

  return (
    <Formik
      initialValues={user}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .min(2, i18n.t('profile_first_name_too_short'))
          .required(i18n.t('profile_first_name_required')),
        lastName: Yup.string()
          .min(2, i18n.t('profile_last_name_too_short'))
          .required(i18n.t('profile_last_name_required'))
      })}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <Col msm={9}>
            <FormGroup>
              <Input
                placeholder={i18n.t('profile_first_name')}
                className="form_item text_form"
                type="text"
                name="firstName"
                tag={Field}
                invalid={errors.firstName && touched.firstName}
              />
              {errors.firstName && touched.firstName && (
                <FormFeedback>{errors.firstName}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                placeholder={i18n.t('profile_last_name')}
                className="form_item text_form"
                type="text"
                name="lastName"
                tag={Field}
                invalid={errors.lastName && touched.lastName}
              />
              {errors.lastName && touched.lastName && (
                <FormFeedback>{errors.lastName}</FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Button color="primary" type="submit" className="form_bt bt_common m_15">
            {i18n.t('save_button')}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
