// import React, { useState } from 'react';
import React from 'react';
import i18n from 'i18n-js';
import { Marker, Popup } from 'react-leaflet';
// import { MdAdjust } from 'react-icons/md/';
// import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import L from 'leaflet';
import { options } from './config';
// import numeral from 'numeral';
L.Icon.Default.imagePath = '/img/maps/';

export default function POIMarker({ poi, icon, onClick }) {
  // return null;
  // const [popoverOpen, setPopoverOpen] = useState(false);

  // const InfoComponent = (
  //   <Popover
  //     placement="bottom"
  //     isOpen={popoverOpen}
  //     target="Popover-Radar"
  //     toggle={() => setPopoverOpen(false)}
  //   >
  //     <PopoverHeader className="text-center">{i18n.t('admin_pois_status')}</PopoverHeader>
  //     <PopoverBody>
  //       <table className="table">
  //         <tbody>
  //           <tr>
  //             <td>
  //               <MdAdjust size="16" color="#25ac22" />
  //             </td>
  //             <td style={{ textAlign: 'right' }}>{i18n.t('pois_status_open_text')}</td>
  //           </tr>
  //           <tr>
  //             <td>
  //               <MdAdjust size="16" color="#ffd327" />
  //             </td>
  //             <td style={{ textAlign: 'right' }}>{i18n.t('pois_status_closed_text')}</td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </PopoverBody>
  //   </Popover>
  // );

  const defaultIcon = poi.own ? options.redIcon : options.blueIcon;
  return (
    <Marker
      position={[parseFloat(poi.latitude), parseFloat(poi.longitude)]}
      key={poi._id}
      icon={icon || defaultIcon}
      onClick={onClick}
      // onChange={a => console.log(a)}
    >
      <Popup>
        <div
          style={{
            padding: '3px 7px',
            fontSize: 12,
            borderRadius: 2
          }}
        >
          <span>
            <b>{poi.name}</b>
          </span>
          <br />
          {/* <span>
            <b>{poi.status}</b>
          </span>
          <br /> */}

          <div>
            {/* <span>
              {i18n.t('admin_pois_ownership')}: {poi.own ? 'Frigo De Mi Barrio' : 'Supermercado'}
            </span> */}
            {poi.city ? (
              <>
                <br />
                <span>
                  {i18n.t('admin_pois_city')}: {poi.city}
                </span>
              </>
            ) : null}
            {poi.address ? (
              <>
                <br />
                <span>
                  {i18n.t('admin_pois_address')}: {poi.address}{' '}
                  {poi.address1 ? ` - ${poi.address1}` : ''}
                </span>
              </>
            ) : null}
            <br />
            {/* <span>
              {i18n.t('admin_pois_status')}:{' '}
              <MdAdjust
                size="16"
                color={
                  // eslint-disable-next-line no-nested-ternary
                  poi.status === 'open'
                    ? options.colorOpen
                    : poi.status === 'closed'
                    ? options.colorClosed
                    : options.colorUnknown
                }
                style={{ cursor: 'pointer' }}
                id="Popover-Radar"
                onClick={() => setPopoverOpen(true)}
              />
              {InfoComponent}
            </span>
            <br /> */}
          </div>
        </div>
      </Popup>
    </Marker>
  );
}
