import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle } from 'reactstrap';


export default function DwellTimeQuartileCard({ quartile, response }) {


  let value;
  if (response.hasValue('metricsGroup')) {
    switch (quartile) {
      case 1:
        value = `${i18n.t('up_to')} ${numeral(
          response.data.value.metricsGroup.quartile_1 * 60
        ).format('00:00:00')} hs`;
        break;
      case 2:
        value = `${numeral(response.data.value.metricsGroup.quartile_1 * 60).format(
          '00:00:00'
        )} a ${numeral(response.data.value.metricsGroup.quartile_2 * 60).format('00:00:00')} hs`;
        break;
      case 3:
        value = `${numeral(response.data.value.metricsGroup.quartile_2 * 60).format(
          '00:00:00'
        )} a ${numeral(response.data.value.metricsGroup.quartile_3 * 60).format('00:00:00')} hs`;
        break;
      case 4:
        value = `${i18n.t('more_than')} ${numeral(
          response.data.value.metricsGroup.quartile_3 * 60
        ).format('00:00:00')} hs`;
        break;
      default:
        value = i18n.t('no_data_min');
    }
  } else {
    value = i18n.t('no_data_min');
  }

  const range = `${(quartile - 1) * 25}${quartile > 1 ? '%' : ''} - ${quartile * 25}%`;

  return (
    <Card className="mb-4" style={{ minHeight: '102px' }}>
      <CardBody>
        <div className="small mb-0 card-subtitle" style={{ fontStyle: 'italic' }}>
          {i18n.t('quartile')} {quartile}
        </div>
        <CardTitle className="text-uppercase small font-weight-bold">
          {i18n.t('percentile')} {range}
        </CardTitle>
        <div className="d-flex align-items-center">
          <h3 className="mr-2 small font-weight-normal">{value}</h3>

          {/* {response.hasValue('metrics') && response.data.value.metrics[`quartile_${quartile}`] > 0 ? (
            <VariationBlock
              variation={
                response.data.value.metricsPrevious[`quartile_${quartile}`] /
                response.data.value.metrics[`quartile_${quartile}`]
              }
            />
          ) : null} */}
        </div>
      </CardBody>
    </Card>
  );
}
