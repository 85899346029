import io from 'socket.io-client';

import { mofiDataOnData, mofiDataOnError } from '../redux/ducks/mofidata';

const MofiData = {};

MofiData.metricsIntervals = {};

MofiData.socket = null;
MofiData.baseURL = null;
MofiData.store = null;
MofiData.token = null;

MofiData._metricsPollInterval = {
  '1sec': 1000 * 3,
  '15sec': 1000 * 15,
  '30sec': 1000 * 30,
  '1min': 1000 * 60,
  '30min': 1000 * 60 * 30
};

MofiData.generateHash = function generateHash(dataKey, args) {
  const copyArgs = JSON.parse(JSON.stringify(args));
  delete copyArgs.userId;
  delete copyArgs.user;
  delete copyArgs.realtime;
  delete copyArgs.resolution;
  const payload = { dataKey, args: copyArgs };
  return JSON.stringify(payload);
};

export const generateHash = MofiData.generateHash;

MofiData.reset = function() {
  if (MofiData.socket) {
    MofiData.socket.close();
    MofiData.socket = null;
  }
};

MofiData.setStore = function(store) {
  MofiData.store = store;
};

MofiData.init = function(token) {
  MofiData.token = token;

  if (token) {
    MofiData.socket = io(MofiData.baseURL, {
      path: '/v2/socket.io',
      transports: ['websocket'],
      query: `token=${MofiData.token}`
    });
  }

  if (!MofiData.socket || !MofiData.store) {
    return MofiData;
  }

  MofiData.socket.on('connect', () => {});

  MofiData.socket.on('data', (hash, data = {}) => {
    MofiData.store.dispatch(mofiDataOnData(hash, data));
  });

  MofiData.socket.on('error', (hash, error) => {
    MofiData.store.dispatch(mofiDataOnError(hash, error));
  });

  return MofiData;
};

export default MofiData;
