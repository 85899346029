import React from 'react';

export default function CustomizedAxisTick({
  x,
  y,
  stroke,
  payload,
  data,
  fontSize = 11,
  iconField = 'icon',
  iconField2
}) {
  const w = iconField2 ? '44px' : '88px';
  const h = iconField2 ? '24px' : '31px';
  return (
    <g transform={`translate(${x},${y})`}>
      <image
        xlinkHref={data[payload.index][iconField]}
        x={iconField2 ? -38 : -44}
        y={0}
        height={h}
        width={w}
        textAnchor="middle"
        fill="#666"
      />
      {iconField2 && (
        <image
          xlinkHref={data[payload.index][iconField2]}
          x={-6}
          y={0}
          height={h}
          width={w}
          textAnchor="middle"
          fill="#666"
        />
      )}
    </g>
  );
}

// <text x={0} y={0} dy={6} fontSize={fontSize} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
