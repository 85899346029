import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

import { BarChart, XAxis, YAxis, Bar, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import { colors } from '../../components/charts/Styles';

export default function PerformanceChart({ response, dataKey, format, suffix }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  const renderTooltip = function(props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0];

      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #999', margin: 0, padding: 10 }}>
          <p>
            <b>{data.payload.group_name}</b>
            <br />
            {i18n.t('pois_distance')}: {numeral(data.payload.distance).format('0,0.00')}kms
          </p>

          <p>
            <span style={{ color: colors[0] }}>{i18n.t('ranking_title')}: </span>
            {data.payload.rank}
          </p>
          <p>
            <span style={{ color: colors[0] }}>{i18n.t(`${dataKey}_label`)}: </span>
            {numeral(data.payload[dataKey]).format(format)}
            {suffix}
            {data.payload.share && ` (${numeral(data.payload.share).format('% 0.00')})`}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        data={response}
        margin={{ top: 0, right: 40, left: 40, bottom: 0 }}
        layout="vertical"
      >
        <YAxis dataKey="group_name" type="category" tick={{ fontSize: 9 }} />
        <XAxis type="number" tickFormatter={value => numeral(value).format(format)} />
        <Tooltip content={props => renderTooltip(props)} />
        <Bar dataKey={dataKey} name={i18n.t(`${dataKey}_label`)} barSize={30}>
          {response.map((entry, index) => (
            <Cell fill={colors[index]} key={index} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
