import React, { useState } from 'react';
// import i18n from 'i18n-js';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

export const GoogleMapComponent = withGoogleMap(props => {
  const defaultProps = {
    center: {
      lat: parseFloat(window._env_.REACT_APP_DEFAULT_LATITUDE),
      lng: parseFloat(window._env_.REACT_APP_DEFAULT_LONGITUDE)
    },
    zoom: 5
  };
  const { pois, onChange } = props;

  const [isOpen, setOpen] = useState({});
  const handleOpen = id => {
    setOpen(value => ({ ...value, [id]: !value[id] }));
  };

  const icons = {
    redIcon: new window.google.maps.MarkerImage(
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(25, 41)
    ),
    blueIcon: new window.google.maps.MarkerImage(
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(25, 41)
    )
  };

  return (
    <GoogleMap defaultZoom={defaultProps.zoom} defaultCenter={defaultProps.center}>
      <MarkerClusterer averageCenter enableRetinaIcons maxZoom={14}>
        {pois.map(p => (
          <Marker
            icon={p.own ? icons.redIcon : icons.blueIcon}
            key={p._id}
            position={{ lat: p.latitude, lng: p.longitude }}
            onClick={() => {
              // handleOpen(p._id);
              onChange && onChange(p);
            }}
            onMouseOver={() => {
              handleOpen(p._id);
            }}
            onMouseOut={() => {
              handleOpen(p._id);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            optimized
          >
            {isOpen[p._id] && (
              <InfoWindow
                onCloseClick={() => {
                  handleOpen(p._id);
                }}
                key={p._id}
                options={{ closeBoxURL: ``, enableEventPropagation: true }}
              >
                <div
                  style={{
                    padding: '3px 7px',
                    // background: 'rgba(60,70,75,.9)',
                    fontSize: 12,
                    // color: '#fff',
                    borderRadius: 2
                  }}
                >
                  <span>
                    <b>{p.name}</b>
                  </span>
                  <br />
                  {/* <span>
                    {i18n.t('admin_pois_ownership')}:{' '}
                    {p.own ? 'Frigo De Mi Barrio' : 'Supermercado'}
                  </span> */}
                  <span>{p.city}</span>
                  <br />
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </MarkerClusterer>
    </GoogleMap>
  );
});
