import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { Card, CardBody, CardTitle } from 'reactstrap';
import BlocksChart from '../../../components/charts/BlocksChart';

export default function AdvisorCard({
  title,
  text,
  kpi,
  format,
  recommendations,
  selected,
  dataChart,
  dataKey,
  stroke,
  fill,
  formatBlock,
  callbackOnClick
}) {
  return (
    <Card className={`mb-4 ${selected ? 'advisor_card_selected' : ''}`} onClick={callbackOnClick}>
      <CardBody>
        <CardTitle className="text-uppercase small font-weight-bold" style={{ minHeight: 24 }}>
          {title}
        </CardTitle>
        <div className="small card-subtitle advisor_card_description">{text}</div>
        <div className="d-flex align-items-center">
          <h3 className="mr-2 font-weight-normal">
            {kpi > 0 ? '+' : null}
            {numeral(kpi).format(format)}
          </h3>
        </div>
        <div className="d-flex align-items-center">
          <h5 className="mr-2 font-weight-normal">
            {recommendations ? recommendations.length : 0}{' '}
            {i18n.t('ai_advisor_recommendations', { count: recommendations.length })}
          </h5>
        </div>
      </CardBody>
      <div style={{ width: '100%', height: '40px' }}>
        {' '}
        {dataChart ? (
          <BlocksChart
            data={dataChart}
            dataKey={dataKey}
            stroke={stroke}
            fill={fill}
            format={formatBlock}
          />
        ) : null}
      </div>
    </Card>
  );
}
