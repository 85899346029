import React from 'react';
import i18n from 'i18n-js';
import numeral from 'numeral';

export default function CustomTooltip({ payload, active }) {
  if (active && payload && payload[0]) {
    return (
      <div
        style={{
          padding: '3px 7px',
          background: 'rgba(60,70,75,.9)',
          fontSize: 12,
          color: '#fff',
          borderRadius: 2
        }}
      >
        {payload[0].payload.name}
        <br />
        {i18n.t('performance_previous_visits')}:{' '}
        {numeral(payload[0].payload.prev_month_visitors).format(
          payload[0].payload.prev_month_visitors > 10000000 ? '0.00a' : '0,0'
        )}
        <br />
        {i18n.t('performance_visits')}:{' '}
        {numeral(payload[0].payload.unique).format(
          payload[0].payload.unique > 10000000 ? '0.00a' : '0,0'
        )}
        <br />
        {i18n.t('performance_variation')}: {numeral(payload[0].value).format('%0.00')}
      </div>
    );
  }
  return null;
}
