import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';

export default function JourneyTable({ response, dataKey, format, suffix }) {
  function JourneyRow({ journey }) {
    return (
      <tr>
        <td>{journey.rank}</td>
        <td>{journey.group_name}</td>
        <td>{numeral(journey.distance).format('0,0.00')}kms</td>
        <td>
          {numeral(journey[dataKey]).format(format)}
          {suffix}
          {journey.share && ` (${numeral(journey.share).format('% 0.00')})`}
        </td>
      </tr>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="mb-2" style={{ width: '5%', minWidth: '5%' }}>
              {i18n.t('ranking_title')}
            </th>
            <th className="mb-2" style={{ width: '65%', minWidth: '65%' }}>
              {i18n.t('poi_name')}
            </th>
            <th className="mb-2" style={{ width: '20%', minWidth: '20%' }}>
              {i18n.t('pois_distance')}
            </th>
            <th className="mb-2" style={{ width: '10%', minWidth: '10%' }}>
              {i18n.t(`${dataKey}_label`)}
            </th>
          </tr>
        </thead>
        <tbody>
          {response && response.map((journey, i) => <JourneyRow key={i} journey={journey} />)}
        </tbody>
      </table>
    </>
  );
}
