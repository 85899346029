import React from 'react';
import i18n from 'i18n-js';

import { useMappedState } from 'redux-react-hook';

import ViewContentBuilder from './ViewContentBuilder';

const mapState = state => ({
  filter: state.filters.filter
});

export default function AudienceBuilder(props) {
  const { filter } = useMappedState(mapState);
  const workingFilter = {};

  return (
    <div className="view">
      <div className="view-header">
        <header className="title text-white">
          <h1 className="h4 text-uppercase">{i18n.t('planner_menu')}</h1>
          <p className="mb-0">{i18n.t('audience_builder')}</p>
        </header>
      </div>

      <ViewContentBuilder filter={filter} workingFilter={workingFilter} type={props.user.type} />
    </div>
  );
}
