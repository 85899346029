import React from 'react';
// import numeral from 'numeral';
import i18n from 'i18n-js';

export default function RecommendationTable({ recommendations }) {
  function RecommendationRow({ recommendation }) {
    return (
      <tr>
        <td className={`recommendation_${recommendation.impact.toLowerCase()}`}>
          {recommendation.impact}
        </td>
        <td>{recommendation.description}</td>
        <td>{recommendation.benefit}</td>
      </tr>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="mb-2" style={{ width: '5%', minWidth: '5%' }}>
              {i18n.t('ai_advisor_recommendation_impact')}
            </th>
            <th className="mb-2" style={{ width: '60%', minWidth: '60%' }}>
              {i18n.t('ai_advisor_recommendation_description')}
            </th>
            <th className="mb-2" style={{ width: '35%', minWidth: '35%' }}>
              {i18n.t('ai_advisor_recommendation_benefit')}
            </th>
          </tr>
        </thead>
        <tbody>
          {recommendations &&
            recommendations
              .sort((a, b) => (a.impact > b.impact ? 1 : -1))
              .map((recommendation, i) => (
                <RecommendationRow key={i} recommendation={recommendation} />
              ))}
        </tbody>
      </table>
    </>
  );
}
