import React from 'react';
import numeral from 'numeral';
import i18n from 'i18n-js';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import CustomTooltip from './CustomTooltip';

export default function RankingChart({ response, color, type }) {
  if (!response || response.length === 0)
    return <div className="text_check">{i18n.t('no_data')}</div>;

  return (
    <ResponsiveContainer>
      <BarChart
        data={response}
        margin={{ top: 0, right: 40, bottom: 0, left: 5 }}
        layout="vertical"
      >
        <XAxis type="number" tickFormatter={value => numeral(value).format('0,0')} />
        <YAxis dataKey="name" width={150} type="category" tick={{ fontSize: 10 }} />
        <Tooltip
          content={<CustomTooltip type={type} />}
          wrapperStyle={{ borderColor: '#000', backgroundColor: '#fff', padding: 10 }}
        />
        <Bar dataKey="visits" name="visits" barSize={20}>
          {response.map((entry, index) => (
            <Cell fill={color} key={index} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
